import React, {Component} from 'react';
import PropTypes from "prop-types";

class WallboardCallsWaiting extends Component {

    constructor(props) {
        super(props);

        this.state = {

            ObjArr : []

        }

    }

    static propTypes = {

        queues: PropTypes.object.isRequired

    };

    render() {

        const {queues} = this.props;

        let obj = queues;

        for(var key in queues ){

            if(obj.hasOwnProperty(key)){

                this.state.ObjArr.push(obj[key]);

            }

        }

        const callsQueued = this.state.ObjArr ? this.state.ObjArr.map(
            (queues) => {

                if(queues.cls_q){
                    return (
                        <div className="col-lg-6 col-12 p-0 d-flex">
                            <div className="col">
                                <h6><strong>Queue: 300-Call Waiting</strong></h6>
                                <table className="table copy-font table-responsive">
                                    <thead>
                                    <tr>
                                        <th>Position</th>
                                        <th>Call wait time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>03:20</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col">
                                <h6><strong>Agents on Call</strong></h6>
                                <table className="table copy-font table-responsive">
                                    <thead>
                                    <tr>
                                        <th>Agents ID</th>
                                        <th>Calls Today</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Ayeni Micheal</td>
                                        <td>20</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                } else {
                    return null;
                }
            }
        ) : (
            <p>notjing</p>
        );

        return (
            <div className="row mb-5">
                <div className="col-12 p-0">
                    {callsQueued}
                </div>
            </div>
        );
    }
}

export default WallboardCallsWaiting;