export default {
  formatCurrency(param) {
    return new Intl.NumberFormat('en-NG', {
      //   style: 'currency',
      //   currency: 'NGN',
    }).format(Number(param));
    // return Number(wallet).toLocaleString(undefined, {
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2,
    // });
  },

  phoneNumber(input) {
    const phone = /^\d{10}$/;
    return !!input.value.match(phone);
  },
};

// Number(wallet).toLocaleString(undefined, {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2
// })
