import React, {Component} from 'react';
import moment from "moment";
import axios from "axios";
import {API_URL} from "../../../../config/BaseUrl";

class RobocallMembers extends Component {

    constructor(props) {
        super(props);

        this.state = {

            robocalls: [],
            loading: false

        }

    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.id){

            this.setState({

                loading: true

            });

            try{

                axios.get(`${API_URL}/wificall/robocall/${nextProps.id}`, {
                    headers: {

                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

                    }

                }).then(response => {

                    if(response.data.group){

                        this.setState({
                            robocalls: response.data.group,
                            loading: false

                        })

                    }

                })
            } catch (e) {

                if (e){

                    console.log(e);

                }

            }

        }

    }

    render() {

        const { robocalls, loading } = this.state;
        const { id} = this.props;

        const robocallList = robocalls.bulkdialer_numbers ? (
            robocalls.bulkdialer_numbers.map(
                (robocall, i) => (
                    <tr className="copy-font">
                        <th scope="row">{i + 1}</th>
                        <td>{robocall.firstname}</td>
                        <td>{robocall.lastname}</td>
                        <td>{moment(robocall.created_at).format("DD MM YYYY")}</td>
                        <td>{robocall.status === true ? 'TRUE' : 'FALSE'}</td>
                        <td>{robocall.phonenumber}</td>
                        <td className="text-center"><img src="https://res.cloudinary.com/teewhy/image/upload/v1548069587/Call_Button.png"  alt="call-button" className="w-75"/></td>
                    </tr>
                )
            )
        ) : (
            <small>no numbers!</small>
        );

        return (
            <div className="col-lg-3 col-12 p-0 mb-2">
                <h6><strong>{robocalls.name}</strong></h6>
                {id ? <div className="card shadow-sm p-4">
                    <table className="table table-responsive">
                        <thead>
                        <tr className="copy-font">
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Date Created</th>
                            <th scope="col">Status</th>
                            <th scope="col">Phone Number</th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ? <i className="fa fa-spinner fa-spin"></i> : robocallList}
                        </tbody>
                    </table>
                </div> : null}
            </div>
        );
    }
}

export default RobocallMembers;