import {FORGOT_PASSWORD, LOGIN_USER, REGISTER_USER, REGISTRATION_CODE_ERROR} from "../../actions/types/AuthTypes";
import {GET_ERRORS} from "../../actions/types/ErrorsTypes";
import {LOADING_STARTS, LOADING_STOPS} from "../../actions/types/loading/LoadingTypes";

const initialState = {

    isAuthenticated: false,
    user: {},
    loadLogin: false,
    loadRegister: false,
    loadForgotPassword: '',
    auth: {},
    loadRegistrationCode: '',
    load: false

};

const AuthReducer = (state = initialState, action) => {
    //console.log(action);
    const{type, payload} = action;
    switch (type) {

        case 'FETCH_USER':
            return {

                ...state,
                auth: action.payload

            };

        case LOADING_STOPS:
            return {

                ...state,
                loadRegister: payload,
                load: payload

            };

        case LOADING_STARTS:
            return {

                ...state,
                loadRegister: payload,
                load: payload

            };


        case REGISTER_USER:
            return{

                ...state,
                loadRegister: action.payload.load

            };

        case LOGIN_USER:
            return{

                ...state,
                loadLogin: action.payload.load

            };

        case FORGOT_PASSWORD:
            return{

                ...state,
                loadForgotPassword: action.payload.load

            };

        case GET_ERRORS:
            return{

                ...state,
                loadLogin: false

            };

        case REGISTRATION_CODE_ERROR:
            return {

                ...state,
                loadRegistrationCode: action.payload

            };

        default:
            return state;

    }


};

export default AuthReducer;