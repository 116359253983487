import React, {Component} from 'react';
import UploadAndDownload from "../upload-and-download-phone/UploadAndDownload";
import {connect} from "react-redux";
import PhoneNumbersGroupMembers from "../phonenumbers/PhoneNumbersGroupMembers";
import {getGroups} from "../../../../../../services/state-management/actions/bulk-dialler/general/GroupsActions";

class Groups extends Component {

    constructor(props) {
        super(props);

        this.state = {

            loadGroup: false,
            id: '',
            name: '',

        }

    }

    componentDidMount() {

        this.props.getGroups()

    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.newGroup.id){

            this.props.groups.unshift(nextProps.newGroup)

        }

    }

  getGroup(id, name) {

        this.setState({

            id,
      name,

        })

    }

    render() {

        const { loadGroup, id, name } = this.state;

        const { groups } = this.props;

        const groupList = groups.length ? (
            groups.map(
                (group, i) => (
                    <tr className="copy-font table-row" key={group.id}>
                        <th scope="row">{i + 1}</th>
                        <td onClick={() => this.getGroup(group.id, group.name)}>{group.name}</td>
                    </tr>
                )
            )
        ) : (
            <p>no groups</p>
        );

        return (
            <div className="col-12 col-lg-12 d-flex csv-part flex-wrap">

                <UploadAndDownload/>

                {/*<PhoneNumbersGroups/>*/}
                <div  className="col-lg-4 col-12 mb-3 p-0 pr-lg-4">
                    <h6><strong>Select Group</strong></h6>
                    <div className="card shadow-sm p-4">
                        <table className="table">
                            <thead>
                            <tr className="copy-font">
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                            </tr>
                            </thead>

                            <tbody>

                              {loadGroup ? <img src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif" alt="loading"/> : groupList}

                            </tbody>

                        </table>
                    </div>
                </div>

                <PhoneNumbersGroupMembers id={id} name={name}/>

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {

        groups: state.groups.groups,
        newGroup: state.groups.group,

    }

};

export default connect(
  mapStateToProps,
  { getGroups },
)(Groups);
