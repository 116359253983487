import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from '../App';
import Calls from '../components/user/calls/Calls';
import Payments from '../components/user/payments/Payments';
import Queues from '../components/user/queue/Queues';
import Users from '../components/user/subscribers/Users';
import Processing from '../components/user/Processing';
import PhonePage from '../components/user/phones/Phone';
import Overview from '../components/user/overview/Overview';
import Files from '../components/user/files/FilesPage';
import Ivr from '../components/user/ivr/Ivr';
import ResetConfirm from '../components/auth/ResetConfirm';
import ForgotPassword from '../components/auth/ForgotPassword';
import Conferences from '../components/user/conference/Conferences';
import Wallboard from '../components/user/wallboard/Wallboard';
import BulkDialler from '../components/user/bulkdialler/BulkDialler';
import BusinessPage from '../components/user/business-page/BusinessPage';
import SuccessfulPayment from '../components/user/payments/SuccessfulPayment';
import Login from '../components/auth/Login';
import SignUp from '../components/auth/account/SignUp';
import Referral from '../components/auth/account/Referral';
import SelectAccountPage from '../components/auth/SelectAccountPage';
import Developers from '../components/user/developers/Developers';
import { PrivateRoute } from '../components/auth/PrivateRoute';
import FailedPayments from '../components/user/payments/FailedPayments';
import VerifyPhoneNumber from '../components/auth/verify/VerifyPhoneNumber';
import EnterCode from '../components/auth/account/EnterCode';
import VerifyAccount from '../components/user/verify/VerifyAccount';
import VerificationCode from '../components/auth/verify/VerificationCode';
import LoginWithEmail from '../components/auth/LoginWithEmail';
import { OTP_HISTORY_URL } from './app.url';
import OtpHistory from '../components/user/otp-history/OtpHistory';

const Routes = () => (
  <App>
    <Switch>
      {/* Login,Signup,reset */}
      <PrivateRoute exact path="/" component={Overview} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/login/:email" component={LoginWithEmail} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:token" component={ResetConfirm} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/verification" component={VerificationCode} />
      <Route exact path="/invite/:token" component={Referral} />
      <PrivateRoute
        exact
        path="/select-account"
        component={SelectAccountPage}
      />
      <Route exact path="/confirm-email/:token" component={VerifyPhoneNumber} />
      <Route exact path="/confirm-phone" component={VerifyPhoneNumber} />
      <Route exact path="/enter-code" component={EnterCode} />

      {/* Customer Portal */}
      <Route exact path="/wallboard/:usersip" component={Wallboard} />
      <Route exact path="/verify" component={Processing} />
      <Route exact path="/payment" component={SuccessfulPayment} />
      <Route exact path="/failed-payment" component={FailedPayments} />
      <Route exact path="/successful-payment" component={SuccessfulPayment} />
      <PrivateRoute exact path="/calls" component={Calls} />
      <PrivateRoute exact path="/users" component={Users} />
      <PrivateRoute exact path="/payments" component={Payments} />
      <PrivateRoute exact path="/files" component={Files} />
      <PrivateRoute exact path="/queues" component={Queues} />
      <PrivateRoute exact path="/conferences" component={Conferences} />
      <PrivateRoute exact path="/ivr" component={Ivr} />
      <PrivateRoute exact path="/bulk-dialler" component={BulkDialler} />
      <PrivateRoute exact path="/phone" component={PhonePage} />
      {/* <Route exact path="/processing-payment/verify?trxef=:token&reference=:token" component={Processing} /> */}
      <PrivateRoute exact path="/business-page" component={BusinessPage} />
      <PrivateRoute exact path="/developers" component={Developers} />
      <PrivateRoute exact path="/verify-account" component={VerifyAccount} />
      {/* <Route component={NotFound}/> */}
      <PrivateRoute exact path="/verify-account" component={VerifyAccount} />
      <PrivateRoute exact path={OTP_HISTORY_URL} component={OtpHistory} />

    </Switch>
  </App>
);

export default Routes;
