import {
    CHECK_VERIFICATION_STATUS,
    UPLOAD_IDENTITY,
    UPLOAD_IDENTITY_PROGRESS, UPLOAD_UTILITY, UPLOAD_UTILITY_PROGRESS
} from "../../actions/types/user/verify/VerifyTypes";

const initialState = {

    verificationStatus: "verified",
    uploadIdentityProgress: 0,
    identityUpload: '',
    uploadUtilityProgresss: 0,
    utilityUpload: ''

};

export const VerifyReducer = (state=initialState, action) => {
    switch (action.type) {
        case CHECK_VERIFICATION_STATUS:
            return {

                ...state,
                verificationStatus: action.payload

            };

        case UPLOAD_IDENTITY_PROGRESS:
            return {

                ...state,
                uploadIdentityProgress: action.payload

            };

        case UPLOAD_IDENTITY:
            return {

                ...state,
                identityUpload: action.payload

            };

        case UPLOAD_UTILITY_PROGRESS:
            return {

                ...state,
                uploadUtilityProgresss: action.payload

            };

        case UPLOAD_UTILITY:
            return {

                ...state,
                utilityUpload: action.payload

            };

        default:
            return state;

    }
};

export default VerifyReducer;