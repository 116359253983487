import React, {Component} from 'react';
import {connect} from "react-redux";
import {getUserBalance} from "../../../services/state-management/actions/common/users/UsersActions";
import PropTypes from 'prop-types';
import Helper from "../../util/Helper";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";

class Balance extends Component {

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        fetchUser: PropTypes.func.isRequired

    };

    componentDidMount() {

        this.props.getUserBalance();
        this.props.fetchUser();

    }

    checkPlan = () => {

        const {plan} = this.props.user;
        const {wallet} = this.props;

        if(plan){
            switch (plan.name) {
                case "Basic unlimited plan":
                    return "Unlimited";

                default:
                    return <b>{typeof (wallet) == "string" ? Helper.formatCurrency(wallet) : null}</b>

            }

        } else return null

    };

    render() {

        return (
            <div className="wallet-balance col-md-2 ml-auto mt-2 mb-3 p-0 col-8">
                <div className="icon pr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png" alt=""/>
                </div>
                <div>
                    <h5 className="count mt-1">
                        <span className="saving">
                            {this.checkPlan()}
                        </span>
                        <br />
                        <span className="baby-font">Wallet Balance</span></h5>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {

        files: state.files.files,
        wallet: state.wallet.wallet,
        user: state.auth.auth

    }

};

export default connect(mapStateToProps, {getUserBalance, fetchUser})(Balance);