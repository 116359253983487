import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { checkVerificationStatus } from '../services/state-management/actions/verify/VerifyActions';
import AuthUser from '../components/user/AuthUser';
import ContactUs from './ContactUs';
import { OTP_HISTORY_URL } from '../routes/app.url';

class MenuBar extends Component {
  componentDidMount() {
    this.props.checkVerificationStatus();
  }

  static propTypes = {
    checkVerificationStatus: PropTypes.func.isRequired,
  };

  render() {
    const { status } = this.props;

    return (
      <div className="col-lg-12 m-0 p-0">
        <nav
          className="navbar navbar-expand-lg navbar-light shift-up"
          id="shift-down"
        >
          <Link className="navbar-brand d-block d-lg-none" to="#">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1547554228/WifiCall_Logo_white-01.png"
              width="50%"
              alt="images"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto dashboard-image">
              <li className="nav-item mr-3">
                <Link className="nav-link" to="/">
                  <span className="mr-2 fas fa-stream" />
                  Overview
                </Link>
              </li>

              <li className="nav-item mr-3">
                <Link className="nav-link" to="/calls">
                  <span className="mr-2 fas fa-history" />
                  Call History
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/payments">
                  <span className="mr-2 fas fa-money-check" />
                  Payments
                </Link>
              </li>

              <li className="nav-item mr-3">
                <Link className="nav-link" to="/users">
                  <span className="mr-2 fas fa-user-friends" />
                  Users
                </Link>
              </li>

              <li className="nav-item dropdown mr-3">
                <Link
                  className="nav-link dropbtn"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to="/bulk-dialler"
                >
                  <span className="mr-2 fas fa-tty" />
                  Bulk Dialer
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropbtn"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to="#"
                >
                  <span className="mr-2 fas fa-suitcase" />
                  Services
                </Link>
                <div className="dropdown-content shadow-sm mb-4 bg-white border border-0">
                  <Link className="mb-2 p-2" to="/phone">
                    <span className="mr-2 fas fa-phone" />
                    Phone
                  </Link>
                  <Link
                    data-target="#contactUs"
                    data-toggle="modal"
                    className="mb-2 p-2"
                    to=""
                  >
                    <span className="mr-2 fas fa-headset" />
                    IVR
                  </Link>
                  <Link
                    data-target="#contactUs"
                    data-toggle="modal"
                    className="mb-2 p-2"
                    to=""
                  >
                    <span className="mr-2 fas fa-user-clock" />
                    Queues
                  </Link>
                  <Link className="mb-2 p-2" to="/conferences">
                    <span className="mr-2 fas fa-users" />
                    Conference
                  </Link>
                  <Link className="mb-2 p-2" to="/files">
                    <span className="mr-2 far fa-file-audio" />
                    Files
                  </Link>
                  <Link className="mb-2 p-2" to="/developers">
                    <span className="mr-2 fas fa-code" />
                    Developers
                  </Link>
                </div>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to={OTP_HISTORY_URL}>
                  <span className="mr-2 fas fa-money-check" />
                  OTP HISTORY
                </Link>
              </li>

              {status !== 'verified' && (
                <li className="nav-item dropdown mr-3">
                  <Link
                    className="nav-link dropbtn"
                    aria-haspopup="true"
                    aria-expanded="false"
                    to="/verify-account"
                  >
                    <span className="mr-2 far fa-address-card">
                      {/* <img src="https://res.cloudinary.com/teewhy/image/upload/v1548441057/bulk-dailler.fw.png" alt='' /> */}
                    </span>
                    Verify account
                  </Link>
                </li>
              )}
            </ul>


            <div className="add-section d-flex mt-2">
              <AuthUser />
              <ContactUs />
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.verificationStatus.verificationStatus,
});

export default connect(
  mapStateToProps,
  { checkVerificationStatus },
)(MenuBar);
