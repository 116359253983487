/* eslint-disable react/require-default-props */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadFile } from '../../../services/state-management/actions/files/FilesAction';

class AddFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      selectedFile: null,
      preview: '',
      filetype: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const { type, selectedFile, filetype } = this.state;

    if (type !== 'audio/wav') {
      toast.info('Please select an audio file!');
    } else {
      const data = new FormData();

      data.append('file', selectedFile);
      data.append('card_id', sessionStorage.getItem('cardid'));
      data.append('filebucket_type', filetype);
      data.append('filebucket_permission', 'public');
      data.append('workgroup_name', sessionStorage.getItem('workgroup'));

      // eslint-disable-next-line react/destructuring-assignment
      this.props.uploadFile(data);
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  fileSelected = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
      preview: e.target.files[0].name,
      type: e.target.files[0].type,
    });
  };

  submitForm() {
    const { selectedFile, filetype } = this.state;

    return selectedFile !== null && filetype.length > 0;
  }

  render() {
    const { preview, type, name } = this.state;
    const { loading, progress } = this.props;
    const isEnabled = this.submitForm();

    return (
      <div className="col-12 col-lg-4 col-md-5 mr-auto p-0 mb-5">
        <h6>
          <strong>Add File</strong>
        </h6>
        <div className="card o-income card-body wallboard-table">
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <div className="form-group">
              <div className="col-12 col-lg-12 mb-3">
                <label className="copy-font">File Name</label>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: '90%' }}
                  name="name"
                  value={name}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-12 col-lg-12 mb-3">
                <label htmlFor="">Select File Bucket Type</label>
                <select
                  name="filetype"
                  onChange={this.onChange}
                  className="form-control"
                >
                  <option value="">Select Type</option>
                  <option value="ivr">Ivr</option>
                  <option value="queue">Queue</option>
                  <option value="moh">Music on Hold</option>
                </select>
              </div>

              <div className="col-12 col-lg-12 mb-3">
                <label>File Upload</label>
                <div className="text-center mt-3 mb-2">
                  <span
                    className="fas fa-cloud-upload-alt"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.fileInput.click()}
                  />
                  <input
                    required
                    type="file"
                    style={{ display: 'none' }}
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    onChange={this.fileSelected}
                    className="form-control mt-2 p-2"
                  />
                </div>
                {/* eslint-disable-next-line no-constant-condition */}
                {type === 'audio/wav' || 'audio/mp3' ? (
                  <small>{preview}</small>
                ) : (
                  <small className="text-center" style={{ fontSize: '78%' }}>
                    Click to upload IVR Recording (wav format supported) maximum
                    size 5mb
                  </small>
                )}
                <br />
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}

            <div
              className="progress-bar"
              style={{ width: '100%' }}
              role="progressbar"
              value={progress}
            >
              <span>{progress ? `${Math.round(progress, 2)}%` : null}</span>
            </div>

            <div className="text-center">
              {loading ? (
                <button
                  disabled={loading}
                  className="btn second-button text-white"
                >
                  <i className="fa fa-spinner fa-spin" />
                  Uploading...
                </button>
              ) : (
                <button
                  disabled={!isEnabled}
                  className="btn second-button text-white"
                >
                  Upload
                </button>
              )}
            </div>
          </form>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    progress: state.files.uploadFileProgress,
    loading: state.files.load,
  };
};

AddFile.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  progress: PropTypes.number,
};

export default connect(
  mapStateToProps,
  { uploadFile },
)(AddFile);
