export const API_URL = 'https://api.tizeti.com/api/v1';
// export const baseUrl = {
//     headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`
//     }
// };

export const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
};
