/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React from 'react';
import moment from 'moment';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import {
  DOWNLOAD_RECORDING_URL,
  DOWNLOAD_RECORDING_URL_BUSINESS,
} from '../../../services/apis/calls/CallsUrl';

const RecordingsTable = ({ recordings, load }) => {
  const showCallStatus = (cause) => {
    switch (cause) {
      case '1':
        return <small>Answered</small>;
      case '2':
        return <small>Busy</small>;
      case '3':
        return <small>No Answer</small>;
      case '4':
        return <small>CANCELLED</small>;
      case '5':
        return <small>CONGESTION</small>;
      default:
        return null;
    }
  };
  if (load) {
    return (
      <img
        src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif"
        alt="loading gif"
      />
    );
  }

  const callList = recordings ? (
    // eslint-disable-next-line react/prop-types
    recordings.map((recording, i) => {
      const id = btoa(`${recording.uniqueid}.wav`);
      return (
        <tr key={i}>
          <th>{i + 1}</th>
          {recording.src ? (
            <td>{recording.src.toUpperCase()}</td>
          ) : (
            <td>N/A</td>
          )}
          {recording.calledstation ? (
            <td>
              {recording.calledstation.includes('PJSIP/')
                ? recording.calledstation.replace('PJSIP/', '#')
                : recording.calledstation.toUpperCase()}
            </td>
          ) : (
            <td>N/A</td>
          )}
          {recording.starttime ? <td>{recording.starttime}</td> : <td>N/A</td>}
          {recording.real_sessiontime ? (
            <td>
              {moment
                .utc(Number(recording.real_sessiontime) * 1000)
                .format('HH:mm:ss')}
            </td>
          ) : (
            <td>N/A</td>
          )}
          <td>{showCallStatus(recording.terminatecauseid)}</td>
          <td className="text-center">
            {recording.terminatecauseid === '1' ? (
              <div style={{ height: '60px', width: '18rem' }}>
                <ReactPlayer
                  url={`${DOWNLOAD_RECORDING_URL}${id}`}
                  width="90%"
                  height="60px"
                  controls
                />
              </div>
            ) : null}
          </td>

          <td className="text-center">
            {recording.terminatecauseid === '1' ? (
              <div style={{ height: '60px', width: '18rem' }}>
                <ReactPlayer
                  url={`${DOWNLOAD_RECORDING_URL_BUSINESS}${id}`}
                  width="90%"
                  height="60px"
                  controls
                />
              </div>
            ) : null}
          </td>
        </tr>
      );
    })
  ) : (
    <small>no calls</small>
  );

  return <tbody>{callList}</tbody>;
};

RecordingsTable.propTypes = {
  recordings: PropTypes.shape,
  load: PropTypes.bool,
};

export default RecordingsTable;
