import React from 'react';

const DownloadSample = () => {
  return (
    <div>
      <h6>
        <strong>Download Sample CSV format</strong>
      </h6>
      <div className="shadow-sm p-4 text-center">
        <div>
          <a href="https://res.cloudinary.com/teewhy/raw/upload/v1554910153/samplecsv.csv">
            <span className="fas fa-file-download" />
            {/* <img src="https://res.cloudinary.com/teewhy/image/upload/v1548426517/csv-income.fw.png" alt="csv-incoming"/> */}
          </a>
        </div>

        <div>
          <small>Click to download csv format</small>
        </div>
      </div>
    </div>
  );
};

export default DownloadSample;
