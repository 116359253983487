/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URL } from '../../config/BaseUrl';
import { fetchUser } from '../../../services/state-management/actions/auth/AuthActions';

class Call extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      dialled: '',
      attempt: false,
    };

    this.call = this.call.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchUser();
  }

  call = async () => {
    const { plan, phoneNumber } = this.props.user;

    if (plan.name === 'Basic unlimited plan') {
      toast.info(
        `This service is not available to users on basic unlimited plan`,
      );
    } else {
      const { connected, dialled } = this.state;
      const result = dialled.replace(/[^\d]+/g, '');

      if (phoneNumber === dialled && phoneNumber === result) {
        toast.info(
          'Please enter the number you wish to call, you can not call yourself!',
        );
        this.setState({
          dialled: '',
        });
      } else {
        this.setState({
          attempt: true,
        });

        if (connected) {
          this.setState({
            connected: false,
          });
        }

        if (dialled === '') {
          toast.error('Please enter the number you want to call!');
          this.setState({
            attempt: false,
          });
        }

        const data = {
          called: phoneNumber,
          calling: result,
          username: sessionStorage.getItem('username'),
        };

        try {
          const response = await axios.post(`${API_URL}/makecall`, data, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });

          if (response.data.status === true) {
            toast.info('Please check your mobile phone.');
            this.setState({
              connected: true,
              attempt: false,
            });
          }

          if (response.data.status === false) {
            toast.info('Call Failed');
          }
        } catch (error) {
          if (error) {
            toast.info('Call Failed');
          }
        }
      }
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  checkUserPlan() {
    const { user } = this.props;
    const { plan } = user;

    if (plan) {
      if (plan.name === 'Basic unlimited plan') {
        return (
          <div className="mt-4">
            <small style={{ fontSize: '16px' }}>Disabled!</small>
          </div>
        );
      }
      return (
        <img
          onClick={this.call}
          src="https://res.cloudinary.com/teewhy/image/upload/v1548069587/Call_Button.png"
          alt=""
          className="call-button"
        />
      );
    }
    return null;
  }

  render() {
    const { connected, attempt, dialled } = this.state;
    const { user } = this.props;
    const { plan } = user;
    return (
      <div className="col-md-4 col-12 mb-3">
        <div className="boxes d-flex">
          <form style={{ marginTop: '-10%' }}>
            <div className="signs text-center mb-2">
              <h6 className="text-center mb-0">
                <strong>Dial </strong>
              </h6>
              {attempt ? (
                <p className="mb-0">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1551362876/connec.png"
                    alt="connected-icon"
                    style={{ width: '5%' }}
                  />
                  <span> Connecting </span>
                </p>
              ) : null}
              {connected ? (
                <p className="mb-0">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1548342137/green_sign.png"
                    alt="connected-icon"
                  />
                  <span> Connected </span>
                </p>
              ) : null}
            </div>
            <div className="d-flex col-12 m-0 p-0">
              <div className="col-9 m-0 p-0 mr-2">
                <div className="mb-2">
                  {plan ? (
                    <input
                      type="tel"
                      className="shadow-sm form-control receive mt-4"
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                      placeholder="To"
                      name="dialled"
                      value={dialled}
                      onChange={
                        plan.name === 'Basic unlimited plan'
                          ? null
                          : this.onChange
                      }
                    />
                  ) : (
                    <input
                      type="tel"
                      className="shadow-sm form-control receive mt-4"
                      placeholder="To"
                      name="dialled"
                      value={dialled}
                    />
                  )}
                </div>
              </div>

              <div className="col-3 m-0 p-0">
                <img
                  onClick={this.call}
                  src="https://res.cloudinary.com/teewhy/image/upload/v1548069587/Call_Button.png"
                  alt=""
                  className="call-button"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.auth,
});

Call.propTypes = {
  plan: PropTypes.shape,
  user: PropTypes.shape,
  fetchUser: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { fetchUser },
)(Call);
