import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFiles } from '../../../services/state-management/actions/files/FilesAction';
import FilesTable from './FilesTable';

class Files extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getFiles();
  }
  static getDerivedStateFromProps(nextProps) {
    // eslint-disable-next-line react/prop-types
    if (nextProps.file.filebucket_number) {
      nextProps.getFiles();
    }

  }

  render() {
    const { files } = this.props;

    return (
      <div className="mr-4 col-12 col-lg-7 col-md-5 p-0">
        <h6>
          <strong>Files</strong>
        </h6>
        <div className="card o-income card-body">
          <div style={{ overflowX: 'auto' }}>
            <table className="table copy-font">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">File Name</th>
                  {/* <th scope="col">Play</th> */}
                  <th scope="col" className="text-center">
                    Download
                  </th>
                  {/* <th scope="col" className="text-center">Actions</th> */}
                </tr>
              </thead>
              <FilesTable files={files} />
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files.files,
    file: state.files.file,
  };
};

Files.propTypes = {
  getFiles: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  { getFiles },
)(Files);
