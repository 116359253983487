import React, {Component} from 'react';
import {connect} from "react-redux";
import {getGroups} from "../../../../../services/state-management/actions/bulk-dialler/general/GroupsActions";
import PropTypes from 'prop-types';
import {createCampaign} from "../../../../../services/state-management/actions/bulk-dialler/campaigns/CampaignsActions";

class CreateCampaign extends Component {

    constructor(props) {
        super(props);

        this.state = {

            campaign: '',
            group: '',
            loading: false

        };

        this.onChange = this.onChange.bind(this);
        this.createCampaign = this.createCampaign.bind(this);

    }

    static propTypes = {

        createCampaign: PropTypes.func.isRequired

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    createCampaign = async (e) => {

        e.preventDefault();

        this.setState({

            loading: true

        });

        const {campaign, group} = this.state;

        const data = {

            campaign_name: campaign,
            group_id: group

        };

        this.props.createCampaign(data);

    };

    componentDidMount() {

        this.props.getGroups()

    }

    submitForm(){

        const {campaign, group} = this.state;

        return(

            campaign.length > 0 &&
            group.length > 0

        )

    }

    componentWillReceiveProps(nextProps) {

        if(nextProps){

            this.setState({

                loading: nextProps.loading,
                campaign: '',
                group: '',

            })

        }

    }

    render() {

        const {groups} = this.props;
        const {campaign, loading} = this.state;
        const isEnabled = this.submitForm();

        return (
            <div className="col-lg-4 col-12 p-0 mb-2" id="campaign">
                <h6><strong>Create Campaign</strong></h6>
                <div className="shadow-sm">
                    <form className="form-horizontal" onSubmit={this.createCampaign}>
                        <div className="form-group p-3">
                            <div className="col-12 d-flex m-0 p-0">
                                <div className="col-6">
                                    <label className="m-0 p-0">Campaign Name</label>
                                    <input type="text"
                                           placeholder="Campaign Name"
                                           className="form-control w-100"
                                           name="campaign"
                                           value={campaign}
                                           onChange={this.onChange}
                                    />
                                </div>

                                <div className="col-6">
                                    <label className="m-0 p-0">Group</label>
                                    {/*<input type="number"*/}
                                    {/*placeholder="Group ID"*/}
                                    {/*className="form-control w-100"*/}
                                    {/*name="group"*/}
                                    {/*value={this.state.group}*/}
                                    {/*onChange={this.onChange}*/}
                                    {/*/>*/}
                                    <select className="form-control" name="group" onChange={this.onChange}>
                                        <option value="">Choose Group</option>
                                        {groups.map(
                                            (group) => {
                                                return <option value={group.id}>{group.name}</option>
                                            }

                                        )}
                                    </select>
                                </div>
                            </div>

                            {/*<div className="col-12 mt-3 mb-5">*/}
                            {/*<label className="m-0 p-0">Select Campaign Type</label>*/}
                            {/*<input type="text"*/}
                            {/*placeholder="Click to Call"*/}
                            {/*className="form-control w-100"*/}
                            {/*name="lastname"*/}
                            {/*readOnly*/}
                            {/*/>*/}
                            {/*/!*<div>*!/*/}
                            {/*/!*<input type="radio" /> AutoDail*!/*/}
                            {/*/!*</div>*!/*/}

                            {/*/!*<div>*!/*/}
                            {/*/!*<input type="radio" /> Click to call*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*</div>*/}

                            {loading ?
                                <div className="col-12 text-center mt-4">
                                    <button className="btn second-button text-white" disabled={true}><i className="fa fa-spinner fa-spin"></i>Creating...</button>
                                </div> :
                                <div className="col-12 text-center mt-4">
                                    <button className="btn second-button text-white" disabled={!isEnabled}>Create Campaign</button>
                                </div>}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    groups: state.groups.groups,
    loading: state.loadCreating.loadCreating,
    campaign: state.campaigns.campaign


});

export default connect(mapStateToProps, {getGroups, createCampaign})(CreateCampaign);