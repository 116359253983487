import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../../components/config/BaseUrl';
import {
  CREATE_CAMPAIGN,
  GET_CAMPAIGNS,
} from '../../types/bulk-dialler/CampaignTypes';
import { CREATE_CAMPAIGN_URL } from '../../../../apis/bulk-dialler/CampaignsUrl';

export const createCampaign = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${CREATE_CAMPAIGN_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      toast.success('Campaign created successfully!');

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: {
          response: response.data,
          load: false,
        },
      });
    }
  } catch (e) {
  }
};

export const getCampaigns = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/wificall/campaign/campaign`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_CAMPAIGNS,
        payload: response.data,
      });
    }
  } catch (e) {
  }
};
