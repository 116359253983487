import { toast } from 'react-toastify';
import axios from 'axios';
import { GROUPS_URL } from '../../../../apis/bulk-dialler/BulkdiallerUrl';
import {
  CREATE_GROUP,
  FETCH_GROUPS,
} from '../../types/bulk-dialler/GroupsTypes';
import { API_URL } from '../../../../../components/config/BaseUrl';

export const getGroups = () => async (dispatch) => {
  const response = await axios.get(`${GROUPS_URL}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.data) {
    dispatch({
      type: FETCH_GROUPS,
      payload: response.data,
    });
  }
};

export const getGroup = (id) => async (dispatch) => {
  const url = `${API_URL}/wificall/campaignNumbers/list/${id}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.data.rows) {
    return {
      type: 'GET_GROUP',
      payload: response.data.rows,
    };
  }
};

export const createGroup = (newGroup) => (dispatch) => {
  const url = `${API_URL}/wificall/groups/campaign`;
  axios
    .post(url, newGroup, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      if (res.data.id) {
        toast.success('Group successfully created', {
          onClose() {
            window.location.reload();
          },
        });
        dispatch({
          type: CREATE_GROUP,
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.info(error.response.data.message);
      }
    });
};
