/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchUser } from '../services/state-management/actions/auth/AuthActions';
import { API_URL } from '../components/config/BaseUrl';
import {
  PAYSTACK_BASIC_CHARGE,
  PAYSTACK_LIVE_KEY,
} from '../components/config/Payment';

class SubscribePage extends Component {
  static propTypes = {
    getFiles: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      amount: '',
      url: false,
      status: false,
    };
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  expectedPrice = (amountPaid, amountInc) => {
    amountPaid = amountPaid / 100;
    return ((amountPaid + amountInc) / (1 - `${PAYSTACK_BASIC_CHARGE}`)) * 100;
  };

  subscribe = (e) => {
    e.preventDefault();
    this.setState({
      status: false,
    });
    let handler;
    const amount = this.state.amount * 100;

    if (amount < 50000) {
      toast.info('You can not pay less than 500 naira');
    } else if (amount < 250000) {
      const newAmount = amount / (1 - `${PAYSTACK_BASIC_CHARGE}`);
      handler = window.PaystackPop.setup({
        key: `${PAYSTACK_LIVE_KEY}`,
        email: sessionStorage.getItem('email'),
        amount: Math.round(newAmount),
        ref: `${Math.floor(Math.random() * 1000000000 + 1)}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        firstname: sessionStorage.getItem('firstname'),
        lastname: sessionStorage.getItem('lastname'),
        metadata: {
          custom_fields: [
            {
              email: sessionStorage.getItem('email'),
              card_id: sessionStorage.getItem('cardid'),
              amount: this.state.amount * 100,
              source: 'web',
            },
          ],
        },
        callback(response) {
          /* alert('success. transaction ref is ' + response.reference); */
          if (response.reference) {
            const ref = response.reference;
            const url = `${API_URL}/verify?reference=${ref}`;
            axios({
              method: 'get',
              url,
            }).then((res) => {
              if (res.data) {
                if (
                  res.data.status === true &&
                  res.data.message === 'PostPayment SUCCESS'
                ) {
                  toastr.success('Transaction Successful');
                  window.location.reload();
                }
              }
            });
            this.setState({
              reference: response.reference,
            });
          }
        },
        onClose() {
          // alert('window closed');
        },
      });
      handler.openIframe();
    } else {
      const newAmount = this.expectedPrice(amount, 100);
      handler = window.PaystackPop.setup({
        key: `${PAYSTACK_LIVE_KEY}`,
        email: sessionStorage.getItem('email'),
        amount: Math.round(newAmount),
        ref: `${Math.floor(Math.random() * 1000000000 + 1)}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        firstname: sessionStorage.getItem('firstname'),
        lastname: sessionStorage.getItem('lastname'),
        // label: "Optional string that replaces user email"
        metadata: {
          custom_fields: [
            {
              email: sessionStorage.getItem('email'),
              card_id: sessionStorage.getItem('cardid'),
              amount: this.state.amount * 100,
              source: 'web',
            },
          ],
        },
        callback(response) {
          if (response.reference) {
            const ref = response.reference;
            const url = `${API_URL}/verify?reference=${ref}`;
            axios({
              method: 'get',
              url,
            }).then((res) => {
              if (res.data) {
                if (
                  res.data.status === true &&
                  res.data.message === 'PostPayment SUCCESS'
                ) {
                  toastr.success('Transaction Successful');
                  window.location.reload();
                }
              }
            });
            this.setState({
              reference: response.reference,
            });
          }
        },
        onClose() {
          // alert('window closed');
        },
      });
      handler.openIframe();
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { url, redirect, status } = this.state;

    const { firstName, lastName, email } = this.props.user;

    if (redirect) {
      return <Link to={url}/>;
    }

    return (
      <div
        className="modal fade in"
        id="page"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header col-12">
              <div className="col-10 ml-5">
                <h4 className="modal-title text-center">Enter Amount</h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>

            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.subscribe}>
                <div className="form-group">
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">First Name</label>
                      <input
                        type="text"
                        className="form-control copy-font w-100"
                        name="firstName"
                        value={firstName}
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Last Name</label>
                      <input
                        type="text"
                        className="form-control copy-font w-100"
                        name="lastName"
                        value={lastName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-3 d-flex">
                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Enter Email</label>
                      <input
                        type="email"
                        className="form-control copy-font w-100"
                        name="email"
                        value={email}
                        readOnly
                      />
                    </div>

                    <div className="col-6">
                      <label className="copy-font m-0 p-0">Enter Amount</label>
                      <input
                        type="number"
                        placeholder="Enter Amount"
                        className="form-control copy-font w-100"
                        name="amount"
                        onChange={this.onChange}
                        value={this.state.amount}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    {status === false ? (
                      <button
                        type="submit"
                        className="btn btn-green text-white"
                      >
                        Top-Up
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-green text-white"
                        disabled
                      >
                        Processing Payment...
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.auth,
});

export default connect(
  mapStateToProps,
  { fetchUser },
)(SubscribePage);
