import axios from 'axios';
import { useEffect, useState } from 'react';
import { LOGGED_IN_USER_URL } from '../../services/apis/user/UserUrl';

const useNin = () => {
  const [nin, setNIN] = useState(true);
  useEffect(() => {
    async function getNin() {
      return axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
    }
    getNin().then((response) => {
      setNIN(response.data.nin);
    });
  }, []);
  return { nin };
};
export default useNin;
