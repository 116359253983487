import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/login.css';
import useAuth from './hooks/useAuth';

const LoginWithEmail = () => {
  const { loading, fetching } = useAuth();
  return (
    <div className="main-wrapper">
      <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
        <div className="login col-sm-12 col-lg-12 col-md-12">
          <div className="tizeti-logo">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
              alt="images"
            />
          </div>
          
          <h6 className="mt-3 text-center">
            <strong>
              Welcome Back! Login to manage your wificall account (s)
            </strong>
          </h6>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="form-horizontal mt-3 login-form col-sm-12 col-lg-12 col-md-12"
          >
            {/* <div className="form-group col-lg-9 col-sm-12">
              <div>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  // value={email}
                  // onChange={this.onChange}
                />
              </div>
              <br />
            </div> */}
            <br />
            <br />

            {loading || fetching ? (
              <div className="wrapper-button mb-3 col-lg-9 p-0">
                <button
                  type="button"
                  className="btn btn-green w-100"
                  id="login"
                  style={{ backgroundColor: '#35BEAA', color: 'white' }}
                  disabled={loading || fetching}
                >
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: '24px' }}
                  />
                </button>
              </div>
            ) : (
              <div className="wrapper-button mb-3 col-lg-9 p-0">
                <button
                  type="submit"
                  className="btn btn-green w-100"
                  style={{ backgroundColor: '#35BEAA', color: 'white' }}
                  // data-toggle="modal"
                  // data-target="#downloadAppModal"
                >
                  LOGIN
                </button>
              </div>
            )}

            {/* <p className="text-center" style={{ fontSize: '10px' }}>
              Don&apos;t have an account?
              <strong>
                <Link
                  to="/sign-up"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {' '}
                  Create Account
                </Link>
              </strong>
            </p>
            */}
          </form>
        </div>
      </div>
    </div>
  );
};
export default LoginWithEmail;
