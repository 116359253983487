import axios from 'axios';
import { toast } from 'react-toastify';
import {
  ASSIGN_NUMBER_URL,
  GET_AVAILABLE_NUMBERS_URL,
  USER_ALLOCATED_NUMBERS_URL,
} from '../../../apis/PhoneUrl';
import {
  ASSIGN_NUMBER,
  GET_AVAILABLE_NUMBERS,
  GET_USER_ALLOCATED_NUMBERS,
} from './PhoneTypes';
import { GET_ERRORS } from '../types/ErrorsTypes';

export const getUserAllocatedNumbers = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${USER_ALLOCATED_NUMBERS_URL}/${sessionStorage.getItem('cardid')}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (response.data.status === true) {
      dispatch({
        type: GET_USER_ALLOCATED_NUMBERS,
        payload: {
          response: response.data.result,
          load: false,
        },
      });
    } else {
      toast.info("Could not fetch User's Phone number allocations");
    }
  } catch (e) {
    toast.info('Something went wrong. Please try again');
  }
};

export const getAvailableNumbers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_AVAILABLE_NUMBERS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      dispatch({
        type: GET_AVAILABLE_NUMBERS,
        payload: response.data.result,
      });
    }
  } catch (e) {}
};

export const assignNumber = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${ASSIGN_NUMBER_URL}`, data);

    if (response.data.status === false) {
      // message: "ERROR PHONE NUMBER ALREADY ASSIGNED. PLEASE CHECK ID"
      // result: false
      // status: false
      toast.warn(response.data.message);
      dispatch({
        type: GET_ERRORS,
        payload: response.data.status,
      });
    }

    if (response.data.status === true) {
      toast.success('Number assigned successfully!');

      dispatch({
        type: ASSIGN_NUMBER,
        payload: {
          response: response.data,
          load: false,
        },
      });
    }
  } catch (e) {
    toast.info('Something went wrong!');
  }
};
