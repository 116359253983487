import React, { Component } from 'react';
import "react-toastify/dist/ReactToastify.min.css";
import {addConference} from "../../../services/state-management/actions/common/conferences/ConferencesActions";
import {connect} from "react-redux";

class AddConference extends Component {

    constructor(props) {
        super(props);

        this.state = {

            name: '',
            description: '',
            loading: false,

        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }


    onSubmit = e => {

        e.preventDefault();

        this.setState({

            loading: true

        });

        const data = {

            conference_name: this.state.name,
            conference_description: this.state.description,
            card_id: sessionStorage.getItem('cardid'),
            workgroup_name: `${sessionStorage.getItem('workgroup')}`

        };

        this.props.addConference(data);

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    submitForm = () => {

        const { name, description } = this.state;

        return(

            name.length > 0 &&
                description.length > 0

        );

    };

    componentWillReceiveProps(nextProps, nextContext) {
        
        if(nextProps.load === false){

            this.setState({

                name: '',
                description: '',
                loading: nextProps.load

            })
        }

    }

    render() {

        const { loading, name, description } = this.state;
        const isEnabled = this.submitForm();

        return (
        <div className="col-12 col-lg-4 col-md-5 mb-2 p-0 mr-auto">
            <h6><strong>Add Conference</strong></h6>
            <div className="card o-income card-body wallboard-table">
                <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <div className="col-12 col-lg-12 mb-3">
                            <label className="copy-font">Conference Name</label>
                            <input
                                type="text"
                                className="form-control"
                                style={{width:"90%"}}
                                name="name"
                                value={name}
                                onChange={this.onChange}
                            />
                        </div>

                        <div className="col-12 col-lg-12 mb-3">
                            <label>Description</label>
                            <textarea name="description"
                                      value={description}
                                      onChange={this.onChange}
                                      rows="4"
                                      cols="50"
                                      className="form-control"
                                      style={{width:"90%"}}>

                                </textarea>
                        </div>

                    </div>

                    <div className="text-center">
                        {loading ?
                            <button className="btn second-button text-white"
                            disabled={true}>
                                Adding...<i className="fa fa-spinner fa-spin" style={{ fontSize:'24px'}}></i>
                            </button> :
                            <button className="btn second-button text-white" disabled={!isEnabled}>Add Conference</button>}
                    </div>
                </form>
            </div>
        </div>
    )
  }
}

const mapStateToProps = state => ({

    conference: state.conference.conference,
    load: state.loadAddConference.loadAddConference

});

export default connect(mapStateToProps, {addConference})(AddConference);