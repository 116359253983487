/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line jsx-a11y/label-has-associated-control

import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import PropTypes from 'prop-types';

class AddUser extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    getSubscribers: PropTypes.func.isRequired,
    addUser: PropTypes.func,
    first: PropTypes.string,
    last: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    load: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { first, last, phone, email, addUser } = this.props;
    const subscriber = {
      email,
      firstName: first,
      lastName: last,
      phoneNumber: phone,
      workgroup: sessionStorage.getItem('workgroup'),
    };
    addUser(subscriber);
  };

  render() {
    const { first, last, phone, email, load, handleChange } = this.props;

    return (
      <div className="col-12 col-lg-4 mt-4 col-md-5 mr-auto">
        <div className="card o-income card-body wallboard-table shadow-sm p-3 bg-white">
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <div className="form-group">
              <div className="col-12 col-lg-12 d-flex mb-3">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="copy-font m-0 p-0">First Name</label>
                  <input
                    type="text"
                    className="form-control shadow-sm p-2 mb-4 bg-white"
                    name="first"
                    onChange={handleChange}
                    value={first}
                  />
                </div>

                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="copy-font m-0 p-0">Last Name</label>
                  <input
                    type="text"
                    className="form-control shadow-sm p-2 mb-4 bg-white"
                    name="last"
                    onChange={handleChange}
                    value={last}
                  />
                </div>
              </div>

              <div className="col-12 col-lg-12 d-flex mb-3">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="copy-font m-0 p-0">Phone Number</label>
                  <input
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    value={phone}
                    className="form-control shadow-sm p-2 mb-4 bg-white"
                  />
                </div>

                <div>
                  <label className="copy-font m-0 p-0">Email Address</label>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    className="form-control shadow-sm p-2 mb-4 bg-white"
                  />
                </div>
              </div>
            </div>
            {load ? (
              <div className="text-center">
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                  className="btn second-button text-white"
                  disabled={load}
                >
                  Adding...
                </button>
              </div>
            ) : (
              <div className="text-center">
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn second-button text-white">
                  Add User
                </button>
              </div>
            )}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
          </form>
        </div>
        
      </div>
    );
  }
}

export default AddUser;
