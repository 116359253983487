import React, { Component } from 'react'

class ViewSubscriber extends Component {
  render() {

      const subscriber = this.props.user;

    return (
    <div  className="modal fade in" id="viewsubscriber" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="d-flex col-11" style={{justifyContent:"center"}}>
                        <h4 className="modal-title">Subscriber Details</h4>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
        
                <div className="modal-body">
                    <div className="col-12 d-flex">
                        <div className="col-5">
                            <p>First Name</p>
                            <p>Last Name</p>
                          {/*  <p>Phone Number</p>*/}
                            <p>Email Address</p>
                            <br />
                            <br />
                            <p>Status</p>
                            <p>Balance</p>
                        </div>

                        <div className="col-7 p-0">
                            {subscriber.firstname ? <p><b>{subscriber.firstname}</b></p> : <p>N/A</p>}
                            {subscriber.lastname ? <p><b>{subscriber.lastname}</b></p> : <p>N/A</p>}
{/*
                            {subscriber.phone ? <p><b>{this.props.user.phone}</b></p> : <p>N/A</p>}
*/}
                            {subscriber.email ? <p><b>{subscriber.email}</b></p> : <p>N/A</p>}
                            <br />
                            <br />
                            <p><b>Enabled</b></p>
                            {subscriber.credit ? <p><b> N {Number(subscriber.credit).toLocaleString()}</b></p> : <p>N/A</p>}
                        </div>
                    </div>

                     {/* <div className="text-center">
                            <button className="btn second-button text-white">Edit</button>
                        </div> */}
                </div>
            </div>
             {/*modal-content */}
        </div>
    </div>
    )
  }
}

export default ViewSubscriber;
