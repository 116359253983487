/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { fetchUser } from '../../services/state-management/actions/auth/AuthActions';
import { editUserDetails } from '../../services/state-management/actions/common/users/UsersActions';
import { LOGGED_IN_USER_URL } from '../../services/apis/user/UserUrl';

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      wallet: '',
      state_nin: '',
      plan: '',
      nin: '',
      workgroup: '',
      username: '',
      user: [],
      profile: {},
      id: '',
    };
  }

  componentDidMount() {
    this.getUser();
    this.props.fetchUser();
  }

  getUser() {
    axios({
      method: 'get',
      url:LOGGED_IN_USER_URL,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        this.setState({
          user: response.data,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          plan: response.data.plan.name,
          state_nin: response.data.nin,
          nin: response.data.nin,
          id: response.data.id,
          address: response.data.address,
          workgroup: response.data.user_sip.workgroup,
          username: response.data.user_sip.username,
        });
      })
      .catch((error) => {});
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  editUser = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      wallet,
      plan,
      nin,
      address,
      workgroup,
      username,
    } = this.state;

    const profile = {
      firstName,
      lastName,
      email,
      phoneNumber,
      wallet,
      plan,
      nin,
      address,
      workgroup,
      username,
    };

    this.props.editUserDetails(profile, this.state.id);
  };

  submitForm = () => {
    const { nin } = this.state;
    return nin === null ? null : nin.length === 11 || nin.length === 12;
  };

  render() {
    const isEnabled = this.submitForm();
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      plan,
      nin,
      state_nin,
      address,
    } = this.state;

    return (
      <div
        className="modal fade in"
        id="profile"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="d-flex col-11"
                style={{ justifyContent: 'center' }}
              >
                <h4 className="modal-title">Edit Profile</h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onSubmit={this.editUser}
                aria-hidden="true"
              >
                ×
              </button>
            </div>

            <div className="modal-body">
              <form className="form-horizontal" onSubmit={this.editUser}>
                <div className="form-group">
                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="m-0 p-0">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={firstName}
                        value={firstName}
                        name="firstName"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-6">
                      <label className="m-0 p-0">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={lastName}
                        value={lastName}
                        name="lastname"
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="m-0 p-0">Phone Number</label>
                      <input
                        type="number"
                        className="form-control"
                        // placeholder={phone}
                        value={phoneNumber}
                        name="phone"
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <label className="m-0 p-0">Plan</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={plan}
                        name="plan"
                        value={plan}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                      <label className="m-0 p-0">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={email}
                        value={email}
                        name="email"
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <label className="m-0 p-0">NIN</label>
                      <input
                        type="tel"
                        pattern="[0-9]*"
                        className="form-control"
                        title="NIN Number"
                        name="nin"
                        value={nin}
                        onChange={this.onChange}
                        readOnly={state_nin !== null && state_nin !== ''}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-3">
                    <div className="col-12">
                      <label className="m-0 p-0">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={address}
                        placeholder={address}
                        name="address"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    disabled={!isEnabled}
                    className="btn second-button text-white"
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(
  mapStateToProps,
  { fetchUser, editUserDetails },
)(EditProfile);
