/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import MenuBar from '../../../layouts/MenuBar';
import UsersTable from './UsersTable';
import ButtonSet from '../../../layouts/ButtonSet';
import { fetchStatusAndUsersAndAdmin } from '../../../services/state-management/actions/common/users/UsersActions';
import AddUser from './AddUser';
import tizeti from '../../../services/apis/tizeti';
import Balance from './Balance';
import { getUsersInWorkgroup } from '../../../services/state-management/actions/workgroups/WorkgroupsActions';
import { ADD_A_SUBSCRIBER_URL } from '../../../services/apis/user/UserUrl';
import NotificationNIN from '../../../layouts/NotificationNIN';

class Users extends Component {
  static propTypes = {
    getUsersInWorkgroup: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    users: PropTypes.object,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      first: '',
      last: '',
      phone: '',
      load: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUsersInWorkgroup();
  }

  addUser = async (data) => {
    this.setState({
      load: true,
    });

    try {
      const response = await axios.post(`${ADD_A_SUBSCRIBER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.data.status === 'success') {
        this.setState({
          email: '',
          first: '',
          last: '',
          phone: '',
          load: false,
        });

        toast.success('User added successfully!');
        // eslint-disable-next-line react/destructuring-assignment
        this.props.getUsersInWorkgroup();
      }
    } catch (e) {
      this.setState({
        load: false,
      });
      if (e.response.status >= 400 && e.response.status < 500) {
        toast.info(e.response.data.message);
      }else {
        toast.info('Something went wrong. Please try again!');
      }
      
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  // eslint-disable-next-line consistent-return
  makeUser(status) {
    const { user } = this.props;
    const usernameurl = `/web/usersip/${user.username}`;

    if (status.status === 'user') {
      tizeti.get(usernameurl).then((res) => {
        if (res.data.wificall_user_id) {
          const data = {
            status: 'lead',
          };

          tizeti
            .put(`/wificall/status/${res.data.wificall_user_id}`, data)
            .then((res) => {
              if (res.data[1]) {
                toast.success('Status has been changed!');
                this.props.fetchStatusAndUsersAndAdmin(this.props.userid);
              }
            });
        }
      });
    } else if (status.status === 'lead') {
      tizeti.get(usernameurl).then((res) => {
        if (res.data.wificall_user_id) {
          const data = {
            status: 'user',
          };

          tizeti
            .put(`/wificall/status/${res.data.wificall_user_id}`, data)
            .then((res) => {
              if (res.data[1]) {
                toast.success('Status has been changed!');
                this.props.fetchStatusAndUsersAndAdmin(this.props.userid);
              }
            });
        }
      });
    } else return null;
  }

  render() {
    const { users, loading } = this.props;
    const { first, last, phone, email, load } = this.state;

    return (
      <div className="body">
        <div className="main-wrapper">
          <div className="container-fluid">
            <div className="row">
              <NotificationNIN />
            </div>
            <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
                alt="logo"
                width="12%"
                height="15%"
              />
            </div>
            <div className="row">
              <MenuBar />
            </div>
            <div className="container-fluid" id="resize-fluid">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="col-12 col-lg-12 d-flex second-child p-0">
                    <Balance />
                    <div className="col-lg-6 p-0">
                      <div className="d-flex button-section">
                        <ButtonSet />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 d-flex two-twelves">
                  <AddUser
                    handleChange={this.onChange}
                    first={first}
                    last={last}
                    phone={phone}
                    email={email}
                    load={load}
                    addUser={this.addUser}
                  />
                  <div className="col-12 col-md-7 col-lg-7 wallboard-table p-0 wallboard-chair">
                    <div className="card o-income card-body">
                      <div style={{ overflowX: 'auto' }}>
                        <table className="table copy-font disable-select">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>First Name</th>
                              <th>Last Name </th>
                              <th>Balance</th>
                              <th className="text-center">Top-up</th>
                              <th>Status</th>
                              <th>Action</th>
                              <th>Admin</th>
                            </tr>
                          </thead>
                          <UsersTable users={users} load={loading} />
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscribers: state.workgroups.subscribers,
  loading: state.workgroups.loading,
  users: state.users.users,
});

export default connect(
  mapStateToProps,
  { fetchStatusAndUsersAndAdmin, getUsersInWorkgroup },
)(Users);
