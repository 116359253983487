import React, {Component} from 'react';
import {API_URL} from "../../config/BaseUrl";
import axios from "axios";
import ChartsBar from "../../UIComponents/ChartsBar";

class CallsGraph extends Component {

    constructor(props) {
        super(props);

        this.state = {

            daily : 0,
            weekly: 0,
            monthly: 0,
            totalcalls: [],
            dailytitle: ["Date", "Calls"],
            phone: [],
            modalShow: true,
            cardid: "",
            usersPhone: ''

        }
    }


    componentDidMount() {

        this.graph();

    }

    graph = async () => {

        const url = `${API_URL}/callgraph/${sessionStorage.getItem('cardid')}`;

        try {

            const res = await axios.get(url,{

                headers: {

                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`

                }

            });

            if(res.data.status){

                let responsedata = res.data.result.daily;
                let responseweekly = res.data.result.weekly;
                let responsemonthly = res.data.result.monthly;

                var finalformat = [];
                var finalWeeklyFormat = [];
                var finalMonthlyFormat = [];

                finalformat.push(this.state.dailytitle)
                finalWeeklyFormat.push(this.state.dailytitle)
                finalMonthlyFormat.push(this.state.dailytitle)

                responsedata.map((user, index) => (
                    finalformat.push(
                        [
                            user.calldate,
                            parseInt(user.frequency)
                        ]
                    )
                ));

                responseweekly.map((user, index) => (
                    finalWeeklyFormat.push(
                        [
                            user.calldate,
                            parseInt(user.frequency)
                        ]
                    )
                ));

                responsemonthly.map((user, index) => (
                    finalMonthlyFormat.push(
                        [
                            user.calldate,
                            parseInt(user.frequency)
                        ]
                    )
                ));

                this.setState({

                    daily: finalformat,
                    weekly : finalWeeklyFormat,
                    monthly : finalMonthlyFormat

                })

            }

        } catch (e) {

        }


    };

    getModalStatus(i) {

        this.setState({
            modalShow:!i
        }, ()=>
        {
            if(this.state.modalShow===true) {
                this.setState({modalShow:!this.state.modalShow})
            }

        })

    }

    changeButtonName(i) {

        this.setState({
            modalShow:!i
        }, ()=>
        {
            if(this.state.modalShow===true) {
                this.setState({modalShow:!this.state.modalShow})
            }

        })

    }

   

    render() {
        return (
            <div className="d-none d-sm-block col-6">
                <div className="d-flex">
                    <h6 className="mr-auto"><strong>Call Frequency</strong></h6>
                    <ul  className="nav nav-pills" id="graph-nav" style={{marginTop:"-1.5%"}}>
                        <li className="nav-item mr-2">
                            <a onClick={this.changeButtonName} className="nav-link active" data-toggle="pill" href="#daily">Daily</a>
                        </li>
                        <li className="nav-item mr-2">
                            <a onClick={this.changeButtonName} className="nav-link" data-toggle="pill" href="#weekly">Weekly</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={this.changeButtonName} className="nav-link" data-toggle="pill" href="#monthly">Monthly</a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div  className="tab-pane active" id="daily" style={{width:"100%", height:"400px"}}>
                        <small>Showing daily statistics</small>
                        {this.state.daily < 2 ? null : <div id="daily-graph" className="chart">
                            <ChartsBar data={this.state.daily} width="100%" height="400px"/>
                        </div>}
                    </div>


                    <div className={!this.state.modalShow?"tab-pane active":" tab-pane active"} id="weekly" onClick={
                        ()=>
                        {
                            if(this.state.modalShow===true) {
                                this.setState({modalShow:!this.state.modalShow})
                            }

                        }
                    }>
                        <small>Showing statistics for 7 days</small>
                        {this.state.weekly < 2 ? null : <div id="weekly-graph" className="chart">
                            <ChartsBar data={this.state.weekly} width="100%" height="400px"/>
                        </div>}
                    </div>

                    <div className={!this.state.modalShow?"tab-pane active":" tab-pane active"} id="monthly">
                        <small>Showing statistics for 30 days</small>
                        {this.state.monthly < 2 ? null : <div id="monthly-graph" className="chart">
                            <ChartsBar data={this.state.monthly}/>
                        </div>}
                    </div>

                </div>
            </div>
        );
    }
}

export default CallsGraph;
