import React from 'react';
import DownloadSample from './DownloadSample';
import UploadPhoneNumbers from './UploadPhoneNumbers';

const UploadAndDownload = () => {
  return (
    <div className="col-lg-3 col-12 mb-3 p-0 pr-lg-4">
      <DownloadSample />
      <UploadPhoneNumbers />
    </div>
  );
};

export default UploadAndDownload;
