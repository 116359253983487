import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";
import {API_URL} from "../../config/BaseUrl";
import {SIGN_UP_URL} from "../../../services/apis/AuthUrl";

class Referral extends Component {

    constructor(props) {
        super(props);

        this.state = {

            firstName: '',
            lastName: '',
            email: '',
            address: '',
            gender: '',
            phone: '',
            plan: '',
            password: '',
            referral: '',
            twitter: '',
            instagram: '',
            plans: [],
            loading: false,
            redirect: false

        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    handleChange = (event) => {

        this.setState({
            ...this.state,
            gender: event.target.value

        });

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });


    onSubmit(e){

        e.preventDefault();

        this.setState({

            loading: true

        });

        if(this.state.firstName === ''){

            toast.info('Please enter a first name');
            this.setState({

                loading: false

            });

        }

        if(this.state.lastName === ''){

            toast.error('Please enter a first name');
            this.setState({

                loading: false

            });

        }

        if(this.state.address === ''){

            toast.error('Please enter a last name');
            this.setState({

                loading: false

            });

        }

        if(this.state.gender === ''){

            toast.error('Please select a gender');
            this.setState({

                loading: false

            });

        }

        if(this.state.email === ''){

            toast.error('Please enter an email');
            this.setState({

                loading: false

            });

        }

        if(this.state.phone === ''){

            toast.error('Please enter a phone number');
            this.setState({

                loading: false

            });

        }

        if(this.state.plan === ''){

            toast.error('Please select a plan');
            this.setState({

                loading: false

            });

        }

        if(!this.state.password){

            toast.error('Please enter your password');
            this.setState({

                loading: false

            });

        }

        const customer = {

            firstName : this.state.firstName,
            lastName : this.state.lastName,
            address : this.state.address,
            gender : this.state.gender,
            email : this.state.email,
            phoneNumber : this.state.phone,
            password: this.state.password,
            plan_id: this.state.plan,
            referral_code: this.state.referral

        };

        axios({

            method: 'post',
            url: `${SIGN_UP_URL}`,
            data: customer

        }).then(response=> {

            if(response.data.data.id){

                this.setState({

                    firstName : '',
                    lastName : '',
                    address : '',
                    gender : '',
                    email : '',
                    phoneNumber : '',
                    password: '',
                    plan_id: '',
                    referral: ''

                });

                if(navigator.userAgent.toLowerCase().indexOf("android") > -1){

                    window.location.href = 'https://play.google.com/store/apps/details?id=com.tizeti.wificall';

                }else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1){

                    window.location.href = 'https://itunes.apple.com/us/app/wificall-ng/id1458874906?ls=1&mt=8';

                } else {

                    this.props.history.push("/");

                }

            }

        }).catch(error => {
            if(error.response.data.message.errors[0].message === "email must be unique") {
                toast.info("This email has been registered already. Go back to log in.")
            }
        });

    }

    componentDidMount() {

        this.getPlans();

        const { token } = this.props.match.params;

        this.setState({

            referral: token

        });

        this.getUserWithToken();

    };

    async getUserWithToken(){

        const url = `${API_URL}/wificall/code/${this.props.match.params.token}`;

        const response = await axios.get(url);

        if(response.data[0]){

            this.setState({

                name: response.data[0].wificall_user.firstName + ' ' + response.data[0].wificall_user.lastName


            })

        }
    }

    getPlans(){

        axios({

            method: 'get',
            url: `${API_URL}/planlist`

        }).then(response => {

            this.setState({

                plans: response.data

            });


        })
    }

    render() {

        const {plans, name} = this.state;

        return (
            <div className="main-wrapper">
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex mt-5 ml-auto mr-auto">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="tizeti-logo">
                            <img src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png" alt="images"/>
                        </div>
                        <ToastContainer/>

                        <p className="mt-3 text-center" style={{fontSize:"13px"}}><strong>{name} has gifted you 20 MINUTES free talktime.Sign up below to claim this gift.</strong></p>

                        <div>
                            <form className="form-horizontal mt-3 login-form col-sm-12 col-lg-11 col-md-11 p-0" onSubmit={this.onSubmit}>
                                <div className=" col-lg-9 col-sm-12">
                                    <div  className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control mr-3"
                                            name="firstName"
                                            placeholder="First name"
                                            value={this.state.firstName}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.onChange}
                                            placeholder="Last name"
                                        />
                                    </div>

                                    <div  className="mb-3 d-flex">
                                        <h6 className="mr-3">Gender:</h6>
                                        <div className="d-flex">
                                            <input
                                                className="mt-1"
                                                type="radio"
                                                name = "gender"
                                                value="female"
                                                onChange={this.handleChange}
                                            />
                                            <span className="ml-1 mr-2">Female</span>
                                            <input
                                                className="mt-1"
                                                type="radio"
                                                name = "gender"
                                                value="male"
                                                onChange={this.handleChange}
                                            />
                                            <span className="ml-1">Male</span>
                                        </div>
                                    </div>

                                    <div  className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="address"
                                            value={this.state.address}
                                            onChange={this.onChange}
                                            placeholder="Address"
                                        />
                                    </div>

                                    <div  className="mb-3">
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.onChange}
                                            placeholder="Mobile number"
                                        />
                                    </div>

                                    <div  className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            placeholder="Email address"
                                        />
                                    </div>

                                    <div  className="mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={this.onChange}
                                            name="password"
                                        />
                                    </div>

                                    <div  className="mb-3">
                                        <select
                                            onChange={this.onChange}
                                            name="plan" className="form-control">
                                            <option>Select Plans</option>
                                            {plans.map(
                                            (plan) => {

                                            return <option value={plan.id}>{plan.name} @ {plan.amount}</option>
                                            }

                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={this.state.referral}
                                            value={this.state.referral}
                                            onChange={this.onChange}
                                            name="code"
                                        />
                                    </div>
                                    {/*<div  className="mb-3">*/}
                                        {/*<select*/}
                                            {/*name="about-us" className="form-control">*/}
                                            {/*<option>How did you hear about us? (Optional)</option>*/}
                                            {/*<option>Facebook</option>*/}
                                            {/*<option>Twitter</option>*/}
                                            {/*<option>Instagram</option>*/}
                                            {/*<option>Google Playstore</option>*/}
                                            {/*<option>IOS Appstore</option>*/}
                                            {/*<option>Friend/Family/Co-worker</option>*/}
                                            {/*<option>Google Search</option>*/}
                                            {/*<option>Online Blog</option>*/}
                                            {/*<option>Newspaper</option>*/}
                                            {/*<option>Organised Event</option>*/}
                                            {/*<option>Other</option>*/}
                                        {/*</select>*/}
                                    {/*</div>*/}

                                </div>

                                <div className="wrapper-button col-12 col-md-9 col-lg-9 p-0 mt-2">
                                    <button className="btn btn-green w-100" style={{ backgroundColor: '#35BEAA', color:"white" }}>SIGN UP</button>
                                </div>
                                {/*<div className="already-have">
                        <p className="text-center">Already have an account? <a href="#tab2">Login</a></p>
                    </div>*/}
                            </form>
                            <div className="below copy-font" id="signin" style={{marginTop:"-3%"}}>
                                <p className="copy-font text-center ml-5" style={{fontSize:"12px"}}>By clicking signup, you agree to our <Link to={"http://wificall.ng/terms.html"} style={{textDecoration:"none", color:"black"}}><b>Terms of service</b></Link> and <Link to={"http://wificall.ng/policy.html"} style={{textDecoration:"none", color:"black"}}><b>Privacy Policy</b></Link></p>
                                <p className="text-center" style={{fontSize:"11px"}}>Already have an account?
                                    <strong><Link to={"/login"} style={{textDecoration:"none", color:"black"}}> Sign In</Link></strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state, ownProps) => ({
//
//     user: ownProps.match.params.token
//
// });

export default Referral;