import React, {Component} from 'react';
import {getUserBalance} from "../../../services/state-management/actions/common/users/UsersActions";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";
import Helper from "../../util/Helper";
import PropTypes from 'prop-types';
import {connect} from "react-redux";

class Balance extends Component {

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        fetchUser: PropTypes.func.isRequired

    };

    checkPlan = () => {

        const {plan} = this.props.user;
        const {wallet} = this.props;

        if(plan){
            switch (plan.name) {
                case "Basic unlimited plan":
                    return <b>Unlimited</b>;

                default:
                    return <b>{typeof (wallet) == "string" ? Helper.formatCurrency(wallet) : null}</b>

            }

        } else return null

    };

    componentDidMount() {

        this.props.getUserBalance();
        this.props.fetchUser();

    }

    render() {
        return (
            <div>
                <h5 className="count mt-1">
                                            <span className="saving">
                                                {this.checkPlan()}
                                            </span>
                    <br />
                    <span className="baby-font">Balance</span></h5>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return{

        wallet: state.wallet.wallet,
        user: state.auth.auth

    }

};

export default connect(mapStateToProps, {getUserBalance, fetchUser})(Balance);