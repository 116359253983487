/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const Plans = ({
  planName,
  amount,
  paystack,
  border,
  background,
  text1,
  text2,
  text3,
  color,
}) => {
  return (
    <div className="col">
      <div
        className="card mx-5 my-3 pb-1"
        style={{
          border: `${border}`,
          backgroundColor: `${background}`,
        }}
      >
        <div className="card-body text-center p-0">
          <Link
            style={{ color: `${color}`, textDecorationLine: 'none' }}
            onClick={paystack}
            to="#"
          >
            <small className="mx-auto fontWeight500">{planName}</small>
            <div className="fontWeight900">&#8358; {amount}</div>
            <small className="mx-auto fontWeight500">
              <div>
                {text1}
                <br />
              </div>
              <div>
                {text2}
                <br />
              </div>
              <div>{text3}</div>
            </small>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Plans;
