/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import '../../styles/login.css';
import PropTypes from 'prop-types';
import { loginUser } from '../../services/state-management/actions/auth/AuthActions';
import DownloadPopUp from './DownloadPopUp';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (sessionStorage.getItem('token')) {
      history.push('/');
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const login = {
      email,
      password,
    };
    this.props.loginUser(login, this.props.history);
  };

  submitForm() {
    const { email, password } = this.state;
    return email.length > 0 && password.length > 0;
  }

  render() {
    const { email, password } = this.state;
    const { load } = this.props;
    const isEnabled = this.submitForm();

    return (
      <div className="main-wrapper">
        <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
          <div className="login col-sm-12 col-lg-12 col-md-12">
            <div className="tizeti-logo">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
              />
            </div>
  
            <h6 className="mt-3 text-center">
              <strong>
                Welcome Back! Login to manage your wificall account (s)
              </strong>
            </h6>

            <form
              onSubmit={this.onLogin}
              className="form-horizontal mt-3 login-form col-sm-12 col-lg-12 col-md-12"
            >
              <div className="form-group col-lg-9 col-sm-12">
                <div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                  />
                </div>
                <br />

                <div>
                  <input
                    id="myInput"
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    placeholder="Password"
                  />
                  {/* <span className="fa fa-fw fa-eye field-icon" onClick="myFunction()"></span> */}
                </div>
                <div id="signin">
                  <p className="copy-font mt-3">
                    Forgot your password?
                    <Link
                      to="/forgot-password"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <strong> Reset it</strong>
                    </Link>
                  </p>
                  {/* <p className="copy-font text-center">Dont have an account? <a href="/login" style={{textDecoration:"none", color:"black"}}><b>Sign up</b></a></p> */}
                </div>
              </div>
              <br />
              <br />

              {load ? (
                <div className="wrapper-button mb-3 col-lg-9 p-0">
                  <button
                    type="button"
                    className="btn btn-green w-100"
                    id="login"
                    style={{ backgroundColor: '#35BEAA', color: 'white' }}
                    disabled
                  >
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: '24px' }}
                    />
                  </button>
                </div>
              ) : (
                <div className="wrapper-button mb-3 col-lg-9 p-0">
                  <button
                    type="submit"
                    className="btn btn-green w-100"
                    style={{ backgroundColor: '#35BEAA', color: 'white' }}
                    disabled={!isEnabled}
                    data-toggle="modal"
                    data-target="#downloadAppModal"
                  >
                    LOGIN
                  </button>
                  <DownloadPopUp />
                </div>
              )}

              <p className="text-center" style={{ fontSize: '10px' }}>
                Don&apos;t have an account?
                <strong>
                  <Link
                    to="/sign-up"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    {' '}
                    Create Account
                  </Link>
                </strong>
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  load: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  loading: state.loadLogin.loadLogin,
  load: state.login.load,
});

export default connect(
  mapStateToProps,
  { loginUser },
)(withRouter(Login));
