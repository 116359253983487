/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import useFetch from './useFetch';
import { GET_USER_SIP_URL } from '../../../services/apis/AuthUrl';
import { DEV_LOGGED_IN_USER_URL } from '../../../services/apis/LoggedInUrl';

interface ParamTypes {
  email: string;
}
const useAuth = () => {
  const { email } = useParams<ParamTypes>();
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const { data, loading } = useFetch(
    `https://api.tizeti.com/api/v1/wificall/login/${email}`,
  );

  useEffect(() => {
    if (data && data.status === 'success') {
      sessionStorage.setItem('token', data._token);

      try {
        axios
          .get(`${GET_USER_SIP_URL}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          })
          .then((res) => {
            if (res.data.length > 1) {
              history.push({
                pathname: '/select-account',
                state: {
                  email,
                  password: '',
                  data: { email, password: '' },
                  workgroups: res.data,
                },
              });
              setFetching(true);
            } else {
              try {
                axios
                  .get(`${DEV_LOGGED_IN_USER_URL}`, {
                    headers: {
                      Authorization: `Bearer ${sessionStorage.getItem(
                        'token',
                      )}`,
                    },
                  })
                  .then((getLoggedInUser) => {
                    if (getLoggedInUser.data) {
                      sessionStorage.setItem(
                        'workgroup',
                        getLoggedInUser.data.workgroup,
                      );
                      sessionStorage.setItem(
                        'referral',
                        getLoggedInUser.data.referral_code,
                      );
                      sessionStorage.setItem('id', getLoggedInUser.data.id);

                      sessionStorage.setItem(
                        'username',
                        getLoggedInUser.data.user_sip.username,
                      );

                      sessionStorage.setItem(
                        'cardid',
                        getLoggedInUser.data.user_sip.cardid,
                      );
                      sessionStorage.setItem(
                        'email',
                        getLoggedInUser.data.email,
                      );
                      sessionStorage.setItem('userid', getLoggedInUser.data.id);
                      sessionStorage.setItem(
                        'firstname',
                        getLoggedInUser.data.firstName,
                      );
                      sessionStorage.setItem(
                        'lastname',
                        getLoggedInUser.data.lastName,
                      );

                      // plan if needed
                      sessionStorage.setItem(
                        'plan',
                        getLoggedInUser.data.plan.name,
                      );
                      sessionStorage.setItem(
                        'planId',
                        getLoggedInUser.data.plan_id,
                      );

                      setFetching(false);

                      history.push('/');
                    }
                  });
              } catch (e) {
                toast('Something went wrong');
              }
            }
          });
      } catch (e) {
        toast('Something went wrong');
      }
    }
  }, [data, email, history]);
  return { loading, fetching };
};
export default useAuth;
