import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types'
import EditConference from "./EditConference";
import { getConferences } from '../../../services/state-management/actions/common/conferences/ConferencesActions';

class ConferencesTable extends Component {

    constructor(props) {
        super(props);

        this.state = {

            one: {}

        }

    }

    componentDidMount() {

        this.props.getConferences();

    }

    static propTypes = {

        getConferences: PropTypes.func.isRequired,
        conferences: PropTypes.object.isRequired

    };

    componentWillReceiveProps(nextProps) {

        if(nextProps.conference.conference_number){

            this.props.getConferences();

        }

    }

    showConference = conference => {

        this.setState({

            one: conference

        })

    };

    render() {

        const { conferences } = this.props;
        const {one} = this.state;

        return (
            <div className="mr-4 mb-2 col-md-6 col-lg-7 p-0">
                <h6><strong>Conference</strong></h6>
                <div className="card o-income card-body">
                    <div style={{overflowX:"auto"}}>
                        <table className="table copy-font">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Conference</th>
                                <th scope="col" nowrap="no-wrap">Conference Number</th>
                                <th scope="col">Actions</th>
                            </tr>
                            </thead>
                            {/* <ConferenceTableData/> */}
                            {conferences.map(
                                (conference, i) => (
                                    <tbody>
                                    <tr className="table-row" data-href="#">
                                        <th scope="row">{i + 1}</th>
                                        <td>{conference.name}</td>
                                        <td>{conference.conference_number}</td>
                                        <td>
                                            <div className="d-flex">
                                                {/* <div className="subscriber-img mr-2">
                                                <img src="https://res.cloudinary.com/teewhy/image/upload/v1546952532/eye.fw.png" />
                                            </div> */}
                                                <div className="subscriber-img mr-2" data-target="#editcon" data-toggle="modal">
                                                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png" alt='' onClick={() => this.showConference(conference)} />
                                                </div>
                                                {/* <EditConference/> */}
                                                <div  className="modal fade in" id="editcon" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <div className="d-flex col-11" style={{justifyContent:"center"}}>
                                                                    <h4 className="modal-title">Edit Conference</h4>
                                                                </div>
                                                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                            </div>
                                                            <EditConference one={one} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    </tbody>
                                )
                            )}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    conferences: state.conferences.conferences,
    conference: state.conference.conference,

});

export default connect(
  mapStateToProps,
  { getConferences },
)(ConferencesTable);
