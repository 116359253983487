/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import '../../../styles/style.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QueueMembers from './QueueMembers';
import MenuBar from '../../../layouts/MenuBar';
import AddQueue from './AddQueue';
import ButtonSet from '../../../layouts/ButtonSet';
import Helper from '../../util/Helper';
import { getUserBalance } from '../../../services/state-management/actions/common/users/UsersActions';
import { fetchUser } from '../../../services/state-management/actions/auth/AuthActions';

class Queues extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getUserBalance();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchUser();
  }

  checkPlan = () => {
    const { user, wallet } = this.props;
    const { plan } = user;

    if (plan) {
      switch (plan.name) {
        case 'Basic unlimited plan':
          return 'Unlimited';

        default:
          return (
            <b>
              {typeof wallet === 'string'
                ? Helper.formatCurrency(wallet)
                : null}
            </b>
          );
      }
    }
    return null;
  };

  render() {
    return (
      <div className="body">
        <div className="container-fluid">
          <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
              alt="logo"
              width="12%"
              height="15%"
            />
          </div>
          <div className="row">
            <MenuBar />
          </div>
          <div className="container-fluid" id="resize-fluid">
            <div className="row">
              <div className="col-12 p-0 d-flex">
                <div className="wallet-balance col-md-2 ml-auto mt-2 mb-3 p-0 col-8">
                  <div className="icon pr-2">
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png"
                      alt="user balance"
                    />
                  </div>
                  <div>
                    <h5 className="count mt-1">
                      <span className="saving">{this.checkPlan()}</span>
                      <br />
                      <span className="baby-font">Wallet Balance</span>
                    </h5>
                  </div>
                </div>
                <div className="col-lg-2 mt-2 mb-3 p-0 col-4">
                  <div className="d-flex button-section">
                    <ButtonSet />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-12 d-flex p-0 m-0 flex-wrap">
                <AddQueue />
                <QueueMembers />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet.wallet,
  user: state.auth.auth,
});

Queues.propTypes = {
  getUserBalance: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  plan: PropTypes.object,
  user: PropTypes.object,
  wallet: PropTypes.string,
};

export default connect(
  mapStateToProps,
  { getUserBalance, fetchUser },
)(Queues);
