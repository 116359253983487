import {CREATE_ROBOCALL_URL, GET_ROBOCALLS_URL} from "../../../../apis/bulk-dialler/RobocallsUrl";
import axios from 'axios';
import {CREATE_ROBOCALL, GET_ROBOCALLS} from "../../types/bulk-dialler/RobocallTypes";
import {toast} from "react-toastify";

export const getRobocalls = () => async dispatch => {

    try {

        const response = await axios.get(`${GET_ROBOCALLS_URL}`, {

            headers: {

                'Authorization': `Bearer ${sessionStorage.getItem('token')}`

            }

        });

        if(response.data){

            dispatch({

                type: GET_ROBOCALLS,
                payload: response.data

            })

        }

    } catch (e) {


    }

};
//start creating robocall
export const createRobocall = (data) => async dispatch => {

    try {

         const response = await axios.post(`${CREATE_ROBOCALL_URL}`, data, {

             headers:{

                 'Authorization': `Bearer ${sessionStorage.getItem('token')}`

             }

         });

        if(response.data.id){

            toast.success("Creating Autocall!");
            dispatch({

                type: CREATE_ROBOCALL,
                payload: {

                    response: response.data,
                    load: false

                }

            });

        }

    } catch (e) {

    }

};