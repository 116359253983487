import { API_URL } from '../../components/config/BaseUrl';

export const SIGN_UP_URL = `${API_URL}/wificall/users1.0`;
export const LOGIN_URL = `${API_URL}/wificall/login`;
export const DEV_LOGIN_URL = `https://api.tizeti.com/developers/v1/wificall/signin`;
// export const DEV_LOGIN_URL =
//   'https://api.tizeti.com/developers/v1/wificall/web/signin';
export const DEV_GET_LOGGED_IN_USER_URL = `https://api.tizeti.com/developers/v1/wificall/activeUserDetails`;
export const FORGOT_PASSWORD_URL = `${API_URL}/wificall/forgotpassword`;
export const VERIFY_EMAIL_URL = `${API_URL}/wificall/verifyEmail`;
export const VERIFY_PHONE_URL = `${API_URL}/wificall/loginCode`;
export const VERIFY_LOGIN_CODE_URL = `${API_URL}/wificall/loginCode`;
export const TEMP_VERIFY_EMAIL_URL = `http://10.10.5.98:5000/api/v1/wificall/verifyEmail`;
export const GET_USER_BY_ID_URL_FOR_VERIFICATION = `${API_URL}/wificall/user`;
export const RESET_PASSWORD_URL = `${API_URL}/wificall/reset`;
export const GET_USER_SIP_URL = `${API_URL}/web/usersip`;
