import {GET_WALLBOARD_QUEUES} from "../../actions/types/WallboardTypes";

const initialState = {

    wallboardqueues: []

};

const WallbaordReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WALLBOARD_QUEUES:
            return {

                ...state,
                wallboardqueues: action.payload
            };

        default:
            return state;
    }

};

export default WallbaordReducer;