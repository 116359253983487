import React, {Component} from 'react';
import {getUserBalance} from "../../../services/state-management/actions/common/users/UsersActions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Helper from "../../util/Helper";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";

class Balance extends Component {

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        balance: PropTypes.string.isRequired,
        fetchUser: PropTypes.func.isRequired

    };

    componentDidMount() {

        this.props.getUserBalance();

    }

    checkPlan = () => {

        const {plan} = this.props.user;
        const {wallet} = this.props;

        if(plan){
            switch (plan.name) {
                case "Basic unlimited plan":
                    return <span className="saving">Unlimited</span>;

                default:
                    return <span className="saving">
                        <b>{typeof (wallet) == "string" ? Helper.formatCurrency(wallet) : null}</b>
                    </span>
            }

        } else return null

    };

    render() {

        return (
            <div className="wallet-balance col-md-6 p-0">
                <div className="icon pr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png" alt='' />
                </div>
                <div>
                    <h5 className="count mt-1">
                        {this.checkPlan()}
                    </h5>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return{

        wallet: state.wallet.wallet,
        user: state.auth.auth


    }

};

export default connect(mapStateToProps, {getUserBalance, fetchUser})(Balance);