import {GET_IVRS, LOADING_IVRS} from "../../../actions/types/IvrTypes";

const initialState = {

    ivrs: [],
    loading: false

};

const IvrReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_IVRS:
            return {

                ...state,
                ivrs: action.payload,
                loading: false

            };

        case LOADING_IVRS:
            return {

                ...state,
                loading: true

            };

        default:
            return state;
    }


};

export default IvrReducer;