import _ from 'lodash';
import { toast } from 'react-toastify';
import axios from 'axios';
import tizeti from '../../../../apis/tizeti';
import * as types from '../../types/UsersTypes';
import { API_URL } from '../../../../../components/config/BaseUrl';
import { LOGGED_IN_URL } from '../../../../apis/LoggedInUrl';
import * as url from '../../../../apis/user/UserUrl';

export const fetchStatusAndUsersAndAdmin = () => async (dispatch, getState) => {
  await dispatch(fetchUsers());
  const username = _.uniq(_.map(getState().users[0], 'username'));
  username.forEach((user) => dispatch(fetchStatus(user)));
  username.forEach((user) => dispatch(fetchAdmin(user)));
};

export const fetchStatus = (id) => async (dispatch) => {
  const statusUrl = `/web/usersip/${id}`;

  const response = await tizeti.get(statusUrl);

  if (response.data) {
    const newresponse = await tizeti.get(
      `/wificall/user/${response.data.wificall_user_id}`,
    );

    dispatch({
      type: 'FETCH_STATUS',
      payload: newresponse.data,
    });
  }
};

export const fetchAdmin = (id) => async (dispatch) => {
  const adminUrl = `/web/usersip/${id}`;

  const response = await tizeti.get(adminUrl);

  if (response.data) {
    const newresponse = await tizeti.get(
      `/wificall/user/${response.data.wificall_user_id}`,
    );

    dispatch({
      type: 'FETCH_ADMIN',
      payload: newresponse.data,
    });
  }
};

export const fetchUsers = () => async (dispatch) => {
  const response = await tizeti.get(`wificall/getWorkgroupList`);

  dispatch({
    type: types.FETCH_USERS,
    payload: response.data.result,
  });
};

export const getRecentCalls = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url.GET_RECENT_CALLS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      dispatch({
        type: types.GET_RECENT_CALLS,
        payload: response.data.result.slice(0, 7),
      });
    }
  } catch (e) {}
};

export const getUserBalance = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}/wificall/username/${sessionStorage.getItem('username')}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (response.data.status === true) {
      dispatch({
        type: types.GET_WALLET_BALANCE,
        payload: response.data.result[0].credit,
      });
    }
  } catch (e) {
    toast.info("Unable to get user's balance!");
  }
};

export const getReferralCode = () => async (dispatch) => {
  const response = await axios.get(LOGGED_IN_URL, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.status === 200) {
    dispatch({
      type: types.GET_REFERRAL_CODE,
      payload: response.data.user_sip.referral_code,
    });
  } else {
    toast.info('Something went wrong while fetching referral code');
  }
};

export const getAssignedPhoneNumber = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}/numbers/assign/${sessionStorage.getItem('cardid')}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (
      response.data.status === false ||
      response.data.message ===
        'CANNOT RETRIEVE PHONE NUMBER LIST or NO PHONE NUMBER ASSIGNED'
    ) {
      toast.info(response.data.message);
    } else {
      dispatch({
        type: 'GET_ASSIGNED_PHONE_NUMBER',
        payload: response.data.result[0].did,
      });
    }
  } catch (e) {}
};

export const loadingUsers = () => {
  return {
    type: types.LOADING_USERS,
  };
};

export const createUser = (newUser) => (dispatch) => {
  const createUserUrl = `/wificall/subscribe`;
  tizeti
    .post(createUserUrl, newUser)
    .then((response) => {
      if (response.data.status === 'success') {
        // window.location.reload();
        toast.success('User added successfully!');
        dispatch({
          type: types.CREATE_USER,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      if (error) {
        toast.info('Something went wrong. Please try again later!');
      }
    });
};

export const getTotalUsers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url.GET_TOTAL_USERS_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      dispatch({
        type: types.GET_TOTAL_USERS,
        payload: response.data.result.length,
      });
    }
  } catch (e) {}
};

export const getWorkgroupsUserBelongsTo = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${url.GET_WORKGROUPS_USER_BELONGS_TO_URL}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (response.data) {
      dispatch({
        type: types.GET_WORKGROUPS_USER_BELONGS_TO,
        payload: response.data,
      });
    }
  } catch (e) {}
};

// memoize starts
// export const fetchStatus = id =>  dispatch => _fetchStatus(id, dispatch);
//
// const _fetchStatus = _.memoize(async (id, dispatch) => {
//
//     const response = await tizeti.get(`/usersip/${id}`);
//
//     if(response.data){
//
//         const newresponse = await tizeti.get(`/wificall/user/${response.data.wificall_user_id}`);
//
//         dispatch({
//
//             type: 'FETCH_STATUS',
//             payload: newresponse.data
//
//         })
//
//
//     }
//
// });
// memoize ends

export const editUserDetails = (data, id) => async () => {
  try {
    const response = await axios.patch(`${url.EDIT_USER_DETAILS}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    if (response) {
      toast.success('Successfully Changed!');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  } catch (e) {
    toast.info('Something went wrong. Please try again later!');
  }
};
