import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserBalance } from '../../../services/state-management/actions/common/users/UsersActions';
import PropTypes from 'prop-types';
import Helper from '../../util/Helper';
import { fetchUser } from '../../../services/state-management/actions/auth/AuthActions';

class Balance extends Component {
  static propTypes = {
    getUserBalance: PropTypes.func.isRequired,
    wallet: PropTypes.string.isRequired,
    fetchUser: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getUserBalance();
    this.props.fetchUser();
  }

  checkPlan = () => {
    const { user } = this.props;
    const { plan } = user;
    // console.log(user);
    const { wallet } = this.props;
    // console.log(wallet)

    // console.log(user)

    if (plan) {
      switch (plan.name) {
        case 'Basic unlimited plan':
          return <b>Unlimited</b>;

        default:
          return (
            <b>
              {typeof wallet === 'string'
                ? Helper.formatCurrency(wallet)
                : null}
            </b>
          );
      }
    } else return null;
  };

  render() {
    return (
      <div className="wallet-balance col-md-6 ml-5 mr-auto">
        <div className="icon pr-2">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png"
            alt=""
          />
        </div>
        <div>
          <h5 className="count mt-1">
            <span className="saving">{this.checkPlan()}</span>
            <br />
            <span className="baby-font">Balance</span>
          </h5>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet.wallet,
  user: state.auth.auth,
});

export default connect(
  mapStateToProps,
  { getUserBalance, fetchUser },
)(Balance);
