import React from 'react';
import MenuBar from '../../../layouts/MenuBar';
import ButtonSet from '../../../layouts/ButtonSet';
import Balance from './Balance';
import ReferralCode from './ReferralCode';
import PaymentsTable from './PaymentsTable';
import NotificationNIN from '../../../layouts/NotificationNIN';

const Payments = () => {
  return (
    <div className="body">
      <div className="main-wrapper">
        <div className="container-fluid">
          <div className="row">
            <NotificationNIN />
          </div>
          <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
              alt="logo"
              width="12%"
              height="15%"
            />
          </div>
          <div className="row">
            <MenuBar />
          </div>
          <div className="container-fluid" id="resize-fluid">
            <div className="row d-flex">
              <div
                className="col-12 col-lg-12 mt-3 d-flex"
                style={{ justifyContent: 'flex-end' }}
              >
                <div className="d-flex col-lg-4" id="payment-page">
                  <small className="mr-3 mt-2 col-lg-3 d-none d-md-block d-lg-block">
                    Filter By:
                  </small>
                  <input
                    type="date"
                    className="form-control copy-font d-none d-md-block d-lg-blockx"
                  />
                </div>
                <ButtonSet />
              </div>
              <div className="col-12 col-md-12 col-lg-9 d-flex box-carrier mt-3 p-0">
                <div className="col-12 col-md-12 col-lg-11 d-flex carry-box p-0 justify-content-between flex-wrap">
                  <Balance />

                  <ReferralCode />
                </div>
              </div>
              <div className="col-12 col-lg-12 mt-3">
                <h6>
                  <strong>Latest Payments</strong>
                </h6>
              </div>
              <div className="card o-income card-body col-12 col-lg-12">
                <div style={{ overflowX: 'auto' }}>
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        {/* <th>Plan Name</th> */}
                        <th>Amount</th>
                        {/* <th>Payg By</th> */}
                        <th>Date</th>
                        {/* <th>Duration</th> */}
                      </tr>
                    </thead>

                    <PaymentsTable />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
