/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  enterWorkgroup,
  getUserWorkgroups,
} from '../../services/state-management/actions/workgroups/WorkgroupsActions';
import { logoutUser } from '../../services/state-management/actions/auth/AuthActions';

class SelectAccountPage extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.props.getUserWorkgroups();
  }

  static propTypes = {
    logoutUser: PropTypes.func.isRequired,
    workgroups: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    enterWorkgroup: PropTypes.func.isRequired,
  };

  enterWorkgroup = (work) => {
    this.props.enterWorkgroup(work, this.props.history);
  };

  logout() {
    const { logoutUser, history } = this.props;

    sessionStorage.setItem('workgroup', '');
    sessionStorage.removeItem('workgroup');
    sessionStorage.setItem('id', '');
    sessionStorage.removeItem('id');
    sessionStorage.setItem('username', '');
    sessionStorage.removeItem('username');
    sessionStorage.setItem('cardid', '');
    sessionStorage.removeItem('cardid');
    sessionStorage.setItem('token', '');
    sessionStorage.removeItem('token');
    sessionStorage.setItem('email', '');
    sessionStorage.removeItem('email');
    sessionStorage.setItem('userid', '');
    sessionStorage.removeItem('userid');
    sessionStorage.setItem('firstname', '');
    sessionStorage.removeItem('firstname');
    sessionStorage.setItem('lastname', '');
    sessionStorage.removeItem('lastname');
    sessionStorage.setItem('referral', '');
    sessionStorage.removeItem('referral');
    sessionStorage.clear();

    logoutUser(history);
  }

  render() {
    const { workgroups, loading } = this.props;

    return (
      <div className="main-wrapper">
        <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
          <div className="login col-sm-12 col-lg-12 col-md-12">
            <div className="tizeti-logo">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
              />
            </div>
            <h6 className="mt-3 text-center">
              <strong>Select account to login</strong>
            </h6>

            {loading ? (
              <small>Fetching accounts</small>
            ) : (
              <div id="buttons" className="text-center mt-3">
                {workgroups.map((workgroup) => (
                  <button
                    className="btn mb-3 w-75 shadow-sm p-2"
                    onClick={() => this.enterWorkgroup(workgroup)}
                  >
                    <h5>{workgroup.workgroup}</h5>
                  </button>
                ))}
              </div>
            )}

            <div className="col-12 text-center" onClick={this.logout}>
              <button className="btn btn-green text-white">Back</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workgroups: state.workgroups.workgroups,
  loading: state.workgroups.load,
});

export default connect(
  mapStateToProps,
  { logoutUser, getUserWorkgroups, enterWorkgroup },
)(withRouter(SelectAccountPage));
