/* eslint-disable import/no-named-as-default */
import { combineReducers } from 'redux';
import PlansReducer from './common/plans/PlansReducer';
import UsersReducer from './common/users/UsersReducer';
import AuthReducer from './auth/AuthReducer';
import IvrReducer from './common/ivr/IvrReducer';
import WallbaordReducer from './wallboard/WallboardReducer';
import FilesReducer from './files/FilesReducer';
import ErrorsReducer from './errors/ErrorsReducer';
import SubscribersReducer from './subscribers/SubscribersReducer';
import PaymentsReducer from './payments/PaymentsReducer';
import PhonesReducer from './phones/PhonesReducer';
import GroupsReducer from './bulk-dialler/GroupsReducer';
import CampaignsReducer from './bulk-dialler/CampaignsReducer';
import RobocallReducer from './bulk-dialler/RobocallReducer';
import ConferencesReducer from './common/conferences/ConferencesReducer';
import QueuesReducer from './queues/QueuesReducer';
import VerifyReducer from './verify/VerifyReducer';
import UserReducer from './common/user/UserReducer';
import WorkgroupReducer from './workgroups/WorkgroupReducer';

const RootReducer = combineReducers({
  plans: PlansReducer,
  status: UsersReducer,
  // users: UsersReducer,
  admin: UsersReducer,
  auth: AuthReducer,
  assignedNumber: UsersReducer,
  ivrs: IvrReducer,
  wallboardqueues: WallbaordReducer,
  // files
  files: FilesReducer,
  errors: ErrorsReducer,
  subscribers: SubscribersReducer,
  wallet: UsersReducer,
  referral: UsersReducer,
  users: UsersReducer,
  totalUsers: UsersReducer,
  recentCalls: UsersReducer,
  payments: PaymentsReducer,
  load: PaymentsReducer,
  numbers: PhonesReducer,
  loadPhones: PhonesReducer,
  availableNumbers: PhonesReducer,
  loadLogin: AuthReducer,
  loadRegister: AuthReducer,
  loadForgotPassword: AuthReducer,
  groups: GroupsReducer,
  campaigns: CampaignsReducer,
  loadCreating: CampaignsReducer,
  robocalls: RobocallReducer,
  loadCreateRobocall: RobocallReducer,
  conferences: ConferencesReducer,
  conference: ConferencesReducer,
  loadAddConference: ConferencesReducer,
  queues: QueuesReducer,
  loadAssigning: PhonesReducer,
  number: PhonesReducer,
  loadRegistrationCode: AuthReducer,
  verificationStatus: VerifyReducer,
  loggedInUser: UserReducer,
  uploadIdentityProgress: VerifyReducer,
  identityUpload: VerifyReducer,
  uploadUtilityProgresss: VerifyReducer,
  utilityUpload: VerifyReducer,
  login: AuthReducer,
  workgroups: WorkgroupReducer,
});

export default RootReducer;
