import React, {Component} from 'react';
import MenuBar from "../../layouts/MenuBar";
import queryString from 'query-string';
import {API_URL} from "../config/BaseUrl";
import axios from 'axios';
import toastr from "toastr";

class Processing extends Component {

    async componentDidMount () {

        const values = queryString.parse(this.props.location.search);

        try {

            if(values.planid){

                const url = `${API_URL}/verify?reference=${values.reference}&planid=${values.planid}`;

                const response = await axios.get(url);
                if(response.data){
                    if(response.data.status === true){

                        toastr.success("Transaction Successful");

                        this.props.history.push("/successful-payment");

                    } else {

                        toastr.info("something went wrong!");

                    }

                }

            } else {

                const url = `${API_URL}/verify?reference=${values.reference}`;

                const response = await axios.get(url);

                if(response.data){

                    if(response.data.status === true){

                        toastr.success("Transaction Successful");

                        this.props.history.push("/successful-payment");

                    } else {

                        toastr.info("something went wrong!");

                    }

                }


            }
        }  catch (e) {
           console.log(e);
        }

    }

    render() {

        return (
            // https://myaccount.wificall.ng/?trxref=W9ff8043d1f#/payment?planid=8&reference=W9ff8043d1f

            <div className="body">
                <div className="container-fluid">
                    <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png" alt="logo" width="12%" height="15%"/>
                    </div>
                    <div className="row">
                        <MenuBar />

                        <div className="col-3 processing-page shadow-lg">
                            <div className="text-center rotating-img">
                                <img src="https://res.cloudinary.com/teewhy/image/upload/v1548340787/WifiCall_Icon_1.png" alt="wificall-icon" className="mb-2"/>
                                <h6>Processing payment .......</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Processing;