/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserPayments } from '../../../services/state-management/actions/payments/PaymentsActions';

class PaymentsTable extends Component {
  static propTypes = {
    payments: PropTypes.object.isRequired,
    load: PropTypes.bool,
    getUserPayments: PropTypes.func,
  };

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { getUserPayments } = this.props;
    getUserPayments();
  }

  render() {
    const { payments, load } = this.props;

    if (load) {
      return (
        <img
          src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif"
          alt="loading"
        />
      );
    }

    const list = payments ? (
      payments.map((payment, i) => {
        return (
          <tr>
            <th>{i + 1}</th>
            {payment.credit > 0 ? (
              <td>
                <span className="amount">&#8358;</span>
                {Math.trunc(payment.credit).toLocaleString()}
              </td>
            ) : (
              <td>N/A</td>
            )}
            <td>{payment.date}</td>
          </tr>
        );
      })
    ) : (
      <p>No payments</p>
    );

    return <tbody>{list}</tbody>;
  }
}

const mapStateToProps = (state) => {
  return {
    payments: state.payments.payments,
    load: state.load.load,
  };
};

export default connect(
  mapStateToProps,
  { getUserPayments },
)(PaymentsTable);
