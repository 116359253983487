import React from 'react'

const EditPhoneGroup = () => {
    return (
        <div  className="modal fade in" id="phone" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex col-11" style={{justifyContent:"center"}}>
                            <h4 className="modal-title">Edit Contact</h4>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
    
                    <div className="modal-body">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <div className="col-12 d-flex mb-3">
                                    <div className="col-6">
                                        <label className="m-0 p-0">First Name</label>
                                        <input type="text"
                                               placeholder="First Name"
                                               className="form-control w-100"
                                               name="firstname"
                                        />
                                    </div>

                                    <div className="col-6">
                                        <label className="copy-font m-0 p-0">Last Name</label>
                                        <input type="text"
                                               placeholder="Last Name"
                                               className="form-control w-100"
                                               name="lastname"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 d-flex mb-3">
                                    <div className="col-6">
                                        <label className="m-0 p-0">Phone Number</label>
                                        <input type="tel"
                                               placeholder="Phone Number"
                                               className="form-control w-100"
                                               name="phone"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 text-center">
                                    <button className="btn second-button text-white">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    
    )
}

export default EditPhoneGroup;
