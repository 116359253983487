/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default function ContactUs() {
  return (
    <div
      className="modal fade"
      id="contactUs"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content Logo">
          <a
            className="close border-0 text-right mr-2"
            data-dismiss="modal"
            aria-label="Close"
            href="#"
          >
            <span aria-hidden="true">&times;</span>
          </a>
          <div className="col-12 text-center pb-3">
            <div className="modal-title mt-4">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
                height="37px"
              />
            </div>
            <div className="modal-body pt-0">
              <div className="py-2 mt-3">
                <div className="row mb-2">
                  <div className="col">
                    <p style={{ color: 'black' }}>
                      Please contact support at
{' '}
                      <a href="mailto:hello@wificall.ng">Wificall</a> for setup
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
