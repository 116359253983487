import React, {Component} from 'react';
import {API_URL} from "../../config/BaseUrl";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {

            existing: '',
            new: '',
            confirm: ''

        }

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    changePassword = e => {

        e.preventDefault();

        const change = {

            password: this.state.new,
            confirm_password: this.state.confirm

        };

        // const baseUrl = {
        //
        //     headers: {
        //
        //         'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        //
        // //     }
        //
        // };


        const url = `${API_URL}/wificall/change_password`;

        if(this.state.new === this.state.confirm){

            axios.post(url, change, {headers: {

                'Authorization': `Bearer ${sessionStorage.getItem('token')}`

            }}).then(response => {
                    if(response.data.status === "successful"){

                        toast.success("Password changed successfully!");

                    }

            })

        } else {

            toast.info("Passwords do not match");

        }

    };

    render() {
        return (
            <div  className="modal fade in" id="changePassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex col-11" style={{justifyContent:"center"}}>
                                <h4 className="modal-title">Change Password Here</h4>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal" onSubmit={this.changePassword}>
                                <div className="form-group">
                                    <div className="col-12 mb-3">
                                        <label className="m-0 p-0">Enter Existing Password</label>
                                        <input type="password"
                                               className="form-control"
                                               placeholder="Enter Existing Password"
                                               name="existing"
                                               value={this.state.existing}
                                               onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className="m-0 p-0">Enter New Password</label>
                                        <input type="password"
                                               className="form-control"
                                               placeholder="Enter New Password"
                                               name="new"
                                               value={this.state.new}
                                               onChange={this.onChange}
                                               minLength="5"
                                        />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className="m-0 p-0">Confirm Password</label>
                                        <input type="password"
                                               className="form-control"
                                               placeholder="Confirm New Password"
                                               name="confirm"
                                               value={this.state.confirm}
                                               onChange={this.onChange}
                                               minLength="5"
                                        />
                                    </div>

                                    <div className="col-12 text-center mb-3">
                                        <button className="btn second-button text-white">Change Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}

export default ChangePassword;