import {ASSIGN_NUMBER, GET_AVAILABLE_NUMBERS, GET_USER_ALLOCATED_NUMBERS} from "../../actions/phone/PhoneTypes";

const initialState = {

    numbers: [],
    loadPhones: false,
    availableNumbers: [],
    number: {},
    loadAssigningPhone: false

};

const PhonesReducer = (state=initialState, action) => {

    switch (action.type) {
        case GET_USER_ALLOCATED_NUMBERS:
            return {

                ...state,
                numbers: action.payload.response,
                loadPhones: action.payload.load

            };

        case GET_AVAILABLE_NUMBERS:
            return{

                ...state,
                availableNumbers: action.payload

            };

        case ASSIGN_NUMBER:
            return {

                ...state,
                loadAssigning: action.payload.load,
                number: action.payload.response

            };


        default:
            return state;

    }

};

export default PhonesReducer;