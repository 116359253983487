const PlanHelper = {
  removeAndReplace(param, remove, replace) {
    return param.toUpperCase().replace(remove, replace);
  },
  capitalizeFirstLetters(string) {
    const separateWord = string.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  },
};
export default PlanHelper;
