/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import { GET_ERRORS } from '../types/ErrorsTypes';
import { API_URL } from '../../../../components/config/BaseUrl';
import {
  DEV_LOGIN_URL,
  FORGOT_PASSWORD_URL,
  GET_USER_BY_ID_URL_FOR_VERIFICATION,
  GET_USER_SIP_URL,
  LOGIN_URL,
  RESET_PASSWORD_URL,
  SIGN_UP_URL,
  VERIFY_LOGIN_CODE_URL,
  VERIFY_PHONE_URL,
} from '../../../apis/AuthUrl';
import {
  FORGOT_PASSWORD,
  FETCH_USER,
  REGISTRATION_CODE_ERROR,
} from '../types/AuthTypes';
import { LOADING_STARTS, LOADING_STOPS } from '../types/loading/LoadingTypes';
import { DEV_LOGGED_IN_USER_URL } from '../../../apis/LoggedInUrl';

// export const registerUser = (user, history) => async dispatch =>  {
//     try{
//         const response = await axios.post(`${SIGN_UP_URL}`, user);
//         if(response.data._token){
//             history.push("/login");
//             toast.success("Account created. Please check your email to activate your account!")
//         }
//     } catch (error) {
//         console.log(error.response);
//         if(error){
//             if(error.response.data.message.errors[0].message === "email must be unique"){
//                 toast.info("This user already exists. Please proceed to login.");
//             }
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: error.response.data.message
//             })
//         }
//     }
// };

export const OTPcall = (data) => async (dispatch) => {
  dispatch({ type: LOADING_STARTS, payload: true });
  const phone = qs.stringify(data);
  try {
    const res = await axios.post(`${VERIFY_LOGIN_CODE_URL}`, phone);
    if (res.data.status === true) {
      dispatch({ type: LOADING_STOPS, payload: false });
      toast.success(
        'You will receive a call/SMS with your verification code within 1 minute',
      );
    }
  } catch (error) {
    if (error) {
      toast.info(error.response.data);
      toast.info(error.response.data.message.toUpperCase());
      dispatch({ type: LOADING_STOPS, payload: false });
    }
  }
};

export const registerUser = (user, history) => async (dispatch) => {
  dispatch({ type: LOADING_STARTS, payload: true });
  const phone = qs.stringify({ phoneNumber: user.phoneNumber });
  // const encryptedUserData = btoa(user.toString());
  // console.log(encryptedUserData);

  try {
    const response = await axios.post(`${SIGN_UP_URL}`, user);
    // const response = await axios.post(
    //   `${API_URL}/wificall/cryptouser`,
    //   encryptedUserData,
    // );

    if (response.data.status === 'success') {
      const res = await axios.post(`${VERIFY_LOGIN_CODE_URL}`, phone);
      if (res.data.status === true) {
        dispatch({ type: LOADING_STOPS, payload: false });
        history.push({
          pathname: '/verification',
          appState: {
            phoneNumber: user.phoneNumber,
            resultOTP: res.data.result,
            wificallUserID: response.data.data.wificall_user_id,
          },
        });
        toast.success(
          'You will receive a call/SMS with your verification code within 1 minute',
        );
      }
    }
  } catch (error) {
    if (error) {
      toast.info(error.response.data);
      toast.info(error.response.data.message.toUpperCase());
      dispatch({ type: LOADING_STOPS, payload: false });
      // dispatch({ type: GET_ERRORS, payload: error.response.data.message })
    }
  }
};

export const resetPassword = (data, history) => async (dispatch) => {
  try {
    const response = await axios.post(`${RESET_PASSWORD_URL}`, data);

    if (response.data.status === 'successful') {
      toast.success('Password changed successfully. Please log in');

      setTimeout(() => {
        history.push('/login');
      });
    }
  } catch (e) {
    if (e.response.data.message === 'link has expired, please reset') {
      toast.info('The link has expired. Please reset password again');
      // history.push("/forgot-password");
      setTimeout(() => {
        history.push('/forgot-password');
      }, 2000);
    }
  }

  // axios({
  //
  //     method: 'post',
  //     url: `${API_URL}/wificall/reset`,
  //     data: reset
  //
  // }).then(response => {
  //
  //     console.log(response.data);
  //
  //     if(response.data.status === "successful"){
  //
  //         const { history } = this.props;
  //
  //         toast.success("Successful. Please log in");
  //
  //         history.push("/login")
  //
  //     }
  //
  // });
};

export const oldloginUser = (user, history) => async (dispatch) => {
  try {
    const response = await axios.post(`${LOGIN_URL}`, user);

    if (response.data._token) {
      try {
        sessionStorage.setItem('token', response.data._token);
        axios
          .get(`${API_URL}/web/wificall/usersip`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          })
          .then(async (res) => {
            if (res.data.length > 1) {
              history.push({
                pathname: '/select-account',
                state: {
                  email: user.email,
                  password: user.password,
                  data: user,
                  workgroups: response.data,
                },
              });
            } else {
              const resp = await axios.get(`${API_URL}/wificall/loggedOnUser`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
              });

              if (resp.data) {
                sessionStorage.setItem(
                  'workgroup',
                  resp.data.user_sip.workgroup,
                );
                sessionStorage.setItem(
                  'referral',
                  resp.data.user_sip.referral_code,
                );
                sessionStorage.setItem('usersipid', resp.data.user_sip.id);
              }

              // sessionStorage.setItem('usersipid', response.data.userDetails.id);
              sessionStorage.setItem('id', response.data.sip.id);
              sessionStorage.setItem('username', response.data.sip.username);
              sessionStorage.setItem('cardid', response.data.sip.cardid);
              sessionStorage.setItem('token', response.data._token);
              sessionStorage.setItem('email', response.data.userDetails.email);
              sessionStorage.setItem('userid', response.data.userDetails.id);
              sessionStorage.setItem(
                'firstname',
                response.data.userDetails.firstName,
              );
              sessionStorage.setItem(
                'lastname',
                response.data.userDetails.lastName,
              );

              history.push('/');
            }
          });
      } catch (e) {}
    }
  } catch (err) {
    if (err.response) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.message,
      });
    }
  }
};

export const loginUser = (user, history) => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.post(`${DEV_LOGIN_URL}`, user);

    if (response.data) {
      sessionStorage.setItem('token', response.data);

      try {
        const res = await axios.get(`${GET_USER_SIP_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });

        if (res.data.length > 1) {
          history.push({
            pathname: '/select-account',
            state: {
              email: user.email,
              password: user.password,
              data: user,
              workgroups: response.data,
            },
          });

          dispatch({
            type: LOADING_STOPS,
            payload: false,
          });
        } else {
          try {
            const getLoggedInUser = await axios.get(
              `${DEV_LOGGED_IN_USER_URL}`,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
              },
            );

            if (getLoggedInUser.data) {
              sessionStorage.setItem(
                'workgroup',
                getLoggedInUser.data.user_sip.workgroup,
              );
              sessionStorage.setItem(
                'referral',
                getLoggedInUser.data.referral_code,
              );
              sessionStorage.setItem('id', getLoggedInUser.data.id);

              sessionStorage.setItem(
                'username',
                getLoggedInUser.data.user_sip.username,
              );

              sessionStorage.setItem(
                'cardid',
                getLoggedInUser.data.user_sip.cardid,
              );
              sessionStorage.setItem('email', getLoggedInUser.data.email);
              sessionStorage.setItem('userid', getLoggedInUser.data.id);
              sessionStorage.setItem(
                'firstname',
                getLoggedInUser.data.firstName,
              );
              sessionStorage.setItem('lastname', getLoggedInUser.data.lastName);

              // plan if needed
              sessionStorage.setItem('plan', getLoggedInUser.data.plan.name);
              sessionStorage.setItem('planId', getLoggedInUser.data.plan_id);

              dispatch({
                type: LOADING_STOPS,
                payload: false,
              });

              history.push('/');
            }
          } catch (e) {}
        }
      } catch (e) {}
    } else {
      toast.info('Something went wrong. Please try again!');

      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  } catch (e) {
    if (e.response) {
      toast.info(e.response.data.message.toUpperCase());

      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const fetchUser = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${DEV_LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: FETCH_USER,
          payload: response.data,
        });
      } else {
        toast.info('Unable to get users!');
      }
    } catch (e) {
      if (e.response) {
        toast.info('Unable to get users!');
      }
    }
  };
};

export const logoutUser = (history) => async () => {
  sessionStorage.setItem('workgroup', '');
  sessionStorage.removeItem('workgroup');
  sessionStorage.setItem('id', '');
  sessionStorage.removeItem('id');
  sessionStorage.setItem('username', '');
  sessionStorage.removeItem('username');
  sessionStorage.setItem('cardid', '');
  sessionStorage.removeItem('cardid');
  sessionStorage.setItem('token', '');
  sessionStorage.removeItem('token');
  sessionStorage.setItem('email', '');
  sessionStorage.removeItem('email');
  sessionStorage.setItem('userid', '');
  sessionStorage.removeItem('userid');
  sessionStorage.setItem('firstname', '');
  sessionStorage.removeItem('firstname');
  sessionStorage.setItem('lastname', '');
  sessionStorage.removeItem('lastname');
  sessionStorage.setItem('referral', '');
  sessionStorage.removeItem('referral');
  sessionStorage.clear();
  history.push('/login');
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${FORGOT_PASSWORD_URL}`, data);

    if (
      response.data.message ===
      'Password check your email for your password reset link'
    ) {
      toast.success(response.data.message);
      dispatch({
        type: FORGOT_PASSWORD,
        payload: {
          load: false,
          response: response.data.message,
        },
      });
    }
  } catch (e) {
    if (e.response.data.message === 'user not found') {
      toast.info('Your Email Is Not Registered. Please Create An Account.');
      dispatch({
        type: FORGOT_PASSWORD,
        payload: {
          load: false,
          response: e.response.data.message,
        },
      });
    }
  }
};

export const getUserForVerification = (data) => async (dispatch) => {
  try {
    const response = await axios(
      `${GET_USER_BY_ID_URL_FOR_VERIFICATION}/${data}`,
    );

    dispatch({
      type: FETCH_USER,
      payload: response.data,
    });
  } catch (e) {}
};

export const verifyEmail = (data, history) => async (dispatch) => {
  try {
    // const verify = {
    //
    //     id: data
    //
    // };
    //
    // const response = await axios.patch(`${VERIFY_EMAIL_URL}/${data}`, verify);
    //
    // if(response.data){
    //     history.push("/confirm-phone");
    //     toast.success("Email Verified");
    // }
  } catch (e) {}
};

export const verifyPhone = (data, history) => async (dispatch) => {
  try {
    const response = await axios.post(`${VERIFY_PHONE_URL}`, data);

    if (response.data.status === true) {
      history.push({
        pathname: '/enter-code',
        appState: {
          code: response.data.result,
        },
      });

      toast.success(
        'Calling your phone number to verify. Please pick up to receive the code',
      );
    }
  } catch (e) {}
};

export const enterCode = (data, history) => async (dispatch) => {
  const { code } = data;

  const usercode = {
    code,
  };

  try {
    const response = await axios.post(
      `${VERIFY_LOGIN_CODE_URL}/${sessionStorage.getItem(
        'verifytoken',
      )}?call_id=${data.token}`,
      usercode,
    );

    if (response.data && typeof response.data === 'object') {
      history.push('/login');
      toast.success('Verification successful! Please log in');
    } else {
      toast.info('Something went wrong!');
    }
  } catch (e) {
    if (
      e.response.data.status === 'failed' ||
      e.response.data.message === 'WRONG CODE'
    ) {
      toast.info(
        'You have entered the wrong code! Please click the button to get another code',
      );
      dispatch({
        type: REGISTRATION_CODE_ERROR,
        payload: false,
      });
    }
  }
};

export const verifyCode = (data, history) => async (dispatch) => {
  const { id, callID, code } = data;
  dispatch({ type: LOADING_STARTS, payload: true });
  try {
    const response = await axios.post(
      `${VERIFY_LOGIN_CODE_URL}/${id}?call_id=${callID}`,
      qs.stringify({ code }),
    );
    if (response.data && typeof response.data === 'object') {
      dispatch({ type: LOADING_STOPS, payload: false });
      history.push('/login');
      toast.success('Phone Number Verified Successfully');
    }
  } catch (e) {
    if (
      e.response.data.status === 'failed' ||
      e.response.data.message === 'wrong verification code'
    ) {
      toast.info('You have entered the wrong code! Please Request Again');
      dispatch({ type: LOADING_STOPS, payload: false });
    }
  }
};
