import React from 'react';
import { ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import NotificationNIN from '../../../layouts/NotificationNIN';
import MenuBar from '../../../layouts/MenuBar';
import Balance from '../calls/Balance';
import Loading from '../../UIComponents/Loading';
import useOtpHistory from './hook/useOtpHistory';

const OtpHistory = () => {
  const {
    onChange,
    onPaginate,
    onSubmit,
    search,
    otps,
    totalPages,
    loading,
  } = useOtpHistory();
  const onPaginateClick = (data: any) => {
    const selected = data.selected + 1;
    onPaginate(selected);
  };
  const onSubmitForm = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onSubmit();
  };
  const displayTable = () => {
    if (otps.length > 0) {
      return otps.map(
        (otp: {
          id: string;
          uniqueid: string;
          entry_time: string;
          last_attempt_time: string;
          callerid: string;
          account: string;
          called: string;
          duration: string;
          status: string;
        }) => (
          <tr>
            <th>{otp.id}</th>
            <th>{otp.uniqueid}</th>
            <th>{otp.entry_time}</th>
            <th>{otp.last_attempt_time}</th>
            <th>{otp.callerid}</th>
            <th>{otp.account}</th>
            <th>{otp.called}</th>
            <th>{otp.duration}</th>
            <th>{otp.status}</th>
          </tr>
        ),
      );
    }
    return 'there are no otps';
  };
  return (
    <div className="body">
      <div className="container-fluid">
        <div className="row">
          <NotificationNIN />
        </div>
        <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
            alt="logo"
            width="12%"
            height="15%"
          />
        </div>
        <div className="row">
          <MenuBar />
        </div>
        <div className="container-fluid" id="resize-fluid">
          <div className="row wallboard-table">
            <div className="col-lg-12 mt-4 d-none d-md-block d-lg-block">
              <div className="row p-0">
                <div className="col-12 d-flex flex-wrap-reverse">
                  <div className="p-0 mr-auto">
                    <form
                      className="col-lg-12 form-group d-flex mr-2"
                      onSubmit={onSubmitForm}
                    >
                      <small className="mt-2 p-0 mr-2">Show calls from:</small>
                      <div className="mr-3">
                        <input
                          type="date"
                          className="form-control copy-font w-100"
                          name="from"
                          value={search.from}
                          onChange={onChange}
                        />
                      </div>
                      <small className="mr-2 mt-2">To:</small>
                      <div className="mr-3">
                        <input
                          type="date"
                          className="form-control copy-font w-100"
                          name="to"
                          value={search.to}
                          onChange={onChange}
                          id="test"
                        />
                      </div>
                      <small className="mr-2 mt-2">Per Page:</small>
                      <div className="mr-3">
                        <input
                          type="input"
                          className="form-control copy-font w-100"
                          name="no_of_records_per_page"
                          value={search.no_of_records_per_page}
                          onChange={onChange}
                          id="test"
                        />
                      </div>

                      <button
                        className="btn btn-blue text-white"
                        style={{ backgroundColor: '#35BEAA' }}
                      >
                        Get Results
                      </button>
                    </form>
                  </div>
                  {/* <div className="p-0 mr-2"> */}
                  {/*    <CSVLink data={[]} filename="calls.csv"> */}
                  {/*      <button */}
                  {/*        className="btn btn-blue text-white" */}
                  {/*        style={{ backgroundColor: '#35BEAA' }} */}
                  {/*      > */}
                  {/*        Download Calls */}
                  {/*      </button> */}
                  {/*    </CSVLink> */}
                  {/* </div> */}
                  <Balance />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card o-income card-body ml-2">
                <div style={{ overflowX: 'auto' }}>
                  <table className="table copy-font">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Unique ID</th>
                        <th>Entry Time</th>
                        <th>Last Attempt Time</th>
                        <th>Caller ID </th>
                        <th>Account</th>
                        <th>Called</th>
                        <th>Duration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{loading ? <Loading /> : displayTable()}</tbody>
                  </table>
                </div>
              </div>
              <nav aria-label="Page navigation example" className="ml-2 mt-4">
                <ul className="font15 pagination">
                  <ReactPaginate
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    nextLabel="Next"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    marginPagesDisplayed={5}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    onPageChange={onPaginateClick}
                  />
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default OtpHistory;
