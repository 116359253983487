/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import { getUsersInWorkgroup } from '../../../services/state-management/actions/workgroups/WorkgroupsActions';
import {
  addQueue,
  getQueues,
} from '../../../services/state-management/actions/queues/QueuesActions';

class AddQueue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      users: [],
    };

    this.onChange = this.onChange.bind(this);
    this.add = this.add.bind(this);
  }

  componentDidMount() {
    this.props.getUsersInWorkgroup();
  }

  // eslint-disable-next-line consistent-return

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading === false) {
      this.setState({
        name: '',
        description: '',
        users: [],
      });
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  add = (e) => {
    e.preventDefault();

    const { name, description, users } = this.state;

    const queue = {
      queue_name: name,
      queue_description: description,
      card_id: sessionStorage.getItem('cardid'),
      workgroup_name: `${sessionStorage.getItem('workgroup')}`,
      queue_users: users,
    };

    this.props.addQueue(queue);
  };

  submitForm() {
    const { name, description, users } = this.state;
    return name.length > 0 && description.length > 0 && users;
  }

  render() {
    const { load, users, loadForm } = this.props;
    const { name, description } = this.state;
    const isEnabled = this.submitForm();

    return (
      <div className="col-12 col-lg-7 col-md-5 mr-3 mb-2 p-0 mr-auto">
        <div className="d-flex">
          <div>
            <h6>
              <strong>Add Queue</strong>
            </h6>
            <div className="card o-income card-body wallboard-table">
              <div className="d-flex">
                <form
                  className="form-horizontal col-lg-12 d-flex flex-wrap p-0"
                  onSubmit={this.add}
                >
                  <div className="form-group col-lg-6 col-12">
                    <div className="col-12 col-lg-12 d-flex mb-3">
                      <div>
                        <label className="copy-font">Queue Name</label>
                        <input
                          type="text"
                          className="form-control shadow-sm"
                          name="name"
                          onChange={this.onChange}
                          value={name}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-12 mb-3">
                      <label>Description</label>
                      <textarea
                        name="description"
                        onChange={this.onChange}
                        value={description}
                        rows="2"
                        cols="50"
                        className="form-control shadow-sm"
                        style={{ width: '90%' }}
                      />
                    </div>

                    {/* <div className="col-12 col-lg-12 mb-3">
                                        <label className="m-0 p-0">Select Recording</label>
                                        <select className="form-control p-3">
                                            <option></option>
                                        </select>
                                    </div> */}
                  </div>
                  <div className="form-group col-lg-6 col-12">
                    <label>Select User(s)</label>
                    {load ? (
                      <div>
                        <small>fetching users...</small>
                      </div>
                    ) : (
                      users.map((subscriber, i) => (
                        <div className="col-12 col-lg-12 d-flex mb-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              ref={subscriber.username}
                              id="inlineCheckbox1"
                              onChange={(e) => {
                                this.state.users.push({
                                  name: e.target.value,
                                  penalty: 0,
                                });
                              }}
                              key={subscriber.username}
                              name="users"
                              value={subscriber.username}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineCheckbox1"
                              key={i.id}
                            >
                              {subscriber.firstname} {subscriber.lastname}
                            </label>
                          </div>
                        </div>
                      ))
                    )}
                    <div className="text-center">
                      {loadForm ? (
                        <button
                          className="btn second-button text-white"
                          disabled={loadForm}
                        >
                          Adding...
                        </button>
                      ) : (
                        <button
                          className="btn second-button text-white"
                          disabled={!isEnabled}
                        >
                          Add Queue
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  load: state.workgroups.loadUsers,
  users: state.workgroups.users,
  loading: state.queues.load,
  loadForm: state.queues.loadForm,
});

export default connect(
  mapStateToProps,
  { getUsersInWorkgroup, getQueues, addQueue },
)(AddQueue);
