import React, {Component} from 'react';
import {connect} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import PropTypes from 'prop-types'
import axios from 'axios';
import {API_URL} from "../../../../../config/BaseUrl";
import {getGroups} from "../../../../../../services/state-management/actions/bulk-dialler/general/GroupsActions";

class UploadPhoneNumbers extends Component {

    constructor(props) {
        super(props);

        this.state = {

            type: null,
            preview: null,
            group: ''

        }

    }

    static propTypes = {

        getGroups: PropTypes.func.isRequired,
        groups: PropTypes.array.isRequired,
        newGroup: PropTypes.object.isRequired

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    componentDidMount() {

        this.props.getGroups();

    };

    componentWillReceiveProps(nextProps) {

        if(nextProps.newGroup.id){

            this.props.groups.unshift(nextProps.newGroup)

        }

    }

    fileSelected = e => {

        this.setState({

            selectedFile: e.target.files[0],
            preview: e.target.files[0].name,
            type: e.target.files[0].type

        });

    };

    uploadPhone = e => {

        e.preventDefault();

        const data = new FormData();
        data.append('form', this.state.selectedFile);
        data.append('group_id', this.state.group);
        axios.post(`${API_URL}/wificall/campaignNumbers/uploads`, data, {
            onUploadProgress: ProgressEvent => {
                this.setState({
                    loaded: (ProgressEvent.loaded/ ProgressEvent.total*100)
                })
            }
        }).then( res => {

            if(res.data.length){

                //toast notification on successful upload 👇
                this.props.getGroups();

                this.setState({

                    type: null,
                    preview: null,
                    group: '',
                    loading: false

                });

                toast.success("Phone numbers uploaded successfully!")

            }
        })

    };

    displayGroups(){

        const {groups} = this.props;

        if(groups){
            return(
                <select name="group" className="form-control mb-4" onChange={this.onChange}>
                    <option value="">Choose Group</option>
                    {groups.map(
                        (group) => (
                            <option value={group.id}>{group.name}</option>
                        )
                    )}
                </select>
            )

        } return null;

    }

    submitForm(){

        const {groups} = this.props;
        const {group, selectedFile} = this.state;

        return(

            groups.length > 0 &&
            group.length > 0 &&
            typeof (selectedFile) === "object"

        );

    }

    render() {

        const { type, preview } = this.state;
        const isEnabled = this.submitForm();

        return (
            <div className="mt-4">
                <h6><strong>Upload Phone Numbers</strong></h6>
                <form onSubmit={this.uploadPhone}>
                    <div className="shadow-sm p-4 text-center">

                        <div>
                            {this.displayGroups()}
                        </div>

                        <div>

                            <span className="fas fa-file-upload" onClick={() => this.fileInput.click()}/>

                            <input required={true} type="file" style={{ display: 'none'}} ref={fileInput => this.fileInput = fileInput} onChange={this.fileSelected} className="form-control mt-2 p-2" />
                        </div>

                        <div className="mb-1">
                            {type === "text/csv" ? <small>{preview}</small> : <small>Click to upload numbers<br />(only csv format supported)</small>}
                        </div>

                        <div>
                            <button className="btn second-button text-white" onClick={this.uploadPhone} disabled={!isEnabled}>Upload</button>
                        </div>
                    </div>
                </form>
                <ToastContainer/>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    groups: state.groups.groups,
    newGroup: state.groups.group

});

export default connect(mapStateToProps, {getGroups})(UploadPhoneNumbers);
