import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { forgotPassword } from '../../services/state-management/actions/auth/AuthActions';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  static propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    load: PropTypes.bool.isRequired,
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      load: true,
    });

    const { email } = this.state;

    const reset = {
      email,
    };

    this.props.forgotPassword(reset, this.props.history);
  };

  submitForm() {
    const { email } = this.state;

    return email.length > 0;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      load: nextProps.loading,
    });
  }

  render() {
    const { load, email } = this.state;
    const isEnabled = this.submitForm();

    return (
      <div className="main-wrapper">
        <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
          <div className="login col-sm-12 col-lg-12 col-md-12">
            <div className="tizeti-logo">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
              />
            </div>
              <form className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12" onSubmit={this.onSubmit}>
                  <div className="form-group col-lg-9 col-sm-12">
                      <h5 style={{color:"black"}}><b>Forgot Password?</b></h5>
                      <p className="copy-font">Please enter your email and you will get a reset link</p>
                      <div>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                          />
                      </div>
                      <br/>
                  </div>
                  <div className="wrapper-button mb-3">
                      { load ?
                        <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={true}><i className="fa fa-spinner fa-spin"/></button>
                        :   <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={!isEnabled}>Reset Password</button>
                      }
                  </div>
              </form>

              <div className="below copy-font" id="signin">
              <p className="copy-font text-center">
                <Link
                  to="/login"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <b>Home</b>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loadForgotPassword.loadForgotPassword,
});

export default connect(
  mapStateToProps,
  { forgotPassword },
)(withRouter(ForgotPassword));
