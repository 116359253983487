export const PAYSTACK_LIVE_KEY =
  'pk_live_942bca51a903f09437dd40c2b4b8f58e05c1dd96';
export const PAYSTACK_TEST_KEY =
  'pk_test_1a391be904839c190be0c8598881a6167d01cf4c';
export const RECURRENT_SUBSCRIBTION_PLAN_CODE = 'PLN_vrb60zo8bynlz4j';
export const BASIC_UNLIMITED_PLAN = 'PLN_eypazhbycnl2ce7';
export const TEST_BASIC_UNLIMTED_PLAN = 'PLN_hxeo03mxisj6tsi';
export const NEW_TEST_RECURRENT_SUBSCRIPTION_PLAN_CODE = 'PLN_o1xuk950sx4v3lq';
export const VERIFY_PAYSTACK_URL = 'https://api.paystack.co/transaction/verify';
export const PAYSTACK_SECRET_KEY =
  'sk_live_b871d3c245fcb3ecfd58c21d64329ced1a65a247';
export const PAYSTACK_TEST_SECRET_KEY =
  'sk_test_8eb687473cdb865e5c93444c1a7ebaf6c03a578d';
export const PAYSTACK_BASIC_CHARGE = 0.015;
export const PAYSTACK_EXTRA_CHARGE = 100;
export const EXPECTED_AMOUNT = (amountPaid, amountInc) => {
  amountPaid /= 100;
  return (
    (amountPaid + amountPaid * `${PAYSTACK_BASIC_CHARGE}` + amountInc) *
    PAYSTACK_EXTRA_CHARGE
  );
};
