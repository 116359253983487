/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../../../components/config/BaseUrl';

export const fetchPlans = () => async (dispatch) => {
  const response = await axios.get(`${API_URL}/planlist`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  dispatch({
    type: 'FETCH_PLANS',
    payload: response.data,
  });
};
