import React,  {Component} from 'react'
import { Chart } from "react-google-charts";

class ChartsBar extends Component {

    constructor (props){
        super(props);

        this.state = {

            drawnow: true,
            data :[],
            monthlyChart:[], 
            weeklyChart: [],
            chartData : {
                labels: ["January", "February", "March", "April", "May", "June", "July"],
                datasets: [{
                label: "My First dataset",
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: [0, 10, 5, 2, 20, 30, 45],
                }]
            }
        }
    }
    
    render(){
        return (
            <div className={"my-pretty-chart-container"}>
            {this.state.drawnow ? 
                   <Chart
                     chartType="Bar" 
                     width="100%" 
                     height="400px"  
                     data={this.props.data} />

                     : 
                     'No record found'}  
            </div>
        )
    }
}

export default ChartsBar