import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import MenuBar from '../../../layouts/MenuBar';
import { API_URL } from '../../config/BaseUrl';
import ButtonSet from '../../../layouts/ButtonSet';
import Balance from './Balance';

class BusinessPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      alias: '',
      phone: '',
      about: '',
      address: '',
      email: '',
      wallet: '',
      selectedFile: null,
      view: null,
      hasProfile: false,
    };

    this.businessProfile = this.businessProfile.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentDidMount() {
    this.checkIfUserOwnsAProfile();
  }

  async checkIfUserOwnsAProfile() {
    const url = `${API_URL}/wificall/businessProfile/my_profile`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.id) {
      this.setState({
        hasProfile: true,
        about: response.data.about,
        address: response.data.address,
        alias: response.data.alias,
        name: response.data.businessName,
        email: response.data.email,
        phone: response.data.phoneNumber,
      });
    } else {
      this.setState({
        hasProfile: false,
      });
    }
  }

  fileSelected = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
      preview: e.target.files[0].name,
      type: e.target.files[0].type,
      view: URL.createObjectURL(e.target.files[0]),
    });
  };

  businessProfile(e) {
    e.preventDefault();

    if (this.state.hasProfile === true) {
    } else {
      if (this.state.name === '') {
        toast.info('Enter a business name');
      } else if (this.state.email === '') {
        this.setState({
          loading: false,
        });
        toast.info('Enter your email');
      } else if (this.state.alias === '') {
        this.setState({
          loading: false,
        });
        toast.info('Enter your alias');
      } else if (this.state.phone === '') {
        this.setState({
          loading: false,
        });
        toast.info('Enter your phone');
      } else if (this.state.address === '') {
        this.setState({
          loading: false,
        });
        toast.info('Enter your address');
      } else if (this.state.about === '') {
        this.setState({
          loading: false,
        });
        toast.info('Enter your about');
      }

      const data = {
        businessName: this.state.name,
        email: this.state.email,
        alias: this.state.alias,
        phoneNumber: this.state.phone,
        address: this.state.address,
        about: this.state.about,
      };

      axios({
        method: 'post',
        data,
        url: `${API_URL}/wificall/businessProfile/add_profile`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }).then((response) => {
        if (response.data.id) {
          toast.success('Profile Added Successfully!');

          this.setState({
            name: '',
            alias: '',
            phone: '',
            about: '',
            address: '',
            email: '',
          });
        } else {
          toast.info('Something went wrong! Please try again!');
        }
      });
    }
  }

  uploadLogo = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    if (this.state.selectedFile) {
      axios({
        method: 'put',
        url: `${API_URL}/wificall/businessProfile/update/${sessionStorage.getItem(
          'userid',
        )}`,
      });
    }
  };

  render() {
    const { hasProfile } = this.state;

    return     <div className="body">
        <div className="container-fluid">
            <div className="col-lg-12 col-12 dashboard-image mt-4 d-none d-lg-block">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png" alt="logo" width="12%" height="15%"/>
            </div>
            <div className="row">
                <MenuBar />
            </div>

            <div className="container p-0">
                <div className="row wallboard-table">
                    <div className="col-lg-12 col-12 mt-4">
                        <div className="d-flex justify-content-end">
                            <div className="wallet-balance col-md-2">
                                <div className="icon pr-2">
                                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png" alt="images"/>
                                </div>

                                <Balance/>
                            </div>

                            <div className="col-lg-3">
                                <div className="d-flex button-section">
                                    <ButtonSet/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-9 col-12 p-0">
                        <h6><strong>My Business Page</strong></h6>
                        <div className="card x-panel d-flex flex-lg-row">
                            <form className="form-horizontal col-10 pr-0" onSubmit={this.businessProfile}>
                                <div className="form-group">
                                    <div className="col-12 d-flex mb-5 p-0">
                                        <div className="col-6">
                                            <div className="col-12 p-0 mb-3">
                                                <label className="copy-font p-0">Business Name</label>
                                                <input type="text"
                                                       className="form-control"
                                                       name="name"
                                                       value={this.state.name}
                                                       onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="col-12 p-0 mb-3">
                                                <label className="copy-font p-0">Wificall Public URL</label>
                                                <div className="d-flex">
                                                    <small className="mt-2 mr-1">wificall.ng/company/ </small><input type="text" className="form-control" readOnly/>
                                                </div>
                                            </div>
                                            {/*<div className="col-12 p-0 mb-3">*/}
                                            {/*<label className="copy-font p-0">Alias</label>*/}
                                            {/*<input type="text"*/}
                                            {/*className="form-control"*/}
                                            {/*placeholder="alias"*/}
                                            {/*name="aka"*/}
                                            {/*value={this.state.aka}*/}
                                            {/*onChange={this.onChange}*/}
                                            {/*/>*/}
                                            {/*</div>*/}

                                            <div className="col-12 p-0 mb-3">
                                                <label className="copy-font p-0">Website</label>
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder="Website"
                                                       name="alias"
                                                       value={this.state.alias}
                                                       onChange={this.onChange}
                                                />
                                            </div>
                                            {/*<div className="col-12 p-0">*/}
                                            {/*<label className="copy-font p-0">Phone</label>*/}
                                            {/*<input type="tel"*/}
                                            {/*className="form-control"*/}
                                            {/*placeholder="09099988812"*/}
                                            {/*name="phone"*/}
                                            {/*value={this.state.phone}*/}
                                            {/*onChange={this.onChange}*/}
                                            {/*/>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-12 p-0 mb-3">
                                                <label className="copy-font p-0">Website</label>
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder="Begin with http:// or https:// or www."
                                                       name="aka"
                                                />
                                            </div>*/}

                                            <div className="col-12 p-0 mb-3">
                                                <label className="copy-font p-0">Phone</label>
                                                <input type="tel"
                                                       className="form-control"
                                                  /*placeholder="LinkedIn URL"
                                                  name="aka"*/
                                                       placeholder="09099988812"
                                                       name="phone"
                                                       value={this.state.phone}
                                                       onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-4 pr-0">
                                            <div className="col-12 p-0 mb-3">
                                                <label className="copy-font p-0">About</label>
                                                <textarea rows="8"
                                                          cols="100"
                                                          name="about"
                                                          className="form-control"
                                                          value={this.state.about}
                                                          onChange={this.onChange}
                                                ></textarea>
                                            </div>

                                            <div className="col-12 p-0">
                                                <label className="copy-font p-0">Address</label>
                                                <textarea rows="5"
                                                          cols="50"
                                                          name="address"
                                                          className="form-control"
                                                          value={this.state.address}
                                                          onChange={this.onChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/*{this.submitButton()}*/}
                                    {hasProfile ? <div className="text-center">
                                        <button className="btn second-button text-white">Edit Business Profile</button>
                                    </div> : <div className="text-center">
                                        <button className="btn second-button text-white">Add Business Profile</button>
                                    </div>}
                                </div>
                            </form>
                            <form className="form-horizontal col-lg-2 col-12 p-0" onSubmit={this.uploadLogo}>
                                <div className="form-group">
                                    <div className="col-12 p-0">
                                        <div className="col-12 p-0 text-center">
                                            <label className="copy-font p-0">Logo</label>
                                            <div className="text-center" style={{cursor:"pointer"}}>
                                                {/*{this.state.selectedFile ? <img src={this.state.view} alt="" className="w-100"/> : null}*/}
                                                {this.state.selectedFile ? <img className="h-50 mh-50 w-100 mw-80 rounded-circle border border-info" onClick={() => this.fileInput.click()} src={this.state.view} alt=''/>: <img src="https://res.cloudinary.com/teewhy/image/upload/v1551091521/ImageUpload.svg"
                                                                                                                                                                                                                                 alt='' onClick={() => this.fileInput.click()}
                                                />}
                                                <input required={true}
                                                       type="file"
                                                       accept='image/*'
                                                       style={{ display: 'none'}}
                                                       ref={fileInput => this.fileInput = fileInput}
                                                       onChange={this.fileSelected}
                                                       className="form-control mt-2 p-2" />
                                            </div>
                                            <small>Select image to upload</small>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn second-button text-white">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  }
}

export default BusinessPage;
