/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../../config/BaseUrl';
import 'react-toastify/dist/ReactToastify.min.css';
import { LOGGED_IN_USER_URL } from '../../../services/apis/user/UserUrl';

class Topup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      id: '',
      loading: false,
      cardid: '',
      username: '',
    };
  }

  componentDidMount() {
    this.getUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.user.id,
      cardid: nextProps.user.id,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getUser() {
    axios({
      method: 'get',
      url: LOGGED_IN_USER_URL,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.data.data) {
        const { username } = response.data.data.user_sip;
        this.setState({ username });
      }
    });
  }

  topup = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const url = `${API_URL}/top/${sessionStorage.getItem('username')}`;
    const data = {
      amount: this.state.amount,
      cardid: this.state.cardid,
    };
    axios({
      method: 'post',
      url,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          toast.info('Topup successful!');
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error) {
          toast.info('Something went wrong! Please try again!');
          this.setState({ loading: false });
        }
      });
  }

  render() {
    const { loading } = this.state;
    const { user } = this.props;

    return (
      <div
        className="modal fade in"
        id="topup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="d-flex col-11"
                style={{ justifyContent: 'center' }}
              >
                <h4 className="modal-title">Subscriber Details</h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>

            <form onSubmit={this.topup}>
              <div className="modal-body">
                <div className="col-12 d-flex">
                  <div className="col-6">
                    <p>First Name</p>
                    <p>Last Name</p>
                    <p>Phone Number</p>
                    <p>Email Address</p>
                    <br />
                    <br />
                    <p>Status</p>
                    <p>Add Amount</p>
                  </div>

                  <div className="col-6">
                    {user.firstname ? (
                      <p>
                        <b>{user.firstname}</b>
                      </p>
                    ) : (
                      <p>
                        <b>N/A</b>
                      </p>
                    )}
                    {user.lastname ? (
                      <p>
                        <b>{user.lastname}</b>
                      </p>
                    ) : (
                      <p>
                        <b>N/A</b>
                      </p>
                    )}
                    {user.firstname ? (
                      <p>
                        <b>{user.username}</b>
                      </p>
                    ) : (
                      <p>
                        <b>N/A</b>
                      </p>
                    )}
                    {user.email ? (
                      <p>
                        <b>{user.email}</b>
                      </p>
                    ) : (
                      <p>
                        <b>N/A</b>
                      </p>
                    )}
                    <br />
                    <br />
                    {user.status !== 1 ? (
                      <p>
                        <b>Enabled</b>
                      </p>
                    ) : (
                      <p>
                        <b>Not Enabled</b>
                      </p>
                    )}
                    <p>
                      <input
                        type="number"
                        className="form-control"
                        name="amount"
                        onChange={this.onChange}
                        value={this.state.amount}
                        placeholder="Enter Amount"
                      />
                    </p>
                  </div>
                </div>

                {loading ? (
                  <div className="text-center">
                    <button className="btn btn-greenn">
                      <i className="fa fa-circle-o-notch fa-spin" />
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    <button className="btn btn-greenn">Top Up</button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
       
      </div>
    );
  }
}

export default Topup;
