/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import ViewSubscriber from './ViewSubscriber';
import Topup from './TopUp';
import tizeti from '../../../services/apis/tizeti';

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: {},
      status: '',
    };
  }

  getUser = (user) => {
    this.setState({
      customerId: user,
    });
  };

  makeUser(user) {
    const usernameurl = `/wificall/web/usersip/${user.username}`;
    if (user.userStatus === 'user') {
      tizeti.get(usernameurl).then((res) => {
        if (res.data.wificall_user_id) {
          const data = {
            status: 'lead',
          };
          tizeti
            .put(`/wificall/status/${res.data.wificall_user_id}`, data)
            .then((res) => {
              if (res.data[1]) {
                toast.success('Status has been changed!');
              }
            });
        }
      });
    } else if (user.userStatus === 'lead') {
      tizeti.get(usernameurl).then((res) => {
        if (res.data.wificall_user_id) {
          const data = {
            status: 'user',
          };
          tizeti
            .put(`/wificall/status/${res.data.wificall_user_id}`, data)
            .then((res) => {
              if (res.data[1]) {
                toast.success('Status has been changed!');
              }
            });
        }
      });
    } else return null;
  }

  makeAdmin(user) {
    const usernameurl = `/web/usersip/${user.username}`;
    tizeti.get(usernameurl).then((res) => {
      if (res.data.wificall_user_id) {
        const data = {
          admin_status: true,
        };
        tizeti
          .put(`/wificall/admin/${res.data.wificall_user_id}`, data)
          .then((res) => {
            if (res.data[1]) {
              toast.success('User has been made an admin!');
            }
          });
      }
    });
  }

  render() {
    const { load, users } = this.props;
    if (load) {
      return (
        <tr>
          <td colSpan="8">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif"
              alt="loading"
            />
          </td>
        </tr>
      );
    }

    const userList = users.length ? (
      users.map((user, i) => {
        return (
          <tr>
            <th>{i + 1}</th>
            {user.firstname ? <td>{user.firstname}</td> : <td>N/A</td>}
            {user.lastname ? <td>{user.lastname}</td> : <td>N/A</td>}
            <td nowrap="no-wrap">
              <span className="amount">&#8358;</span>{' '}
              {Number(user.credit).toLocaleString()}
              {/* <button className="btn-green ml-2 topup p-1" onClick={() => this.getUser(user)} data-target="#topup" data-toggle="modal">Top-up</button> */}
              {/* <Topup user={this.state.customerId}/> */}
            </td>
            <td nowrap="no-wrap">
              <button
                type="submit"
                className="btn-green ml-2 topup p-1 text-white"
                onClick={() => this.getUser(user)}
                data-target="#topup"
                data-toggle="modal"
              >
                Top-up
              </button>
              <Topup user={this.state.customerId} />
            </td>
            {/* onChange={() => {this.onChange(user); this.getValue()}} */}
            {/* value={this.state.text} onChange="this.props.onChange(); this.handleChange();" */}
            {/* <Enable userid={user.username}  email={user.email} user={user}/> */}
            <td>
              <select
                className="p-0"
                name="status"
                onChange={() => this.makeUser(user)}
              >
                <option value="">{user.userStatus}</option>
                {user.userStatus === 'lead' ? (
                  <option value="enable">Enable</option>
                ) : (
                  <option value="disable">Disable</option>
                )}
              </select>
            </td>
            <td>
              <div className="d-flex">
                <div
                  onClick={() => this.getUser(user)}
                  className="subscriber-img ml-2"
                  data-target="#viewsubscriber"
                  data-toggle="modal"
                >
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1546952532/eye.fw.png"
                    alt="view"
                    data-toggle="tooltip"
                    title="View"
                  />
                </div>
                <ViewSubscriber user={this.state.customerId} />
                {/* <div
                  onClick={() => this.getUser(user)}
                  className="subscriber-img mr-2"
                  data-target="#editDetails"
                  data-toggle="modal"
                >
                  <img
                    src={edit}
                    alt="edit"
                    data-toggle="tooltip"
                    title="Edit"
                  />
                </div>
                <EditDetails user={this.state.customerId} /> */}
                {/* <div className="subscriber-img mr-2">
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png" />
                  </div> */}
                {/* <div className="subscriber-img mr-2" data-target="#confirmdelete" data-toggle="modal"> */}
                {/* <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png" alt="images" /> */}
                {/* <ConfirmDelete/> */}
                {/* </div> */}
              </div>
            </td>
            <td className="text-center" id="label-switch">
              {/* {user.admin_status} */}
              {user.admin_status === false ? (
                <label className="switch">
                  <input type="checkbox" />
                  <span
                    className="slider round"
                    onClick={() => this.makeAdmin(user)}
                  />
                  :
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
                    alt=""
                    className="w-25 ml-3"
                  />
                </label>
              ) : (
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1550751069/add-imgs.png"
                  alt=""
                  className="w-25"
                />
              )}
            </td>
            {/* <MakeAdmin userid={user.username} email={user.email} user={user}/> */}
            
          </tr>
        );
      })
    ) : (
      <p>No users</p>
    );

    return <tbody>{userList}</tbody>;
  }
}

export default UsersTable;
