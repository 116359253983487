import axios from 'axios';
import { toast } from 'react-toastify';
import { LOADING_STARTS, LOADING_STOPS } from '../types/loading/LoadingTypes';
import {
  GET_USER_WORKGROUP_URL,
  GET_USERS_IN_WORKGROUP_URL,
} from '../../../apis/workgroups/WorkgroupsUrl';
import {
  GET_USERS_IN_WORKGROUP,
  GET_WORKGROUPS,
  START_GETTING_USERS_IN_WORKGROUP,
  STOP_GETTING_USERS_IN_WORKGROUP,
} from '../types/workgroup/WorkgroupTypes';
import { DEV_LOGGED_IN_USER_URL } from '../../../apis/LoggedInUrl';

export const getUserWorkgroups = () => async (dispatch) => {
  dispatch({
    type: LOADING_STARTS,
    payload: true,
  });

  try {
    const response = await axios.get(`${GET_USER_WORKGROUP_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      dispatch({
        type: GET_WORKGROUPS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (e) {
      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });
    }
  }
};

export const enterWorkgroup = (data, history) => async (dispatch) => {
  try {
    const response = await axios.get(`${DEV_LOGGED_IN_USER_URL}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data) {
      sessionStorage.setItem('workgroup', data.workgroup);
      sessionStorage.setItem('referral', data.referral_code);
      sessionStorage.setItem('id', data.id);
      sessionStorage.setItem('username', data.username);
      sessionStorage.setItem('cardid', data.cardid);
      sessionStorage.setItem('email', response.data.email);
      sessionStorage.setItem('userid', response.data.id);
      sessionStorage.setItem('firstname', response.data.firstName);
      sessionStorage.setItem('lastname', response.data.lastName);

      // plan if needed
      sessionStorage.setItem('plan', response.data.plan.name);
      sessionStorage.setItem('planId', response.data.plan_id);

      dispatch({
        type: LOADING_STOPS,
        payload: false,
      });

      history.push('/');
    } else {
      sessionStorage.setItem('token', '');
      sessionStorage.removeItem('token');
      sessionStorage.clear();
      history.push('/login');
    }
  } catch (e) {}
};

export const getUsersInWorkgroup = () => {
  return async (dispatch) => {
    dispatch({
      type: START_GETTING_USERS_IN_WORKGROUP,
    });

    try {
      const response = await axios.get(`${GET_USERS_IN_WORKGROUP_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === true) {
        dispatch({
          type: GET_USERS_IN_WORKGROUP,
          payload: response.data.result,
        });
      } else {
        toast.info('Unable to get users in this workgroup');

        dispatch({
          type: STOP_GETTING_USERS_IN_WORKGROUP,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to get users in this workgroup');

        dispatch({
          type: STOP_GETTING_USERS_IN_WORKGROUP,
          payload: false,
        });
      }
    }
  };
};
