import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
/* import App from './App'; */
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { ToastContainer } from 'react-toastify';
import RootReducer from './services/state-management/reducers/RootReducer';
import Routes from './routes/routes';
import * as serviceWorker from './serviceWorker';

const store = createStore(RootReducer, compose(applyMiddleware(thunk)));
// console.log(store.getState());
/* ReactDOM.render(<App />, document.getElemehntById('root')); */
ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Routes />
    </HashRouter>
    <ToastContainer />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
