import React from 'react';

const IvrTable = ({ ivrs }) => {
  const IvrList = ivrs.length ? (
    ivrs.map(
      (iv, i) => (
        <tr className="table-row" data-href="#">
          <th scope="row">{i + 1}</th>
          <td>{iv.name}</td>
          <td>{iv.description}</td>
          {/*<td className="text-center" onClick={() => this.showConfigures(iv)}><img src="https://res.cloudinary.com/teewhy/image/upload/v1548183654/setting.fw.png" alt="config-icon" style={{width:"35%"}}/></td>*/}
          <td>
            <div className="d-flex">
              <div className="mr-2" data-target="#editqueue" data-toggle="modal">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png"
                     alt=''/>
              </div>
              <div className="mr-2 text-center">
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png"
                     alt=''/>
              </div>
            </div>
          </td>
        </tr>
      )
    )
  ) : (
    <p>You have no ivrs</p>
  );

  return (
    <div className="card o-income card-body">
      <div style={{overflowX: "auto"}}>
        <table className="table copy-font">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">IVR Name</th>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
          {IvrList ? IvrList : <img src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif" alt="images"/>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IvrTable;
