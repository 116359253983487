import React from 'react';
import PropTypes from 'prop-types';

const App = ({ children }) => {
  return <div>{children}</div>;
};

App.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.shape,
};
export default App;
