import React from 'react';
import googlePlay from '../images/google-play.png';
import appStore from '../images/app-store.png';

const DownloadAppNotification = () => {
  return (
    <div className="download-dashboard my-2">
      <div className="copy-font text-center">
        <strong>Download WifiCall App</strong>
        <span className="px-1">
          <a
            href="https://play.google.com/store/apps/details?id=com.tizeti.wificall"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={googlePlay} alt="images" width="11%" />
          </a>
        </span>
        <span className="px-1">
          <a
            href="https://itunes.apple.com/us/app/wificall-ng/id1458874906?ls=1&mt=8"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={appStore} alt="images" width="11%" />
          </a>
        </span>
      </div>
    </div>
  );
};

export default DownloadAppNotification;
