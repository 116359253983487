import React, {Component} from 'react';
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {uploadUtilityVerification} from "../../../services/state-management/actions/common/user/verification/VerificationActions";
import PropTypes from "prop-types";

class UploadUtilityDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            files: null,
            load: false,
            type: null,
            preview: '',
            view: null,
            loaded: 0,
            utility: ''

        };

    }

    static propTypes = {

        user: PropTypes.object.isRequired,

    };

    submitFile(){

        const {files, utility} = this.state;

        return (files !== null && utility.length > 0)

    }

    uploadUtility = () => {

        this.setState({

            load: true

        });

        const{files, type, utility} = this.state;

        if(type === "audio/wav"){

            toast.info("Please a non-audio file");
            this.setState({

                load: false

            })

        } else {

            const data = new FormData();

            data.append("card_id", sessionStorage.getItem('cardid'));
            data.append("workgroup_name", sessionStorage.getItem('workgroup'));
            data.append("file", files);
            data.append("utility_bill_type", utility);

            this.props.uploadUtilityVerification(data);

        }

    };

    fileSelected = e => this.setState({

        files: e.target.files[0],
        type: e.target.files[0].type,
        name: e.target.files[0].name,
        view: URL.createObjectURL(e.target.files[0])

    });

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.progress){

            this.setState({

                loaded: nextProps.progress

            })
        }

        if(nextProps.file === "uploaded utility"){

            this.setState({

                load: false,
                type: null,
                name: null,
                view: null,
                loaded: 0,
                utility: ''

            })

        }

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    render() {

        const isEnabled = this.submitFile();
        const {load, name, view, utility} = this.state;

        return (
            <div className="tab-pane container col-12 p-0" id="bizPlan">
                <div className="col-md-8 d-flex p-0">
                    <div className="col-md-5 p-0">
                        <h6>Select utility image to upload</h6>
                        <div className="mb-3">
                            <label className="m-0 p-0" htmlFor="">Type of File</label>
                            <input type="text" className="form-control identity-type" name="utility" onChange={this.onChange} value={utility} placeholder="type of file being uploaded. e.g Nepa bill"/>
                        </div>

                        <div className="card p-4 mr-5 text-center card-verify">
                            <span className="fas fa-file-upload"
                                  onClick={()=> this.fileInput.click()}
                                  style={{cursor: 'pointer'}}>
                            </span>
                            <input required={true} type="file" style={{ display: 'none'}} ref={fileInput => this.fileInput = fileInput} onChange={this.fileSelected} className="form-control mt-2 p-2" />

                            <p>Click to select document image</p>
                        </div>
                    </div>
                    <div className="col-md-5 p-0">
                        <h6>Download preview</h6>
                        <div className="card p-4 text-center card-verify">
                            <span className="far fa-file-image"></span>
                            {name ? <img src={view} alt="view" className="w-100"/> : null}
                            {name ? name : <p>Document Preview</p>}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 text-center mt-5">
                    <div className="progress-bar" style={{width:'100%'}} role="progressbar" value={this.state.loaded}>
                        <span>
                            {this.state.loaded ? Math.round(this.state.loaded,2) + '%'  : null }
                        </span>
                    </div>
                </div>

                <div className="col-md-6 text-center mt-5" onClick={this.uploadUtility}>
                    {load ?  <button className="btn second-button text-white" disabled={true}><i className="fa fa-spinner fa-spin"></i></button>
                        : <button className="btn second-button text-white" disabled={!isEnabled}>Upload Utility Document</button>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    user: state.loggedInUser.user,
    file: state.utilityUpload.utilityUpload,
    progress: state.uploadUtilityProgresss.uploadUtilityProgresss

});

export default connect(mapStateToProps, {uploadUtilityVerification})(UploadUtilityDocument);