/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import { CSVLink } from 'react-csv';

const CustomDownload = ({ data, className, textName, fileName, headers }) => {
 
  
  return (
    <CSVLink headers={headers} data={data} filename={fileName}>
      <button className={className}>{textName}</button>
    </CSVLink>
  );
};

export default CustomDownload;
