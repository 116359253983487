/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getReferralCode } from '../../../services/state-management/actions/common/users/UsersActions';

class ReferralCode extends Component {
  static propTypes = {
    getReferralCode: PropTypes.func.isRequired,
    referral: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      referral: '',
    };

    this.onCopy = this.onCopy.bind(this);
  }

  componentDidMount() {
    this.props.getReferralCode();
  }

  onCopy() {
    this.setState({
      copied: true,
    });

    toast.success('Copied!');
  }

  render() {
    const { referral } = this.props;

    return (
      <div className="col-12 col-md-6 col-lg-5 p-0">
        <div className="boxes d-flex mt-3">
          <div className="icon pr-2 mt-3">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1546937884/envelope-wallet.fw.png"
              alt=""
              className="w-100"
            />
          </div>
          <div style={{ marginTop: '-5%' }}>
            {/* Click code below to get shareable link for friends! */}
            <h5 className="count mt-5">
              <CopyToClipboard
                text={`https://myaccount.wificall.ng/#/invite/${referral}`} // use the real one?
                onCopy={this.onCopy}
              >
                <b>
                  {referral}{' '}
                  <span
                    className="badge badge-info copy-font ml-2"
                    style={{ cursor: 'pointer' }}
                  >
                    COPY
                  </span>
                </b>
              </CopyToClipboard>
              <br />
              <span className="copy-font">Referral Code</span>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  referral: state.referral.referral,
});

export default connect(
  mapStateToProps,
  { getReferralCode },
)(ReferralCode);
