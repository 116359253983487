import React, {Component} from 'react';
import {ToastContainer} from "react-toastify";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getUserForVerification, verifyPhone} from "../../../services/state-management/actions/auth/AuthActions";

class VerifyPhoneNumber extends Component {

    constructor(props) {
        super(props);

        this.state = {

            phone: '',
            load: false

        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {

        const {token} = this.props.match.params;
        sessionStorage.setItem('verifytoken', token);
        this.props.getUserForVerification(token);

    };

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    onSubmit = async (e) => {

        e.preventDefault();

        const{phoneNumber} = this.props.number;

        this.setState({

            load: true

        });

        const data = {

            phoneNumber

        };

        this.props.verifyPhone(data, this.props.history);

    };

    submitForm = () => {

        const{phoneNumber} = this.props.number;

        const phone = phoneNumber;

        return(

            phone

        )

    };

    render() {

        const {load} = this.state;

        const{phoneNumber} = this.props.number;

        const phone = phoneNumber;

        const isEnabled = this.submitForm();

        return (
            <div className="main-wrapper">
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
                    <div className="login col-sm-12 col-lg-12 col-md-12">
                        <div className="tizeti-logo">
                            <img src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png" alt="images"/>
                        </div>
                        <form className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12" onSubmit={this.onSubmit}>
                            <div className="form-group col-lg-9 col-sm-12">
                                <h5 style={{color:"black"}}><b>Click button below to verify your phone number</b></h5>
                                {/*<p className="copy-font">Please enterour email and you will get a reset link</p>*/}
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your phone number to receive code"
                                        name="phone"
                                        value={phone}
                                        onChange={this.onChange}
                                        disabled={true}
                                    />
                                </div>
                                <br/>
                            </div>
                            <div className="wrapper-button mb-3">
                                { load ? <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={true}><i className="fa fa-spinner fa-spin"></i></button>
                                    :   <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={!isEnabled}>Submit</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer/>
            </div>

        );
    }
}

const mapStateToProps = state => ({

    number: state.auth.auth

});

export default connect(mapStateToProps, {verifyPhone, getUserForVerification})(withRouter(VerifyPhoneNumber));