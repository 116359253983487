/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React from 'react';
import CustomDownload from '../../../layouts/CustomDownload';
import './style.css';
import moment from 'moment';

const DownloadCallHistoryButton = ({ downloadData }) => {
  const showCallStatus = (cause) => {
    switch (cause) {
      case '1':
        return 'Answered';
      case '2':
        return 'Busy';
      case '3':
        return 'No Answer';
      case '4':
        return 'CANCELLED';
      case '5':
        return 'CONGESTION';
      default:
        return 'Status Unknown';
    }
  };
  const callHeaders = [
    { label: 'Date', key: 'starttime' },
    { label: 'Caller', key: 'src' },
    { label: 'Receiver', key: 'calledstation' },
    { label: 'Call Status', key: 'terminatecauseid' },
    { label: 'Call Duration', key: 'real_sessiontime' },
    { label: 'Download (Individual)', key: 'call_individual' },
    { label: 'Download (Business Users)', key: 'call_business_users' },

    //     { label: 'Call Type', key: '' },
    //   ];
  ];
  const data = downloadData.map((row) => ({
    ...row,

    terminatecauseid: showCallStatus(row.terminatecauseid),
    real_sessiontime: moment
      .utc(Number(row.real_sessiontime) * 1000)
      .format('HH:mm:ss'),
    call_individual: `https://myaccount.wificall.ng/download.php?recordfile=${btoa(
      row.uniqueid + '.wav',
    )}`,
    terminatecauseid: showCallStatus(row.terminatecauseid),
    real_sessiontime: moment
      .utc(Number(row.real_sessiontime) * 1000)
      .format('HH:mm:ss'),
    call_business_users: `https://myaccount.wificall.ng/downloadbusiness.php?recordfile=${btoa(
      row.uniqueid + '.wav',
    )}`,
  }));

  const props = {
    className: 'btn btn-blue text-white download-call-button',
    data: data,
    textName: 'Download Calls',
    fileName: 'Calls.csv',
    headers: callHeaders,
  };

  return (
    <div className="p-0 mr-2">
      <CustomDownload {...props} />
    </div>
  );
};

export default DownloadCallHistoryButton;
