import React, {Component} from 'react';
import {getRecentCalls} from "../../../services/state-management/actions/common/users/UsersActions";
import {connect} from "react-redux";
import moment from "moment";

class RecentCalls extends Component {

    componentDidMount() {

        this.props.getRecentCalls();

    }

    render() {

        const { calls } = this.props;

        return (
            <div className="col-md-6">
                <h6><strong>Recent Calls</strong></h6>
                <div className="card o-income card-body">
                    <div style={{overflowX:"auto"}} className="table-responsive">
                        <table className="table copy-font">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th scope="col">Time</th>
                                <th scope="col">Duration</th>
                            </tr>
                            </thead>

                            {calls.map(
                                (call, i) => (
                                    <tbody>
                                    <tr>
                                        <th scope="row">{i + 1}</th>
                                        {call.src ? <td>{call.src}</td> : <td>N/A</td>}
                                        {call.calledstation ? <td>{call.calledstation}</td> : <td>{call.dnid }</td>}

                                        {/*<td>10:40 AM</td>*/}
                                        {call.starttime ? <td>{call.starttime}</td> : <td>N/A</td>}
                                        {call.real_sessiontime ? <td className="text-center">{moment.utc(Number(call.real_sessiontime)*1000).format('HH:mm:ss')}</td> : <td>N/A</td>}

                                    </tr>
                                    </tbody>
                                )
                            )}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    calls: state.recentCalls.recentCalls

});

export default connect(mapStateToProps, {getRecentCalls})(RecentCalls);