import React, { Component } from 'react'
import {connect} from "react-redux";
import {getWorkgroupsUserBelongsTo} from "../../services/state-management/actions/common/users/UsersActions";
import PropTypes from 'prop-types';

class SelectAccount extends Component {

    static propTypes = {

        getWorkgroupsUserBelongsTo: PropTypes.func.isRequired,
        workgroups: PropTypes.object.isRequired

    };

    componentDidMount() {

        this.props.getWorkgroupsUserBelongsTo();

    }

    render() {

        const { workgroups } = this.props;

        return (
            <div  className="modal fade in" id="select-account" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex col-11" style={{justifyContent:"center"}}>
                                <h4 className="modal-title">Select Account</h4>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>

                        <div className="modal-body">
                            {workgroups.map(
                                (work) => (
                                    <div id="buttons">
                                        <button className="btn mb-3 w-100 shadow-sm p-2">
                                            <h5>{work.workgroup}</h5>
                                        </button>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({

    workgroups: state.workgroups.workgroups

});

export default connect(mapStateToProps, {getWorkgroupsUserBelongsTo})(SelectAccount);
