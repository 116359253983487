import React, {Component} from 'react'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createGroup} from "../../../../../../services/state-management/actions/bulk-dialler/general/GroupsActions";

class CreateGroup extends Component {

    constructor(props){
        super(props);
        this.state = {

            groupname: '',

        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    button(){

        if(!this.state.groupname){

            return(
                <div className="col-12 text-center mt-4">
                        <button className="btn second-button text-white" disabled={true}>Create Group</button>
                    </div>
            )
        } else {

            return(
                <div className="col-12 text-center mt-4">
                        <button className="btn second-button text-white">Create Group</button>
                    </div>
            )
        }

    }

    onSubmit = e => {

        e.preventDefault();

        const {groupname} = this.state;

        const group = {

            name: groupname

        };

        this.props.createGroup(group);

    };

    render() {
        return (
            <div className="modal fade in" id="create-group" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex col-11" style={{justifyContent: "center"}}>
                                <h4 className="modal-title">Create Group</h4>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <div className="col-12">
                                        <label className="m-0 p-0">Group Name</label>
                                        <input type="text"
                                               placeholder="Group Name"
                                               className="form-control w-100"
                                               name="groupname"
                                               value={this.state.groupname}
                                               onChange={this.onChange}
                                        />
                                    </div>

                                    {this.button()}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CreateGroup.propTypes = {

    createGroup: PropTypes.func.isRequired,

};
export default connect(null, {createGroup})(CreateGroup);
