import React, {Component} from 'react';
import {ToastContainer} from "react-toastify";
import {getCampaigns} from "../../../../../services/state-management/actions/bulk-dialler/campaigns/CampaignsActions";
import {connect} from "react-redux";
import {getGroups} from "../../../../../services/state-management/actions/bulk-dialler/general/GroupsActions";
import {getFiles} from "../../../../../services/state-management/actions/files/FilesAction";
import {createRobocall} from "../../../../../services/state-management/actions/bulk-dialler/robocall/RobocallActions";
import {getAssignedPhoneNumber} from "../../../../../services/state-management/actions/common/users/UsersActions";
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";

class CreateRobocall extends Component {

    constructor(props) {
        super(props);

        this.state = {

            campaign: '',
            group: '',
            name: '',
            recording: '',
            recordings: [],
            groups: [],
            load: false

        };

        this.onChange = this.onChange.bind(this);
        this.createRobocall = this.createRobocall.bind(this);

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    componentDidMount() {

        this.props.getFiles();
        this.props.getGroups();
        this.props.getCampaigns();
        this.props.getAssignedPhoneNumber();

    }

    componentWillReceiveProps(nextProps) {

        if(nextProps){

            this.setState({

                load: nextProps.loading

            })

        }

    }

    submitForm(){

        const {campaign, group, recording} = this.state;

        return(

            campaign.length > 0 &&
            group.length > 0 &&
            recording.length > 0

        )

    }

    createRobocall = e => {

        this.setState({

            load: true

        });

        e.preventDefault();

        const {campaign, group, recording} = this.state;
        const {assignedNumber} = this.props;

        if(typeof assignedNumber !== "string"){

            toast.info("You do not have an assigned number. Please go to the phone page to add a phone number");
            //this.props.history.push("/phone");

            this.setState({

                load: false

            })

        } else {

            const data = {

                campaign_name: campaign,
                group_id: group,
                file: recording,
                username: `${sessionStorage.getItem('username')}`,
                callerid: assignedNumber

            };

            this.props.createRobocall(data);

        }

    };

    render() {

        const {campaigns, groups, recordings} = this.props;
        const isEnabled = this.submitForm();
        const{load}=this.state;

        return (
            <div className="col-lg-4 col-12 p-0 mb-2">
                <h6><strong>Create Robocall</strong></h6>
                <div className="col-12 shadow-sm m-0 p-0">
                    <form className="form-horizontal p-3" onSubmit={this.createRobocall}>
                        <div className="form-group">
                            <div className="col-12 d-flex mb-3 m-0 p-0">
                                <div className="col-6">
                                    <label className="m-0 p-0">Campaign Name</label>
                                    <select className="form-control" name="campaign" onChange={this.onChange}>
                                        <option value="">Choose a campaign name</option>
                                    {campaigns.map(
                                        (campaign) => (
                                            <option value={campaign.campaign_name}>{campaign.campaign_name}</option>
                                        )
                                    )}
                                    </select>
                                </div>

                                <div className="col-6">
                                    <label className="m-0 p-0">Group</label>
                                    <select className="form-control" name="group" onChange={this.onChange}>
                                        <option value="">Choose group</option>
                                        {groups.map(
                                            (group) => (
                                                <option value={group.id}>{group.name}</option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 mb-5">
                                <label className="m-0 p-0">Select Recording</label>
                                <select name="recording" onChange={this.onChange} className="form-control">
                                    <option value="">Choose a recording</option>
                                    {recordings.map(
                                        (rec) => (
                                            <option value={rec.id}>{rec.name}</option>
                                        )
                                    )}
                                </select>
                            </div>

                            {load? <div className="col-12 text-center">
                                <button className="btn second-button text-white" disabled={true}><i className="fa fa-spinner fa-spin"></i>Starting robocall...</button>
                            </div> : <div className="col-12 text-center">
                                <button className="btn second-button text-white" disabled={!isEnabled}>Start robocall</button>
                            </div>}
                        </div>
                    </form>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    campaigns: state.campaigns.campaigns,
    campaign: state.campaigns.campaign,
    groups: state.groups.groups,
    recordings: state.files.files,
    loading: state.loadCreateRobocall.loadCreateRobocall,
    robocall: state.robocalls.robocall,
    assignedNumber: state.assignedNumber.number,

});

export default connect(mapStateToProps, {getCampaigns, getGroups, getFiles, createRobocall, getAssignedPhoneNumber})(withRouter(CreateRobocall));
