import React, {Component} from 'react';
import CampaignGroupMembers from "./CampaignGroupMembers";
import moment from 'moment';
import CreateCampaign from "./CreateCampaign";
import {getCampaigns} from "../../../../../services/state-management/actions/bulk-dialler/campaigns/CampaignsActions";
import {connect} from "react-redux";

class Campaigns extends Component {

    constructor(props) {
        super(props);

        this.state = {

            campaignName: '',
            campaignId: ''

        }

    }

    componentDidMount() {

        this.props.getCampaigns();

    }

    getOne = campaign => this.setState({

        campaignId: campaign.id,
        campaignName: campaign.campaign_name

    });

    componentWillReceiveProps(nextProps) {

        if(nextProps.campaign.id){

            this.props.campaigns.unshift(nextProps.campaign)


        }

    }

    render() {

        const {campaigns} = this.props;
        const {campaignId, campaignName} = this.state;

        return (
            <div className="col-12 col-lg-12 d-flex flex-wrap">

                <CreateCampaign/>

                <div className="col-lg-4 col-12 p-0 mb-2">
                    <h6><strong>Campaigns</strong></h6>

                    <div className="card shadow-sm p-4">
                        <table className="table table-responsive">
                            <thead>
                            <tr className="copy-font">
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Group ID</th>
                                <th scope="col">Date Created</th>
                                <th scope="col" className="text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {campaigns.map(
                                (campaign, i) => (

                                    <tr className="copy-font table-row" onClick={() => this.getOne(campaign)}>
                                        <td>{i+1}</td>
                                        <td>{campaign.campaign_name}</td>
                                        <td>{campaign.group_id}</td>
                                        <td>{moment(campaign.created_at).format('DD/MM/YYYY')}</td>
                                        <td>
                                            <div className="d-flex">
                                                <div className="subscriber-img mr-2">
                                                    <img
                                                        src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png"
                                                        alt="images"/>
                                                </div>

                                                <div className="subscriber-img mr-2">
                                                    <img
                                                        src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png"
                                                        alt="images"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <CampaignGroupMembers name={campaignName} id={campaignId}/>

            </div>
        );
    }
}

const mapStateToProps = state => ({

    campaigns: state.campaigns.campaigns,
    campaign: state.campaigns.campaign

});

export default connect(mapStateToProps, {getCampaigns})(Campaigns);