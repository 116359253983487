import React from 'react';
import MenuBar from '../../../layouts/MenuBar';
import Balance from './Balance';
import ButtonSet from '../../../layouts/ButtonSet';
import AssignPhoneNumber from './AssignPhoneNumber';
import PhoneTable from './PhoneTable';

const Phone = () => {
  return (
    <div className="body">
      <div className="container-fluid">
        <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
            alt="logo"
            width="12%"
            height="15%"
          />
        </div>
        <div className="row">
          <MenuBar />
        </div>
        <div className="container-fluid" id="resize-fluid">
          <div className="col-lg-12 col-12 d-flex mt-3 p-0">
            <div className="col-lg-6  mr-auto d-none d-lg-flex d-md-flex">
              <h6>
                <strong>Phone number selections</strong>
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1551709317/info.svg"
                  alt=""
                  data-toggle="tooltip"
                  data-placement="top"
                  className="ml-1"
                  title="Select phone numbers and the allocations"
                />
              </h6>
            </div>

            <Balance />

            <div className="pr-0">
              <div className="d-flex button-section">
                <ButtonSet />
              </div>
            </div>
          </div>

          <AssignPhoneNumber />

          <div className="col-12 col-lg-12">
            <div className="col-8 mr-auto">
              <h6>
                <strong>Phone number allocations</strong>
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1551709317/info.svg"
                  alt=""
                  data-toggle="tooltip"
                  data-placement="top"
                  className="ml-1"
                  title="List of phone numbers and the destinations allocated"
                />
              </h6>
            </div>
            <div className="col-12 col-lg-8">
              <div className="card o-income card-body shadow-sm">
                <table className="table copy-font">
                  <thead>
                    <tr className="copy-font">
                      <th scope="col">#</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Category</th>
                      <th scope="col" className="text-center">
                        Destinations
                      </th>
                    </tr>
                  </thead>

                  <PhoneTable />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Phone;
