import React, {Component} from 'react';
import {getUserBalance} from "../../../services/state-management/actions/common/users/UsersActions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Helper from "../../util/Helper";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";

class Balance extends Component {

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        fetchUser: PropTypes.func.isRequired

    };

    componentDidMount() {

        this.props.getUserBalance();
        this.props.fetchUser();

    }

    checkPlan = () => {

        const {plan} = this.props.user;
        const {wallet} = this.props;

        if(plan){
            switch (plan.name) {
                case "Basic unlimited plan":
                    return <b>Unlimited</b>;

                default:
                    return <b>{typeof (wallet) == "string" ? Helper.formatCurrency(wallet) : null}</b>

            }

        } else return null

    };

    render() {

        return (
            <div className="col-12 col-md-6 col-lg-5 p-0">
                <div className="boxes d-flex mt-3">
                    <div className="icon">
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1546937883/wificall-wallet.fw.png" alt='' className="w-75 mt-2"/>
                    </div>

                    <div>
                        {/*{wallet ? Math.trunc(wallet).toLocaleString() : null}*/}
                        <h5 className="count mt-2">
                            <span className="saving">
                                {this.checkPlan()}
                            </span>
                            <br/>
                            <span className="copy-font">Wallet Balance</span></h5>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return{

        wallet: state.wallet.wallet,
        user: state.auth.auth

    }

};

export default connect(mapStateToProps, {getUserBalance, fetchUser})(Balance);