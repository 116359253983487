import React from 'react';
import useNin from '../hooks/nin/useNin';

const NotificationNIN = () => {
  const { nin } = useNin();

  return nin ? null : (
    <div className="col-12 bg-danger" style={{ height: '60px' }}>
      <p className="pt-3 text-center notifyFont">
        <span
          className="text-white"
          style={{ textDecorationLine: 'none', fontFamily: 'sans-serif' }}
        >
          Kindly Update Profile With Your NIN
        </span>
      </p>
    </div>
  );
};

export default NotificationNIN;
