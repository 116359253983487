import {
    CREATE_USER,
    GET_ASSIGNED_PHONE_NUMBER, GET_RECENT_CALLS,
    GET_REFERRAL_CODE,
    GET_TOTAL_USERS,
    GET_USERS_IN_WORKGROUP,
    GET_WALLET_BALANCE
} from "../../../actions/types/UsersTypes";

const initialState = {

    wallet: {},
    users: [],
    number: {},
    referral: '',
    totalUsers: [],
    recentCalls: [],

};

const UsersReducer = (state = initialState, action) => {
    switch (action.type) {
        // case 'FETCH_STATUS':
        //     return [...state, action.payload];

        // case 'FETCH_USERS':
        //     return [...state, action.payload];

        // case 'FETCH_ADMIN':
        //     return [...state, action.payload];

        case GET_WALLET_BALANCE:
            // return [...state, action.payload];
            return {
                ...state,
                wallet: action.payload

            };

        case GET_ASSIGNED_PHONE_NUMBER:
            // return [...state, action.payload];
            return {
                ...state,
                number: action.payload
            };

        case GET_REFERRAL_CODE:

            return {

                ...state,
                referral: action.payload

            };

        case GET_USERS_IN_WORKGROUP:

            return {

                ...state,
                users: action.payload

            };

        case GET_TOTAL_USERS:
            return {

                ...state,
                totalUsers: action.payload

            };

        case GET_RECENT_CALLS:
            return {

                ...state,
                recentCalls: action.payload

            };

        case CREATE_USER:
            return [...state, action.payload];
        // return {
            //
            //     ...state,
            //     user: [action.payload, ...state.user]
            //
            // };

        default:
            return state;

    }

};

 export default UsersReducer;