import React, {Component} from 'react';
import {connect} from "react-redux";
import EditPhoneGroup from "../../../EditPhoneGroup";
import tizeti from "../../../../../../services/apis/tizeti";

class PhoneNumbersGroupMembers extends Component {

    constructor(props) {
        super(props);

        this.state = {

            members: [],
            loading: false

        }

    }

    componentWillReceiveProps(nextProps) {
        const url = `/wificall/campaignNumbers/list/${nextProps.id}`;
        this.setState({

            loading: true

        });
        tizeti.get(url)
            .then(res => {

                if(res.data.rows){

                    this.setState({

                        members: res.data.rows,
                        loading: false

                    })

                }

            })
    }

    render() {

        const { members, loading } = this.state;

        const memberList = members ? (
            members.map(
                (member, i) => {
                    return (
                        <tr className="copy-font">
                            <th scope="row">{i + 1}</th>
                            <td>{member.firstname}</td>
                            <td>{member.lastname}</td>
                            <td>{member.phonenumber}</td>
                            <td>
                                <div className="d-flex">
                                    <div className="subscriber-img mr-2" data-target="#phone" data-toggle="modal">
                                        <img
                                            src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png"
                                            alt="images"/>
                                    </div>
                                    <EditPhoneGroup/>
                                    <div className="subscriber-img mr-2">
                                        <img
                                            src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png"
                                            alt="images"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                }
            )
        ) : (

            <h1>No members in this group</h1>
        );

        return (
            <div className="col-lg-5 col-12 mb-3 p-0">
                <h6><strong>{this.props.name}</strong></h6>
                {this.props.id ?
                    <div className="card shadow-sm p-4">
                    <table className="table table-responsive">
                        <thead>
                        <tr className="copy-font">
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col" className="text-center">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ? <i className="fa fa-spinner fa-spin"></i> : memberList}
                        </tbody>
                    </table>
                </div> : null}
            </div>
        );
    };

}

// const mapStateToProps = (state, ownProps) => ({
//
//     groupMembers: state.groups.groups.find(group => group.id === ownProps.id)
//
// });

export default connect(null)(PhoneNumbersGroupMembers);
