import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CreateRobocall from './CreateRobocall';
import RobocallMembers from './RobocallMembers';
import { getRobocalls } from '../../../../../services/state-management/actions/bulk-dialler/robocall/RobocallActions';

class Robocall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      robocall: '',
      robocallName: '',
    };
  }

  componentDidMount() {
    this.props.getRobocalls();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.robocall.id) {
      // this.props.robocalls.unshift(nextProps.robocall);
      this.props.getRobocalls();
    }
  }

  getRobocall = (robo) =>
    this.setState({
      robocall: robo.id,
      robocallName: robo.name,
    });

  render() {
    const {robocalls} = this.props;
    const {robocall, robocallName} = this.state;

    const robocallList = robocalls.length ? (
      robocalls.map(
        (robo, i) => (
          <tr className="copy-font"
              onClick={() => {
                return this.getRobocall(robo);
              }}
          >
            <td>{i+1}</td>
            <td>{robo.campaign_name}</td>
            <td>{robo.group.name ? robo.group.name : 'N/A' }</td>
            <td>{robo.file}</td>
            <td>{robo.status === true ? 'True' : 'False' }</td>
          </tr>
        )
      )
    ): (
      <small>This user owns no robocalls!</small>
    );

    return (
      <div className="col-12 col-lg-12 d-flex flex-wrap">
        <CreateRobocall/>
        {/*<RobocallHistory/>*/}
        <div className="col-lg-5 col-12 p-0 mb-2">
          <h6><strong>Call History</strong></h6>
          <div className="card shadow-sm p-4">
            <table className="table table-responsive">
              <thead>
              <tr className="copy-font">
                <th scope="col">#</th>
                <th scope="col">Campaign Name</th>
                <th scope="col">Group Name</th>
                <th scope="col">File</th>
                <th scope="col" className="text-center">Call Status</th>
              </tr>
              </thead>


              <tbody>

              {robocallList}

              </tbody>
            </table>
          </div>
        </div>
        <RobocallMembers id={robocall}
                         name={robocallName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  robocalls: state.robocalls.robocalls,
  robocall: state.robocalls.robocall,
});

Robocall.propTypes = {
  // eslint-disable-next-line react/require-default-props
  getRobocalls: PropTypes.func,
}

export default connect(
  mapStateToProps,
  { getRobocalls },
)(Robocall);
