import {GET_USER_PAYMENTS} from "../../actions/payments/PaymentTypes";

const initialState = {

    payments: [],
    load: true

};

const PaymentsReducer = (state=initialState, action) => {

    switch (action.type) {
        case GET_USER_PAYMENTS:
            return {

                ...state,
                payments: action.payload.response,
                load: action.payload.load

            };

        default:
            return state;

    }
};

export default PaymentsReducer;