/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  BASIC_UNLIMITED_PLAN,
  PAYSTACK_BASIC_CHARGE,
  PAYSTACK_LIVE_KEY,
  PAYSTACK_SECRET_KEY,
  VERIFY_PAYSTACK_URL,
} from '../components/config/Payment';
import SubscribePage from './Subscribe';
import Plans from './Plans';
import { fetchPlans } from '../services/state-management/actions/common/plans/PlansActions';
import Payg from '../components/user/payments/Payg';
import PlanHelper from './utils/plan.helper';
import Helper from '../components/util/Helper';

class ButtonSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: [],
      email: '',
      amount: '',
      business: 15000,
      plan: '',
    };
  }

  componentDidMount() {
    this.props.fetchPlans();
  }

  expectedPrice = (amountPaid, amountInc) => {
    amountPaid = amountPaid / 100;
    return ((amountPaid + amountInc) / (1 - `${PAYSTACK_BASIC_CHARGE}`)) * 100;
  };

  payForPlanWithPaystack = (plan) => {
    let handler;

    if (plan.amount) {
      const amount = plan.amount * 100;
      const newAmount = this.expectedPrice(amount, 100);

      if (amount === 600000) {
        handler = window.PaystackPop.setup({
          key: `${PAYSTACK_LIVE_KEY}`,
          email: sessionStorage.getItem('email'),
          plan: `${BASIC_UNLIMITED_PLAN}`,
          metadata: {
            custom_fields: [
              {
                email: sessionStorage.getItem('email'),
                card_id: sessionStorage.getItem('cardid'),
                amount,
                planid: plan.id,
                planName: plan.name,
                source: 'web',
              },
            ],
          },
          callback(response) {
            if (response.reference) {
              const ref = response.reference;

              try {
                axios
                  .get(`${VERIFY_PAYSTACK_URL}/${ref}`, {
                    headers: {
                      Authorization: `Bearer ${PAYSTACK_SECRET_KEY}`,
                    },
                  })
                  .then((res) => {
                    if (res.data.status !== true) {
                      return;
                    }
                    toast.success('Transaction Successful');
                  });
              } catch (e) {
                toast.info('Something went wrong!');
              }
            } else {
              toast.info('Transaction Failed');
            }
          },
          onClose() {
            //            alert('window closed');
          },
        });
        handler.openIframe();
      } else {
        handler = window.PaystackPop.setup({
          key: `${PAYSTACK_LIVE_KEY}`,
          email: sessionStorage.getItem('email'),
          amount: Math.round(newAmount),
          ref: `${Math.floor(Math.random() * 1000000000 + 1)}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
          firstname: sessionStorage.getItem('firstname'),
          lastname: sessionStorage.getItem('lastname'),
          // label: "Optional string that replaces user email"
          metadata: {
            custom_fields: [
              {
                email: sessionStorage.getItem('email'),
                card_id: sessionStorage.getItem('cardid'),
                amount,
                planid: plan.id,
                planName: plan.name,
                source: 'web',
              },
            ],
          },
          callback(response) {
            if (response.reference) {
              const ref = response.reference;
              try {
                axios
                  .get(`${VERIFY_PAYSTACK_URL}/${ref}`, {
                    headers: {
                      Authorization: `Bearer ${PAYSTACK_SECRET_KEY}`,
                    },
                  })
                  .then((res) => {
                    if (res.data.status !== true) {
                      return;
                    }
                    toast.success('Transaction Successful');
                  });
              } catch (e) {
                toast.info('Something went wrong!');
              }

              // const url = `${API_URL}/verify?reference=${ref}&planid=${plan.id}`;
              // axios({
              //
              //     method: 'get',
              //     url: url
              //
              // }).then(res => {
              //
              //     if (res.data.status === true && res.data.message === "PostPayment SUCCESS") {
              //             toast.success("Transaction Successful");
              //             window.location.reload()
              //
              //     }
              //
              // });
            } else {
              toast.info('Transaction Failed');
            }
          },

          onClose() {
            //            alert('window closed');
          },
        });
        handler.openIframe();
      }
    }
  };

  render() {
    const { plans } = this.props;

    return (
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-green text-white"
          data-target="#pager"
          data-toggle="modal"
          style={{ height: '50px' }}
        >
          Top-Up
        </button>

        <div
          className="modal right fade"
          id="pager"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel2"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div
                className="mx-auto pt-3 modal-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <h5 className="d-none d-md-flex fontWeight900">
                  Subscribe To Your Preferred Plan
                </h5>
                <h6 className="d-md-none fontWeight900 ml-3">
                  Subscribe To Your Preferred Plan
                  <span className="ml-3">&times;</span>
                </h6>
              </div>

              <div className="mt-0 pt-0 modal-body">
                <Payg />

                {plans.map((plan) => {
                  return plan.id === 2 ? (
                    <Plans
                      planName={PlanHelper.capitalizeFirstLetters(
                        PlanHelper.removeAndReplace(plan.name, 'UNLIMITED', ''),
                      )}
                      amount={Helper.formatCurrency(plan.amount)}
                      paystack={() => this.payForPlanWithPaystack(plan)}
                      border="3px solid #33c0ac"
                      text1="Unlimited Usage with Fair Usage policy"
                      text2="One User"
                      color="#0f676b"
                    />
                  ) : plan.id === 3 ? (
                    <Plans
                      planName={`${plan.name} Airtime`}
                      amount={Helper.formatCurrency(plan.amount)}
                      paystack={() => this.payForPlanWithPaystack(plan)}
                      border="3px solid #0f676b"
                      background="#0f676b"
                      // text1={
                      //   <span>
                      //     Create up to <strong>10</strong> users
                      //   </span>
                      // }
                      text2="Auto-Attendant"
                      text3="Default IVR features"
                      color="white"
                    />
                  ) : plan.id === 4 ? (
                    <Plans
                      planName={PlanHelper.capitalizeFirstLetters(
                        PlanHelper.removeAndReplace(
                          plan.name,
                          'PLAN',
                          'Airtime',
                        ),
                      )}
                      amount={Helper.formatCurrency(plan.amount)}
                      paystack={() => this.payForPlanWithPaystack(plan)}
                      border="3px solid #99255e"
                      background="#99255e"
                      // text1={
                      //   <span>
                      //     Create up to <strong>20</strong> users
                      //   </span>
                      // }
                      text2="Auto-Attendant"
                      text3="Default IVR features"
                      color="white"
                    />
                  ) : plan.id === 8 ? (
                    <Plans
                      planName={PlanHelper.capitalizeFirstLetters(
                        PlanHelper.removeAndReplace(
                          plan.name,
                          'PLAN',
                          'Airtime',
                        ),
                      )}
                      amount={Helper.formatCurrency(plan.amount)}
                      paystack={() => this.payForPlanWithPaystack(plan)}
                      border="3px solid #0f676b"
                      text2="Auto-Attendant"
                      text3="Custom IVR Features"
                      // text2={
                      //   <span>
                      //     30 Days validity
                      //     <br />
                      //   </span>
                      // }
                      color="#0f676b"
                    />
                  ) : null;
                })}
              </div>
            </div>
          </div>
        </div>
        <SubscribePage />
       
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
});

export default connect(
  mapStateToProps,
  { fetchPlans },
)(ButtonSet);
