/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { fetchPlans } from '../../../services/state-management/actions/common/plans/PlansActions';
import { registerUser } from '../../../services/state-management/actions/auth/AuthActions';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      phone: '',
      planType: '',
      passwordShown: false,
      password: '',
      referral: '',
      nin: '',
    };
  }

  componentDidMount() {
    this.props.fetchPlans();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        errors: nextProps.errors,
        loading: nextProps.loading,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (!navigator.onLine) {
      toast.warn('Network Disconnected');
    }
    const {
      planType,
      // gender,
      referral,
      about,
      email,
      password,
      address,
      lastName,
      firstName,
      phone,
      nin,
    } = this.state;

    const newUser = {
      firstName,
      lastName,
      address,
      // gender,
      email,
      phoneNumber: phone,
      nin,
      password,
      plan_id: 13,
      referral,
      planType,
      about_us: about,
      source: 'web',
      // key: "2GpDc}RM[^AY:@'J"
    };
    // const encryptedUserData = btoa(newUser.toString());
    this.props.registerUser(newUser, this.props.history);
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  togglePasswordVisiblity = () => {
    this.setState((prevState) => {
      return {
        passwordShown: !prevState.passwordShown,
      };
    });
  };

  submitForm() {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      planType,
      nin,
    } = this.state;

    return (
      email.length > 0 &&
      password.length > 0 &&
      lastName.length > 0 &&
      firstName.length > 0 &&
      phone.length >= 12 &&
      planType.length >= 8 &&
      nin.length === 11
    );
  }

  render() {
    const {
      referral,
      password,
      passwordShown,
      address,
      lastName,
      firstName,
      phone,
      email,
      nin,
    } = this.state;
    const { loading } = this.props;
    const isEnabled = this.submitForm();

    return (
      <div className="container">
        <div className="col-lg-7 col-md-7 col-sm-12 d-flex mt-5 ml-auto mr-auto">
          <div className="col-12">
            <div className="tizeti-logo">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
              />
            </div>
            <div className="col-12 p-0">
              <form
                className="form-horizontal mt-3 login-form"
                onSubmit={this.onSubmit}
              >
                <p className="mt-3 text-center" style={{ fontSize: '13px' }}>
                  <strong>
                    Sign up to enjoy crystal clear calls with family, friends,
                    customers and business partners
                  </strong>
                </p>
                <div className="form-row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="copy-font form-control mr-3"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="copy-font form-control"
                      name="lastName"
                      value={lastName}
                      onChange={this.onChange}
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col">
                    <div>
                      <PhoneInput
                        country="ng"
                        value={phone}
                        onChange={(selected) =>
                          this.setState({ phone: selected })}
                        inputStyle={{ width: 'inherit' }}
                        isValid={(value, country) => {
                          if (value.match(/12345/)) {
                            return `Invalid value: ${value}, ${country.name}`;
                          }
                          if (value.match(/1234/)) {
                            return false;
                          }
                          return true;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="copy-font form-control"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      placeholder="Email Address"
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col password-wrapper">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      className="copy-font form-control"
                      placeholder="Password"
                      value={password}
                      onChange={this.onChange}
                      name="password"
                    />
                    <i
                      className={`pointer copy-font ${
                        passwordShown ? 'fa fa-eye' : 'fa fa-eye-slash'
                      }`}
                      aria-hidden="true"
                      onClick={this.togglePasswordVisiblity}
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col">
                    <select
                      defaultValue="DEFAULT"
                      onChange={this.onChange}
                      name="planType"
                      className="copy-font form-control"
                    >
                      <option value="DEFAULT" disabled>
                        Plan Type
                      </option>
                      <option value="Personal">Personal</option>
                      <option value="Business">Business</option>
                    </select>
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="copy-font form-control"
                      name="address"
                      value={address}
                      onChange={this.onChange}
                      placeholder="Address"
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="copy-font form-control"
                      name="nin"
                      value={nin}
                      onChange={this.onChange}
                      maxLength="11"
                      placeholder="National Identification Number (NIN)"
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="copy-font form-control"
                      placeholder="Referral code (Optional)"
                      value={referral}
                      onChange={this.onChange}
                      name="referral"
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col">
                    <select
                      onChange={this.onChange}
                      name="about"
                      className="copy-font form-control"
                    >
                      <option>How did you hear about us?</option>
                      <option value="google search">Google Search</option>
                      <option value="Friend Referral">Friend Referral</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="WhatsApp">WhatsApp</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Nairaland">Nairaland</option>
                      <option value="TV">TV</option>
                      <option value="Radio">Radio</option>
                      <option value="Billboard">Billboard</option>
                      <option value="Flyer">Flyer</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="wrapper-button col-12 p-0 mt-2">
                  {loading ? (
                    <button
                      className="btn btn-green w-100"
                      style={{ backgroundColor: '#35BEAA', color: 'white' }}
                      disabled
                    >
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: '24px' }}
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-green w-100"
                      style={{ backgroundColor: '#35BEAA', color: 'white' }}
                      disabled={!isEnabled}
                    >
                      CREATE ACCOUNT
                    </button>
                  )}
                </div>
              </form>

              <div
                className="copy-font"
                id="signin"
                style={{ marginTop: '-3%' }}
              >
                <p
                  className="copy-font text-center mb-1"
                  style={{ fontSize: '12px' }}
                >
                  By clicking signup, you agree to our{' '}
                  <a
                    href="https://wificall.ng/terms.html"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <b>Terms of service</b>
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://wificall.ng/policy.html"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <b>Privacy Policy</b>
                  </a>
                </p>
                <p className="text-center mt-2" style={{ fontSize: '11px' }}>
                  Already have an account?
                  <strong>
                    <Link
                      to="/login"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      {' '}
                      Sign In
                    </Link>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

SignUp.propTypes = {
  registerUser: PropTypes.func,
  auth: PropTypes.shape(),
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
  auth: state.auth,
  loading: state.loadRegister.loadRegister,
});

export default connect(
  mapStateToProps,
  { fetchPlans, registerUser },
)(withRouter(SignUp));
