import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getUserBalance} from "../../../services/state-management/actions/common/users/UsersActions";
import Helper from "../../util/Helper";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";
import {ToastContainer} from "react-toastify";

class Balance extends Component {

    componentDidMount() {

        this.props.getUserBalance();
        this.props.fetchUser();

    };

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        wallet: PropTypes.string.isRequired

    };

    checkPlan = () => {

        const {plan} = this.props.user;
        const {wallet} = this.props;

        if(plan){
            if (plan.name === "Basic unlimited plan") {
                return "Unlimited";
            } else {
                return <b>{typeof (wallet) == "string" ? Helper.formatCurrency(wallet) : null}</b>
            }

        } else return null

    };

    render() {

        return (
            <div className="col-md-2 col-12 mb-3">
                <div className="d-flex">
                    <div className="icon pr-2">
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png" alt=''/>
                    </div>

                    <div>
                        {/*.toFixed(2).*/}
                        <h5 className="count mt-1"><span className="saving"> <b>{this.checkPlan()}</b></span>
                            <br />
                            <span className="baby-font">Balance</span></h5>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );
    }

}

const mapStateToProps = (state) => {

    return {

        wallet: state.wallet.wallet,
        user: state.auth.auth

    };

};

export default connect(
  mapStateToProps,
  { getUserBalance, fetchUser },
)(Balance);
