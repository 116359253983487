import React, { Component } from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectAccount from './SelectAccount';
import Profile from './Profile';
import ChangePassword from '../auth/reset/ChangePassword';
import { logoutUser } from '../../services/state-management/actions/auth/AuthActions';
import { LOGGED_IN_USER_URL } from '../../services/apis/user/UserUrl';

class AuthUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: [],
      workgroups: [],
    };

    this.logout = this.logout.bind(this);
  }

  static propTypes = {
    logoutUser: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    axios({
      method: 'get',
      url: LOGGED_IN_USER_URL,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.data) {
        this.setState({
          name: response.data.firstName,
          workgroups: response.data.user_sip.workgroup,
        });
      }
    });
  }

  logout() {
    sessionStorage.setItem('id', '');
    sessionStorage.removeItem('id');
    sessionStorage.setItem('username', '');
    sessionStorage.removeItem('username');
    sessionStorage.setItem('cardid', '');
    sessionStorage.removeItem('cardid');
    sessionStorage.setItem('token', '');
    sessionStorage.removeItem('token');
    sessionStorage.setItem('email', '');
    sessionStorage.removeItem('email');
    sessionStorage.setItem('userid', '');
    sessionStorage.removeItem('userid');
    sessionStorage.setItem('firstname', '');
    sessionStorage.removeItem('firstname');
    sessionStorage.setItem('lastname', '');
    sessionStorage.removeItem('lastname');
    sessionStorage.setItem('workgroup', '');
    sessionStorage.removeItem('workgroup');
    sessionStorage.setItem('referral', '');
    sessionStorage.removeItem('referral');
    sessionStorage.setItem('usersipid', '');
    sessionStorage.removeItem('usersipid');
    sessionStorage.clear();
    this.props.logoutUser(this.props.history);
  }

  render() {
    const { workgroups, name } = this.state;

    return (
      <div className="mr-2 profile-logout dropdown">
        <h6 className="mr-2 d-none d-sm-block" data-toggle="dropdown">
          <b>{name || null}</b>
          <span>
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1542977026/Profile.png"
              className="ml-2"
              alt="images"
            />
          </span>
        </h6>
        <ul className="dropdown-menu dropdown-menu-right p-2">
          <li data-target="#profile" data-toggle="modal">
            Profile
          </li>

          {workgroups ? (
            <li data-target="#select-account" data-toggle="modal">
              {workgroups.length > 11
                ? `${sessionStorage.getItem('workgroup').substring(0, 10)}...`
                : workgroups}
            </li>
          ) : null}
          <li data-target="#changePassword" data-toggle="modal">
            Change Password
          </li>
          <li>
            <Link to="/business-page">My Business Page </Link>
          </li>
          <li>
            <Link to="/developers">Developers</Link>
          </li>
          <li>
            <Link to="#" onClick={this.logout}>
              Logout
            </Link>
          </li>
        </ul>
        <Profile />
        <SelectAccount />
        <ChangePassword />
      </div>
    );
  }
}

export default connect(
  null,
  { logoutUser },
)(withRouter(AuthUser));
