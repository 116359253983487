import axios from 'axios';
import { GET_IVRS, LOADING_IVRS } from '../../types/IvrTypes';
import { GET_IVRS_URL } from '../../../../apis/IvrUrl';

export const getIVrs = () => async (dispatch) => {
  dispatch(loadingIvrs());

  const response = await axios.get(
    `${GET_IVRS_URL}/${sessionStorage.getItem('cardid')}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    },
  );

  if (response.data.status === true) {
    dispatch({
      type: GET_IVRS,
      payload: response.data.result,
    });
  }
};

export const loadingIvrs = () => {
  return {
    type: LOADING_IVRS,
  };
};
