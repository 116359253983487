import { useEffect, useState } from 'react';
import axios from 'axios';
import { stringify } from 'qs';
import { toast } from 'react-toastify';

const useOtpHistory = () => {
  const cardId = sessionStorage.getItem('cardid')
  const SEARCH_ACTION = 'wificall_otp_history'
  const today = new Date().toISOString().slice(0, 10);
  const searchState = {
    action: SEARCH_ACTION,
    cardid: cardId,
    pageno: '1',
    no_of_records_per_page: '5',
    from: today,
    to: today,
  };
  const [search, setSearch] = useState(searchState);
  const [totalPages, setTotalPages] = useState(0);
  const [otps, setOtps] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = (e: { target: { name: any; value: any } }) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const onPaginate = async (num: number) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://legacy.tizeti.com/geniatechapi/index.php`,
        stringify({
          action: SEARCH_ACTION,
          cardid: cardId,
          pageno: num,
          no_of_records_per_page: search.no_of_records_per_page,
          from: search.from,
          to: search.to,
        }),
      );
      if (response.data.status) {
        setOtps(response.data.history);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.info('Unable to get otps');
    }
  };
  const onSubmit = () => {
  };
  useEffect(() => {
    async function getOtpHistory() {
      setLoading(true);
      return axios.post(
        `https://legacy.tizeti.com/geniatechapi/index.php`,
        stringify(search),
      );
    }
    getOtpHistory()
      .then((response) => {
        if (response.data.status) {
          setOtps(response.data.history);
          setTotalPages(response.data.total_pages);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        toast.info('Unable to get otps');
      });
  }, [search]);
  return { onChange, onPaginate, onSubmit, search, otps, totalPages, loading };
};
export default useOtpHistory;
