import {CREATE_GROUP, FETCH_GROUPS} from "../../actions/types/bulk-dialler/GroupsTypes";

const initialState = {

    groups: [],
    group: {}

};

const GroupsReducer = (state = initialState, action) => {

    switch (action.type) {

        case FETCH_GROUPS:
            // console.log('reducer')
            return {

                ...state,
                groups: action.payload

            };

        case CREATE_GROUP:
            return {
                ...state,
                group: action.payload
                //group: [action.payload, ...state.items];
            };

        case 'GET_GROUP':
            return action.payload;

        default:
            return state;

    }

};

export default GroupsReducer;