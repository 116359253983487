import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../types/files/FilesTypes';
import { API_URL } from '../../../../components/config/BaseUrl';

export const loadingFiles = () => {
  return {
    type: types.LOADING_FILES,
  };
};

export const getFiles = () => async (dispatch) => {
  dispatch(loadingFiles());

  const url = `${API_URL}/wificall/getupload/${sessionStorage.getItem(
    'cardid',
  )}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });

  if (response.data.status === false) {
  } else {
    dispatch({
      type: types.GET_FILES,
      payload: response.data.result,
    });
  }
};

export const uploadFile = (data) => {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    dispatch({ type: types.START_FILE_UPLOAD });
    try {
      const response = await axios.post(`${API_URL}/wificall/addupload`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },

        onUploadProgress: (ProgressEvent) => {
          dispatch({
            type: types.UPLOAD_FILE_PROGRESS,
            payload: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      });

      if (response.data.status === true) {
        toast.success('File Uploaded Successfully!');

        dispatch({
          type: types.UPLOAD_FILE_SUCCESS,
          payload: response.data.result,
        });
      } else {
        toast.info('Unable to upload file');
        dispatch({ type: types.STOP_FILE_UPLOAD });
      }
    } catch (error) {
      switch (error.response.data.message) {
        case "That fileType isn't allowed, only wav files allowed!!":
          dispatch({ type: types.STOP_FILE_UPLOAD });
          return toast.info('You can only upload .wav files');

        default:
          dispatch({ type: types.STOP_FILE_UPLOAD });
          return toast.info('Something went wrong. Please try again later!');
      }
    }
  };
};

export const deleteFile = (id) => (dispatch) => {
  const data = {
    filebucket_number: id.filebucket_number,
    filebucket_type: id.filebucket_type,
    filebucket_extension: id.filebucket_extension,
    card_id: `${sessionStorage.getItem('cardid')}`,
    workgroup_name: `${sessionStorage.getItem('workgroup')}`,
  };

  axios
    .post(`${API_URL}/wificall/deleteUpload`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      if (response.data.status === true) {
        toast.success('File deleted successfully!');
        dispatch({
          type: types.DELETE_FILE,
          payload: data.filebucket_number,
        });
      }
    });
};
