import React, {Component} from 'react';
import MenuBar from "../../../layouts/MenuBar";
import ButtonSet from "../../../layouts/ButtonSet";
import Balance from "./Balance";
import UploadIdentityDocument from "./UploadIdentityDocument";
import UploadUtilityDocument from "./UploadUtilityDocument";
import NotificationNIN from '../../../layouts/NotificationNIN';

class VerifyAccount extends Component {
    render() {
        return (
            <div className="body">
                <div className="container-fluid">
                <div className="row">
            <NotificationNIN />
          </div>
                    <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
                        <img
                            src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
                            alt="logo" width="12%" height="15%"/></div>
                    <div className="row">
                        <MenuBar/>
                    </div>

                    <div className="container-fluid " id="resize-fluid">
                        <div className="d-flex">
                            <h6 className="mt-5 mr-auto"><b>Select document type to upload</b></h6>
                            <div className="d-flex">
                                <div className="d-flex mr-4 mt-3">
                                    <div className="icon pr-2">
                                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png"
                                            alt=''/>
                                    </div>

                                    <Balance/>
                                </div>
                                <div className="d-flex button-section mt-3 mb-2">
                                    <ButtonSet/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-12 mb-3">
                            <div className="col-lg-4 col-12 p-0">
                                <ul className="nav nav-pills" id="pills-tab">
                                    <li className="nav-item mr-auto">
                                        <a className="nav-link active" data-toggle="tab" href="#homePlan">Identity
                                            documents</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#bizPlan">Utility documents</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content col-md-12 p-0 mt-3">
                                <UploadIdentityDocument/>

                                <UploadUtilityDocument/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VerifyAccount;