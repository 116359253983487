import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getUsersInWorkgroup} from "../../../services/state-management/actions/workgroups/WorkgroupsActions";
import {getQueues} from "../../../services/state-management/actions/queues/QueuesActions";

class QueueMembers extends Component {

    componentDidMount() {

        this.props.getQueues();

    }

    static propTypes = {

        getQueues: PropTypes.func.isRequired,


    };

    render() {

        const {queues, load} = this.props;

        return (
            <div className="mb-2 col-12 col-md-6 col-lg-4 p-0">
                <h6><strong>Queues</strong></h6>
                <div className="card o-income card-body">
                    <div style={{overflowX:"auto"}}>
                        <table className="table copy-font">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Actions</th>
                            </tr>
                            </thead>


                            {load ? <img src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif" alt=''/> :
                                <tbody>
                                {queues.map(
                                (queue, i) => (
                                    <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>{queue.name}</td>
                                        <td>{queue.description}</td>
                                        <td>
                                            <div className="d-flex">
                                                <div className="subscriber-img mr-2">
                                                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546952532/eye.fw.png" alt="images"/>
                                                </div>
                                                <div className="subscriber-img mr-2">
                                                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png" alt="images"/>
                                                </div>
                                                <div className="subscriber-img mr-2">
                                                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png" alt="images"/>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                )
                            )}
                            </tbody> }


                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    queues: state.queues.queues,
    load: state.queues.loadQueues

});


export default connect(mapStateToProps, { getUsersInWorkgroup, getQueues})(QueueMembers);