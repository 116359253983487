import React, { Component } from 'react'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {withRouter} from "react-router-dom";
import {resetPassword} from "../../services/state-management/actions/auth/AuthActions";
import {connect} from "react-redux";

class ResetConfirm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            password: '',
            confirmpassword: '',
            load: false

        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    onSubmit = e => {

        const {password, confirmpassword} = this.state;

        e.preventDefault();

        this.setState({

            load: true

        });

        const { token } = this.props.match.params;

        const {history} = this.props;

        const reset = {

            password,
            token,
            confirm_password: confirmpassword

        };


        this.props.resetPassword(reset, history);

    };

    submitForm(){

        const {password, confirmpassword} = this.state;

        return password.length > 0 && confirmpassword.length > 0;

    }

    render() {

        const { load, password, confirmpassword } = this.state;

        const isEnabled = this.submitForm();

    return (
        <div className="main-wrapper">
        <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
            <div className="login col-sm-12 col-lg-12 col-md-12">
                <div className="tizeti-logo">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png" alt="images"/>
                </div>
                <form className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12" onSubmit={this.onSubmit}>
                    <div className="form-group col-lg-9 col-sm-12">
                        <h6>Enter and confirm new password</h6>
                        <div className="mb-3">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Enter New Password"
                                name="password"
                                value={password}
                                onChange={this.onChange}
                            />
                        </div>

                        <div>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Confirm New Password"
                                name="confirmpassword"
                                value={confirmpassword}
                                onChange={this.onChange}
                            />
                        </div>
                        <br/>
                    </div>
                    <div className="wrapper-button mb-3">
                        { load ?
                            <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={true}><i className="fa fa-spinner"></i></button>
                            :
                            <button className="btn btn-green" disabled={!isEnabled} id="login" style={{backgroundColor: '#35BEAA', color:"white"}}>Reset Password</button>
                        }
                    </div>
                </form>
            </div>
        </div>
            <ToastContainer/>
        </div>
    );
  }
}

export default connect(null, {resetPassword})(withRouter(ResetConfirm));
