import {GET_ERRORS} from "../../actions/types/ErrorsTypes";
import {toast} from "react-toastify";

const initialState = {};

const ErrorsReducer = (state = initialState, action) => {
    // console.log(action);
    switch (action.type) {
        case GET_ERRORS:
            return action.payload;

        case 'invalid credentials':
            return toast.info("Invalid Email/Password!");
            
        default:
            return state;
    }


};

export default ErrorsReducer;