import React, {Component} from 'react';

class EditConference extends Component {

    constructor(props) {
        super(props);

        this.state = {

            isSubmitted: false,
            name: '',
            number: ''

        }

    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.one){

            this.setState({

                name: nextProps.one.name,
                number: nextProps.one.conference_number

            })

        }
    }


    render() {
        const {name, number} = this.state;

        return (
            <div className="modal-body">
                <form className="form-horizontal">
                    <div className="form-group">
                        <div className="col-12 d-flex mb-3 m-0 p-0">
                            <div className="col-6">
                                <label className="m-0 p-0">Conference Name</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Conference Name"
                                       value={name}
                                />
                            </div>
                            <div className="col-6">
                                <label className="m-0 p-0">Conference Number</label>
                                <input type="text" className="form-control"
                                       value={number}
                                       placeholder="Queue Number" readOnly />
                            </div>
                        </div>


                        <div className="col-12 text-center">
                            <button className="btn second-button text-white" disabled={true}>Update</button>
                        </div>
                    </div>
                </form>
            </div>

        );
    }
}

export default EditConference;