import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserAllocatedNumbers } from '../../../services/state-management/actions/phone/PhonesActions';

class PhoneTable extends Component {

    componentDidMount() {

        this.props.getUserAllocatedNumbers();

    }

    static propTypes = {
    getUserAllocatedNumbers: PropTypes.func.isRequired,
        assigned: PropTypes.object.isRequired

    };

    componentWillReceiveProps(nextProps) {

        if(nextProps.number.status === true){

            this.props.getUserAllocatedNumbers();

        }

    }

    render() {

        const {assigned, load} = this.props;

        const numberList = assigned.length ? (
            assigned.map(
            (number, i) => {
                return(
                    <tr className="copy-font table-row">
                        <th scope="row">{i + 1}</th>
                        <td>{number.did}</td>
                        <td>{number.category}</td>
                        <td className="text-center">{number.destination}</td>
                    </tr>
                )
            }
        )
    ) : (
        <p>No numbers</p>
    );

        return (
            <tbody>

            {load ? <small>loading...</small> : numberList}

            </tbody>
        );
    }
}

const mapStateToProps = state => ({

    assigned: state.numbers.numbers,
    number: state.number.number,
    load: state.loadPhones.loadPhones

});

export default connect(mapStateToProps, {getUserAllocatedNumbers})(PhoneTable);
