import React from 'react';
import MenuBar from '../../../layouts/MenuBar';
import Balance from './Balance';
import ButtonSet from '../../../layouts/ButtonSet';
import Files from './Files';
import AddFile from './AddFile';

const FilesPage = () => {
  return (
    <div className="body">
      <div className="container-fluid">
        <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
            alt="logo"
            width="12%"
            height="15%"
          />
        </div>
        <div className="row">
          <MenuBar />
        </div>
        <div className="container-fluid" id="resize-fluid">
          <div className="row">
            <div className="col-12 p-0 d-flex">
              <Balance />

              <div className="col-lg-2 mt-2 mb-3 p-0 col-4">
                <div className="d-flex button-section">
                  <ButtonSet />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-12 p-0 m-0 d-flex flex-wrap">
              <AddFile />

              <Files />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilesPage;
