import * as types from '../../actions/types/files/FilesTypes';

const initialState = {
  files: [],
  file: {},
  loading: false,
  uploadFileProgress: 0,
  load: false,
};

const FilesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.START_FILE_UPLOAD:
      return {
        ...state,
        load: true,
      };

    case types.STOP_FILE_UPLOAD:
      return {
        ...state,
        load: false,
        uploadFileProgress: 0
      };

    case types.GET_FILES:
      // console.log('reducer')
      return {
        ...state,
        files: payload,
        loading: false,
      };

    case types.DELETE_FILE:
      // const copyOfFiles = state.files.slice();

      return {
        ...state,
        // files
        file: payload,
      };
    // console.log(state.files);

    case types.UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        uploadFileProgress: action.payload,
      };

    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        file: payload,
        loading: false,
        load: false,
        uploadFileProgress: 0,
      };

    case types.LOADING_FILES:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default FilesReducer;
