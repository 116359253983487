/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

const WallboardMenuBar = () => {
  return (
    <div className="col-lg-12 mt-3" style={{ margin: '0', padding: '0' }}>
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand d-block d-sm-none" href="#">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1547554228/WifiCall_Logo_white-01.png"
            alt=""
            width="50%"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto dashboard-image">
            <li className="nav-item mr-3">
              <a className="nav-link" href="/wallboard">
                <span className="mr-2">
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1546522703/customerportal-wallboard.fw.png"
                    alt=""
                    className="span-img"
                  />
                </span>
                Wallboard
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default WallboardMenuBar;
