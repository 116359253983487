import axios from 'axios';
import { toast } from 'react-toastify';
import { ADD_QUEUE_URL, GET_QUEUES_URL } from '../../../apis/Queues/QueuesUrl';
import {
  CREATED_QUEUES,
  GET_QUEUES,
  START_ADDING_QUEUES,
  START_FETCHING_QUEUES,
  STOP_ADDING_QUEUES,
  STOP_FETCHING_QUEUES,
} from '../types/QueuesType';

export const getQueues = () => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCHING_QUEUES,
      payload: true,
    });

    try {
      const response = await axios.get(
        `${GET_QUEUES_URL}/${sessionStorage.getItem('cardid')}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      if (response.data.status === true) {
        dispatch({
          type: GET_QUEUES,
          payload: response.data.result,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Something went wrong');
        dispatch({
          type: STOP_FETCHING_QUEUES,
          payload: false,
        });
      }
    }
  };
};

export const addQueue = (data) => {
  return async (dispatch) => {
    dispatch({
      type: START_ADDING_QUEUES,
      payload: true,
    });

    try {
      const response = await axios.post(`${ADD_QUEUE_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status === true) {
        toast.success('Queues added successfully');

        dispatch(getQueues());
        dispatch({
          type: CREATED_QUEUES,
          payload: false,
        });
      } else {
        toast.info('Unable to add queues!');
        dispatch({
          type: STOP_ADDING_QUEUES,
          payload: false,
        });
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to add queues!');
        dispatch({
          type: STOP_ADDING_QUEUES,
          payload: false,
        });
      }
    }
  };
};
