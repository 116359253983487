import React, {Component} from 'react';
import axios from "axios";
import {CAMPAIGN_MEMBERS_URL} from "../../../../../services/apis/bulk-dialler/CampaignsUrl";
import {toast} from "react-toastify";
import {API_URL} from "../../../../config/BaseUrl";
import {fetchUser} from "../../../../../services/state-management/actions/auth/AuthActions";
import {connect} from "react-redux";

class CampaignGroupMembers extends Component {

    constructor(props) {
        super(props);

        this.state = {

            numbers: [],
            loading: false,
            attempt: false,
            connected: false

        }

    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.id){

            this.setState({

                loading: true

            });

            try {

                axios.get(`${CAMPAIGN_MEMBERS_URL}/${nextProps.id}`, {

                    headers: {

                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

                    }

                }).then(response => {

                    if(response.data.group_id){

                        this.setState({

                            numbers: response.data.group.bulkdialer_numbers,
                            loading: false

                        })

                    }

                })

            } catch (e) {


            }

        }

    }

    callNumber = async number => {


        const {phoneNumber} = this.props.user;


        if(phoneNumber === number.phonenumber ){

            toast.info("you can not call yourself!");

        } else {

            this.setState({

                attempt: true

            });

            const {connected} = this.state;

            if(connected){

                this.setState({

                    connected: false

                })

            }

            const data = {

                called: phoneNumber,
                calling: number.phonenumber,
                username: sessionStorage.getItem('username')

            };

            try {

                const response = await axios.post(`${API_URL}/makecall`, data, {

                    headers: {

                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`

                    }

                });

                if(response.data.status === true){

                    this.setState({

                        connected: true,
                        attempt: false,
                        disable: true,
                        phone: number.phone

                    })

                }

                if(response.data.status === false){

                    this.setState({

                        stat: true,
                        disable: false

                    });

                    toast.info("Call Failed")

                }

            } catch (error) {

                if(error){

                    this.setState({

                        disable: false

                    })

                }

            }

        }


    };

    componentDidMount() {

        this.props.fetchUser();

    }

    showCalling = value => {
        if(value){
            return <small>connecting</small>
        }
    };


    render() {

        const {name, id} = this.props;
        const {numbers, loading, attempt, phone} = this.state;

        const campaignMembers = numbers.length ? (
            numbers.map(
                (number, i) => (
                    <tr className="copy-font table-row">
                        <th scope="row">{i + 1}</th>
                        <td>{number.firstname}</td>
                        <td>{number.phonenumber}</td>
                        <td className="text-center">

                            {phone === number.phonenumber  ? this.showCalling(attempt) : null}
                            {phone === number.phonenumber }

                            {/*{phone === number.phoneNumber ? <img src="https://res.cloudinary.com/teewhy/image/upload/v1548069587/Call_Button.png"*/}
                            {/*                                     onClick={() => this.callNumber(number)}*/}
                            {/*                                     alt="call-button" className="w-25"/> : <small>connecying...</small>}*/}
                            {phone === number.phonenumber ? <small>conncted...</small> : <img src={attempt ? "https://res.cloudinary.com/teewhy/image/upload/v1551362876/connec.png" : "https://res.cloudinary.com/teewhy/image/upload/v1548069587/Call_Button.png" }
                                                                                              onClick={() => this.callNumber(number)}
                                                                                              alt="call-button" className="w-25"/> }
                        </td>
                    </tr>
                )
            )
        ): (
            <small>no numbers in this campaign!</small>
        );

        return (
            <div className="col-lg-4 col-12 p-0 mb-2">
                {name ? <h6><strong>Campaigns</strong>  <strong>{name}</strong></h6> : null}
                {id ? <div className="card shadow-sm">
                    <table className="table table-responsive">
                        <thead>
                        <tr className="copy-font">
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col" className="text-center">Click to call</th>
                        </tr>
                        </thead>

                        <tbody>

                        {loading ? <i className="fa fa-spinner fa-spin"></i> : campaignMembers }
                        </tbody>
                    </table>
                </div> : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({

    user: state.auth.auth

});

export default connect(mapStateToProps, {fetchUser})(CampaignGroupMembers);
