const initialState = {

    plans: []

};

const PlansReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PLANS':
            return {
                ...state,
                plans: action.payload
            };

        default:
            return state;

    }

};

export default PlansReducer;