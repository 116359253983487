import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getUserBalance} from "../../../services/state-management/actions/common/users/UsersActions";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";

class Balance extends Component {

    componentDidMount() {

        this.props.getUserBalance();
        this.props.fetchUser();

    }

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        wallet: PropTypes.string.isRequired

    };

    checkPlan = () => {

        const {plan} = this.props.user;
        const {wallet} = this.props;

        if(plan){
            switch (plan.name) {
                case "Basic unlimited plan":
                    return <span className="saving"><b>Unlimited</b></span>;

                default:
                    return <span className="saving">
                        <b>{typeof (wallet) === "string" ? Number(wallet).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) : null}</b>
                    </span>
            }

        } else return null

    };

    render() {

        return (
            <div className="wallet-balance">
                <div>
                    <div className="icon pr-2 d-flex p-0">
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png" alt="images" className="h-100 pr-2"/>
                        <span>
                            <h5 className="count mt-1">
                                {this.checkPlan()}
                                <br />
                                <span className="baby-font">Balance</span></h5></span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return{

        wallet: state.wallet.wallet,
        user: state.auth.auth

    }

};

export default connect(mapStateToProps, {getUserBalance, fetchUser})(Balance);