import React, { Component } from 'react';
import '../../../styles/style.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import MenuBar from '../../../layouts/MenuBar';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.min.css';
import IvrTable from './IvrTable';
import ButtonSet from '../../../layouts/ButtonSet';
import { getIVrs } from '../../../services/state-management/actions/common/ivr/IvrActions';
import { connect } from 'react-redux';
import { getUserBalance } from '../../../services/state-management/actions/common/users/UsersActions';
import { getFiles } from '../../../services/state-management/actions/files/FilesAction';
import { fetchUser } from '../../../services/state-management/actions/auth/AuthActions';
import Helper from '../../util/Helper';
import { getConferences } from '../../../services/state-management/actions/common/conferences/ConferencesActions';
import { API_URL } from '../../config/BaseUrl';
import { GET_QUEUES_URL } from '../../../services/apis/Queues/QueuesUrl';

class Ivr extends Component {

    constructor(props) {
        super(props);

        this.state = {

            wallet: [],
            email: "",
            amount: "",
            business: 15000,
            plan: '',
            cardid: "",
            recordings: [],
            name: '',
            record: '',
            description: '',
            ivr: [],
            conference: '',
            users: [],
            queues: [],
            categoryZero: [],
            categoryOne: [],
            categoryTwo: [],
            categoryThree: [],
            categoryFour: [],
            categoryFive: [],
            categorySix: [],
            categorySeven: [],
            categoryEight: [],
            categoryNine: [],
            categoryStar: [],
            categoryHash: [],
            valueZero: '',
            valueOne: '',
            valueTwo: '',
            valueThree: '',
            valueFour: '',
            valueFive: '',
            valueSix: '',
            valueSeven: '',
            valueEight: '',
            valueNine: '',
            valueHash: '',
            valueStar: '',
            digit: '',
            showConfigure: false,
            ivrId: {},
            workgroup: [],
            ivrMembers: []


        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.createIvr = this.createIvr.bind(this);
        this.showConfigures = this.showConfigures.bind(this);
        this.showAddIvrForm = this.showAddIvrForm.bind(this);

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });


    showConfigures(iv){

        this.setState({

            showConfigure: !this.state.showConfigure,
            ivrId: iv

        });

    }

    showAddIvrForm(){

        this.setState({

            showAdd: !this.state.showAdd
        })
    }

    componentDidMount() {

        this.props.getFiles();
        this.getUsers();
        this.props.getConferences();
        this.getQueues();
        this.props.getIVrs();
        this.props.getUserBalance();
        this.props.fetchUser();

    }

    static propTypes = {

        getUserBalance: PropTypes.func.isRequired,
        fetchUser: PropTypes.func.isRequired,
        getIVrs: PropTypes.func.isRequired,
        getFiles: PropTypes.func.isRequired

    };

    /*callDialPad(){

        var x = document.getElementById("myDialPad");
        if (x.style.display === "none") {
            x.style.display = "block";
        }

    }*/

    onSubmit(e){

        e.preventDefault();

        const { name, description, record } = this.state;

        const data = {

            name: name,
            description: description,
            record: record

        };

        let ivr = [...this.state.ivr, data];

        this.setState({

            ivr,
            name: '',
            description: ''

        });

    }

    getQueues(){

        axios({

            method: 'get',
            url: `${GET_QUEUES_URL}/${sessionStorage.getItem('cardid')}`,
            headers: {

                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }

        }).then(res => {

            this.setState({

                queues: res.data.result

            })

        })

    }

    fixedDigit = (digit) => {

        this.setState({

            digit

        })

    };

    getUsers(){

        axios({

            method: 'get',
            url: `${API_URL}/wificall/getWorkgroupList`,
            headers: {

                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }

        }).then(res => {

            this.setState({

                users: res.data.result

            });

        })

    }

    createIvr(e){

        e.preventDefault();

        const data = {

            name: this.state.ivrId.name,
            description: this.state.ivrId.description,
            card_id: sessionStorage.getItem('cardid'),
            workgroup_name: sessionStorage.getItem('workgroup'),
            recording: this.state.ivrId.record,
            ivr_members: this.state.ivrMembers

        };

        axios({

            method: 'post',
            url: `${API_URL}/ivr/addIvr`,
            data: data

        }).then(res => {

            if(res.data.status === true){

                toast.success('Ivr Created Successfully!');
                this.props.getIVrs();

            }

        }).catch(() =>{})

    }

    displayDestinationZero(){

        if(this.state.categoryZero === "queue"){

            return(
              <select className="form-control shadow-sm"
                      name="valueZero"
                      onChange={(e) => {
                          this.onChange(e);
                          this.state.ivrMembers.push({
                              "category": this.state.categoryZero,
                              "digit": "0",
                              "destination": e.target.value
                          })
                      }
                      }>
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(q => {
                      return(
                        <option value={q.queue_number}>{q.name}</option>
                      )
                  })}
              </select>
            )

        } else if( this.state.categoryZero === "user") {

            return (
              <select className="form-control shadow-sm" name="valueZero" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryZero,
                          "digit": "0",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryZero === "conference"){

            return (
              <select className="form-control shadow-sm"
                      name="valueZero"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryZero,
                                  "digit": "0",
                                  "destination": e.target.value
                              })
                          }
                      }>

                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryZero === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueZero" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryZero,
                          "digit": "0",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationOne(){

        if(this.state.categoryOne === "queue"){

            return(
              <select name="valueOne"
                      className="form-control shadow-sm"
                      onChange={(e) => {
                          this.onChange(e);
                          this.state.ivrMembers.push({
                              "category": this.state.categoryOne,
                              "digit": "1",
                              "destination": e.target.value
                          })
                      }
                      }>
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryOne === "user") {
            return (
              <select className="form-control shadow-sm" name="valueOne" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryOne,
                          "digit": "1",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryOne === "conference"){
            return (
              <select className="form-control shadow-sm"
                      name="valueOne"
                      onChange={(e) => {
                          this.onChange(e);
                          this.state.ivrMembers.push({
                              "category": this.state.categoryOne,
                              "digit": "1",
                              "destination": e.target.value
                          })
                      }
                      }>
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryOne === "ivr"){
            return (
              <select className="form-control shadow-sm"
                      name="valueOne"
                      onChange={(e) => {
                          this.onChange(e);
                          this.state.ivrMembers.push({
                              "category": this.state.categoryOne,
                              "digit": "1",
                              "destination": e.target.value
                          })
                      }
                      }
              >
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}
              </select>
            )
        }

    }

    displayDestinationTwo(){

        if(this.state.categoryTwo === "queue"){

            return(
              <select name="valueTwo" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryTwo,
                          "digit": "2",
                          "destination": e.target.value
                      })
                  }
              } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryTwo === "user") {

            return (
              <select className="form-control shadow-sm" name="valueTwo" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryTwo,
                          "digit": "2",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryTwo === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueTwo" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryTwo,
                          "digit": "2",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryTwo === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueTwo" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryTwo,
                          "digit": "2",
                          "destination": e.target.value
                      })
                  }
              }
              >
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationThree(){

        if(this.state.categoryThree === "queue"){

            return(
              <select name="valueThree" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryThree,
                          "digit": "3",
                          "destination": e.target.value
                      })
                  }
              } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryThree === "user") {

            return (
              <select className="form-control shadow-sm" name="valueThree" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryThree,
                          "digit": "3",
                          "destination": e.target.value
                      })
                  }
              }
              >
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryThree === "conference"){

            return (
              <select className="form-control shadow-sm"
                      name="valueThree"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryThree,
                                  "digit": "3",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryThree === "ivr"){

            return (
              <select className="form-control shadow-sm"
                      name="valueThree"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryThree,
                                  "digit": "3",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}
              </select>
            )
        }

    }

    displayDestinationFour(){

        if(this.state.categoryFour === "queue"){

            return(
              <select multiple={true} name="valueFour"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryFour,
                                  "digit": "4",
                                  "destination": e.target.value
                              })
                          }
                      } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryFour === "user") {

            return (
              <select className="form-control shadow-sm" name="valueFour" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryFour,
                          "digit": "4",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryFour === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueFour"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryFour,
                                  "digit": "4",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryFour === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueFour" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryFour,
                          "digit": "4",
                          "destination": e.target.value
                      })
                  }
              }
              >
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationFive(){

        if(this.state.categoryFive === "queue"){

            return(
              <select name="valueFive" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryFive,
                          "digit": "5",
                          "destination": e.target.value
                      })
                  }
              } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryFive === "user") {

            return (
              <select className="form-control shadow-sm" name="valueFive"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryFive,
                                  "digit": "5",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryFive === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueFive"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryFive,
                                  "digit": "5",
                                  "destination": e.target.value
                              })
                          }
                      }>
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryFive === "ivr"){

            return (
              <select className="form-control shadow-sm"
                      name="valueFive"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryFive,
                                  "digit": "5",
                                  "destination": e.target.value
                              })
                          }
                      }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationSix(){

        if(this.state.categorySix === "queue"){

            return(
              <select name="valueSix" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySix,
                          "digit": "6",
                          "destination": e.target.value
                      })
                  }
              } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categorySix === "user") {

            return (
              <select className="form-control shadow-sm" name="valueSix" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySix,
                          "digit": "6",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.firstname} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categorySix === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueSix" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySix,
                          "digit": "6",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categorySix === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueSix" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySix,
                          "digit": "6",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationSeven(){

        if(this.state.categorySeven === "queue"){

            return(
              <select name="valueSeven"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categorySeven,
                                  "digit": "7",
                                  "destination": e.target.value
                              })
                          }
                      } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categorySeven === "user") {

            return (
              <select className="form-control shadow-sm" name="valueSeven" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySeven,
                          "digit": "7",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categorySeven === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueSeven" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySeven,
                          "digit": "7",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categorySeven === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueSeven" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categorySeven,
                          "digit": "7",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }


    }

    displayDestinationEight(){

        if(this.state.categoryEight === "queue"){

            return(
              <select name="valueEight" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryEight,
                          "digit": "8",
                          "destination": e.target.value
                      })
                  }
              } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryEight === "user") {

            return (
              <select className="form-control shadow-sm" name="valueEight"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryEight,
                                  "digit": "8",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryEight === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueEight"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryEight,
                                  "digit": "8",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryEight === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueEight" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryEight,
                          "digit": "8",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationNine(){

        if(this.state.categoryNine === "queue"){

            return(
              <select name="valueNine" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryNine,
                          "digit": "9",
                          "destination": e.target.value
                      })
                  }
              } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryNine === "user") {

            return (
              <select className="form-control shadow-sm"
                      name="valueNine"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryNine,
                                  "digit": "9",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryNine === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueNine"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryNine,
                                  "digit": "9",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryNine === "ivr"){

            return (
              <select className="form-control shadow-sm" name="valueNine" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryNine,
                          "digit": "9",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationStar(){

        if(this.state.categoryStar === "queue"){

            return(
              <select name="valueStar"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryStar,
                                  "digit": "*",
                                  "destination": e.target.value
                              })
                          }
                      } className="form-control shadow-sm">
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryStar === "user") {

            return (
              <select className="form-control shadow-sm"
                      name="valueStar"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryStar,
                                  "digit": "*",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryStar === "conference"){

            return (
              <select className="form-control shadow-sm" name="valueStar" onChange={
                  (e) => {
                      this.onChange(e);
                      this.state.ivrMembers.push({
                          "category": this.state.categoryStar,
                          "digit": "*",
                          "destination": e.target.value
                      })
                  }
              }>
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryStar === "ivr"){

            return (
              <select className="form-control shadow-sm"
                      name="valueStar"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryStar,
                                  "digit": "*",
                                  "destination": e.target.value
                              })
                          }
                      }>
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    displayDestinationHash(){

        if(this.state.categoryHash === "queue"){

            return(
              <select className="form-control shadow-sm"
                      name="valueHash"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryHash,
                                  "digit": "#",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Queue</option>
                  {this.state.queues.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>

                    }
                  )}

              </select>

            )

        } else if( this.state.categoryHash === "user") {

            return (
              <select className="form-control shadow-sm"
                      name="valueHash"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryHash,
                                  "digit": "#",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose User</option>
                  {this.state.users.map(
                    (u, i) => {
                        return <option value={u.username}>{u.first} {u.lastname}</option>
                    }
                  )}

              </select>
            )
        } else if(this.state.categoryHash === "conference"){

            return (
              <select className="form-control shadow-sm"
                      name="valueHash"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryHash,
                                  "digit": "#",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Conference</option>
                  {this.state.conferences.map(
                    (co, i) => {
                        return <option value={co.conference_number}>{co.name}</option>
                    }
                  )}

              </select>
            )

        } else if(this.state.categoryHash === "ivr"){

            return (
              <select className="form-control shadow-sm"
                      name="valueHash"
                      onChange={
                          (e) => {
                              this.onChange(e);
                              this.state.ivrMembers.push({
                                  "category": this.state.categoryHash,
                                  "digit": "#",
                                  "destination": e.target.value
                              })
                          }
                      }
              >
                  <option value="">Choose Ivr</option>
                  {this.state.ivr.map(
                    (qu, i) => {
                        return <option value={qu.queue_number}>{qu.name}</option>
                    }
                  )}

              </select>
            )
        }

    }

    checkPlan = () => {

        const {wallet, user} = this.props;
        const {plan} = user;

        if(plan){
            switch (plan.name) {
                case "Basic unlimited plan":
                    return "Unlimited";

                default:
                    return <b>{typeof (wallet) == "string" ? Helper.formatCurrency(wallet) : null}</b>

            }

        } else return null

    };

    render() {

        const { name, ivr } = this.state;
        const {ivrs, loading, files, 
            // conferences
        } = this.props;

        // const digit0 = "0";
        const digit1 = "1";
        const digit2 = "2";
        const digit3 = "3";
        const digit4 = "4";
        const digit5 = "5";
        const digit6 = "6";
        const digit7 = "7";
        const digit8 = "8";
        const digit9 = "9";
        const digitStar = '*';
        const digitHash = "#";

        const ivrList = ivr.length ? (
          ivr.map(
            (iv, i) => {
                return (
                  <tr className="table-row" data-href="#">
                      <th scope="row">{i + 1}</th>
                      <td>{iv.name}</td>
                      <td>{iv.description}</td>
                      <td className="text-center" onClick={() => this.showConfigures(iv)}><img src="https://res.cloudinary.com/teewhy/image/upload/v1548183654/setting.fw.png" alt="config-icon" style={{width:"35%"}}/></td>
                      <td>
                          <div className="d-flex">
                              <div className="mr-2" data-target="#editqueue" data-toggle="modal">
                                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953182/pencil.fw.png" alt=''/>
                              </div>
                              <div className="mr-2 text-center">
                                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png" alt=''/>
                              </div>
                          </div>
                      </td>
                  </tr>
                )
            }
          )
        ) : (

          <p>Please add an ivr</p>

        );

        return (
          <div className="body">
              <div className="container-fluid">
                  <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
                      <img src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png" alt="logo" width="12%" height="15%"/>
                  </div>
                  <div className="row">
                      <MenuBar/>
                  </div>
                  <div className="container-fluid" id="resize-fluid">
                      <div className="row">
                          <div className="col-12 d-flex p-0">
                              <div className="col-lg-4 mr-auto mt-4 mb-3 p-0" onClick={() => this.showAddIvrForm()}>
                                  <button className="btn second-button text-white pr-4 pl-4" data-target="#addivr" data-toggle="modal">Add IVR</button>
                              </div>
                              {/*<AddIvr />*/}
                              <div  className="modal fade in" id="addivr" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div className="modal-dialog" role="document">
                                      <div className="modal-content">
                                          <div className="modal-header">
                                              <div className="d-flex col-11" style={{justifyContent:"center"}}>
                                                  <h4 className="modal-title">Add IVR</h4>
                                              </div>
                                              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                          </div>

                                          <div className="modal-body">
                                              <form className="form-horizontal" onSubmit={this.onSubmit}>
                                                  <div className="form-group">
                                                      <div className="col-12 d-flex mb-3 m-0 p-0">
                                                          <div className="col-6 p-0">
                                                              <label className="m-0 p-0">IVR Name</label>
                                                              <input type="text"
                                                                     name="name"
                                                                     onChange={this.onChange}
                                                                     value={name}
                                                                     className="form-control"
                                                                     placeholder="IVR Name"
                                                                     required={true}
                                                              />
                                                          </div>
                                                          <div className="col-6 ml-2 p-0">
                                                              <label className="m-0 p-0">Description</label>
                                                              <input type="text"
                                                                     className="form-control"
                                                                     name="description"
                                                                     value={this.state.description}
                                                                     onChange={this.onChange}
                                                                     placeholder="IVR Description"
                                                                     required={true}
                                                              />
                                                          </div>
                                                      </div>

                                                      <div className="col-12 col-lg-12 mb-3">
                                                          <form className="form-horizontal">
                                                              <div className="form-group">
                                                                  <div className="col-12 m-0 p-0">
                                                                      <label className="m-0 p-0">Select Recording</label>
                                                                      {files.length > 0 ? <select className="form-control" onChange={this.onChange} name="record" required={true}>
                                                                          <option>Select Recording</option>
                                                                          {files.map(
                                                                            (rec) => {
                                                                                return <option value={rec.filebucket_number}>{rec.name}</option>

                                                                            }
                                                                          )}
                                                                      </select> : <small className="form-control">You have no files uploaded. Please add some to create an ivr</small> }
                                                                  </div>
                                                              </div>
                                                          </form>
                                                      </div>

                                                      {files.length > 0 ? <div className="text-center">
                                                          <button className="btn second-button text-white" onClick={this.callDialPad}>Add IVR</button>
                                                      </div> : <div className="text-center">
                                                          <button className="btn second-button text-white" disabled={true}>Add IVR</button>
                                                      </div>}
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="wallet-balance  d-none d-sm-none d-md-block d-lg-inline-flex ml-auto mr-auto mt-4 mb-3 p-0">
                                  <div className="icon pr-2">
                                      <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-balance.fw.png" alt="images"/>
                                  </div>
                                  <div>
                                      <h5 className="count mt-1">
                                    <span className="saving">
                                        {this.checkPlan()}
                                    </span>
                                          <br />
                                          <span className="baby-font">Wallet Balance</span></h5>
                                  </div>
                              </div>
                              <div className="mt-4 mb-3">
                                  <div className="d-flex button-section">
                                      <ButtonSet/>
                                  </div>
                              </div>
                          </div>

                          <div className="col-12 d-flex p-0 m-0 flex-wrap">
                              {/*<IvrList/>*/}

                              <div className="mr-2 col-lg-4 col-12 m-0 p-0">

                                  <div className="result-table col-12 col-lg-12 mt-4">
                                      <div className="card o-income card-body">
                                          <div style={{overflowX:"auto"}}>
                                              <table className="table copy-font">
                                                  <thead>
                                                  <tr>
                                                      <th scope="col">#</th>
                                                      <th scope="col">IVR Name</th>
                                                      <th scope="col">Description</th>
                                                      <th scope="col">Configure</th>
                                                      <th scope="col">Actions</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {ivrList}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </div>

                                  <h6><strong>IVR List</strong></h6>
                                  <IvrTable ivrs={ivrs} load={loading}/>
                              </div>



                              {/*<DialPad />*/}

                              {this.state.showConfigure ? <div className="mr-2 m-0 p-0 col-lg-7 col-12">
                                  <h6><strong>Keypad Selections</strong><br />
                                      <span><small>(Digits the caller needs to dail to access said destination)</small></span>
                                  </h6>
                                  <div className="card o-income card-body">
                                      <div className="col-12 d-flex m-0 p-0 d-flex" id="dialpad">
                                          <div className="col-12" id="lot-form">
                                              <form className="form-horizontal" onSubmit={this.createIvr}>
                                                  <input type="hidden" placeholder="h"/>
                                                  <div className="form-group">
                                                      <div className="col-12 mb-3 m-0 p-0 d-flex">
                                                          <div className="col-1 m-0 p-0">
                                                              <h6 className="text-center"><strong>Digits</strong></h6>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit1" onClick={() => this.fixedDigit(digit1)} onChange={this.onChange}> {digit1} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit2" onClick={() => this.fixedDigit(digit2)} onChange={this.onChange}> {digit2} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit3" onClick={() => this.fixedDigit(digit3)} onChange={this.onChange}> {digit3} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit4" onClick={() => this.fixedDigit(digit4)} onChange={this.onChange}> {digit4} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit5" onClick={() => this.fixedDigit(digit5)} onChange={this.onChange}> {digit5} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit6" onClick={() => this.fixedDigit(digit6)} onChange={this.onChange}> {digit6} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit7" onClick={() => this.fixedDigit(digit7)} onChange={this.onChange}> {digit7} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit8" onClick={() => this.fixedDigit(digit8)} onChange={this.onChange}> {digit8} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digit9" onClick={() => this.fixedDigit(digit9)} onChange={this.onChange}> {digit9} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digitHash" onClick={() => this.fixedDigit(digitStar)} onChange={this.onChange}> {digitStar} </button>
                                                              </div>

                                                              <div className="digits col-3 p-1 mb-3">
                                                                  <button className="btn pl-3 pr-3" type="button" name="digitStar" onClick={() => this.fixedDigit(digitStar)} onChange={this.onChange}> {digitHash} </button>
                                                              </div>

                                                          </div>

                                                          <div className="col-11 p-0">
                                                              <h6><strong className="ml-4">Category</strong></h6>
                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select  className="form-control shadow-sm"
                                                                               name="categoryOne"
                                                                               onChange={
                                                                                   this.onChange
                                                                               }
                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="user">User</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationOne()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              name="categoryTwo"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="ivr">Ivr</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="user">User</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationTwo()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              name="categoryThree"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationThree()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm" onChange={
                                                                          this.onChange
                                                                      }
                                                                              name="categoryFour">
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationFour()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                              name="categoryFive">
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationFive()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                              name="categorySix">
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationSix()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                              name="categorySeven">
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationSeven()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              name="categoryEight"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationEight()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              name="categoryNine"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationNine()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">
                                                                      <select className="form-control shadow-sm"
                                                                              name="categoryStar"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }
                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">

                                                                      {this.displayDestinationStar()}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 d-flex mb-3">
                                                                  <div className="col-6 p-1">

                                                                      <select className="form-control shadow-sm"
                                                                              name="categoryHash"
                                                                              onChange={
                                                                                  this.onChange
                                                                              }

                                                                      >
                                                                          <option>Choose One</option>
                                                                          <option value="user">User</option>
                                                                          <option value="queue">Queue</option>
                                                                          <option value="conference">Conference</option>
                                                                          <option value="ivr">Ivr</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="col-6 p-1">
                                                                      {this.displayDestinationHash()}
                                                                      {/*{this.state.categoryHash === "conference" ? <select className="form-control shadow-sm" name="valueHash" onChange={this.onChange}>
                                                                        {this.state.conferences.map(
                                                                            (co, i) => {
                                                                                return <option value={co.conference_number}>{co.name}</option>
                                                                            }
                                                                        )}

                                                                    </select> : null }
                                                                    {this.state.categoryHash === "queue" ? <select name="valueHash" onChange={this.onChange} className="form-control shadow-sm">
                                                                        {this.state.queues.map(
                                                                            (qu, i) => {
                                                                                return <option value={qu.queue_number}>{qu.name}</option>

                                                                            }
                                                                        )}

                                                                    </select> : null }
                                                                    {this.state.categoryHash === "user" ? <select className="form-control shadow-sm" name="valueHash" onChange={this.onChange}>
                                                                        {this.state.users.map(
                                                                            (u, i) => {
                                                                                return <option value={u.username}>{u.first} {u.lastname}</option>
                                                                            }
                                                                        )}

                                                                    </select> : null }
                                                                    {this.state.categoryHash === "ivr" ? <select className="form-control shadow-sm" name="valueHash" onChange={this.onChange}>
                                                                        {this.state.ivr.map(
                                                                            (qu, i) => {
                                                                                return <option value={qu.queue_number}>{qu.name}</option>
                                                                            }
                                                                        )}

                                                                    </select> : null }*/}

                                                                  </div>
                                                              </div>

                                                              <div className="col-12 text-center m-0 p-0">
                                                                  <button className="col-6 btn second-button text-white">Save</button>
                                                              </div>
                                                          </div>


                                                      </div>


                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div> : null}

                              {/* <AddQueue/>
                            <QueueTable/>
                            <QueueMembers/> */}
                          </div>
                      </div>
                  </div>
              </div>
              <ToastContainer/>
          </div>

        )
    }
}

const mapStateToProps = (state) => {
  return {
    ivrs: state.ivrs.ivrs,
    loading: state.ivrs.loading,
    files: state.files.files,
    wallet: state.wallet.wallet,
    user: state.auth.auth,
  };
};
export default connect(
  mapStateToProps,
  { getIVrs, getUserBalance, getFiles, fetchUser, getConferences },
)(Ivr);
