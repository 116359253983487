/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './payg.css';
import { toast } from 'react-toastify';
import usePayg from '../../../hooks/payment/payg/usePayg';
const Payg = () => {
  const [amount, setAmount] = useState('');
  const { lessThanTwoFive, moreThanTwoFive } = usePayg();
  const payForPlan = () => {
    if (amount < 200) {
      toast.info('You can not pay less than 200 naira');
    } else if (amount < 2500) {
      lessThanTwoFive(amount * 100);
    } else {
      moreThanTwoFive(amount * 100);
    }
  };
  return (
    <div className="col">
      <div
        className="card mx-5 my-3"
        style={{
          border: '3px solid #aeb0b0',
          color: 'blue',
        }}
      >
        <div className="card-body text-center p-0">
          <div
            style={{
              color: '#0f676b',
              textDecorationLine: 'none',
            }}
          >
            <div className="information">
              <small className="mx-auto fontWeight900">Pay As You Go</small>

              <small className="fontWeight500">
                {' '}
                Enter and pay any amount here
              </small>
            </div>
            <div className="inputs">
              <input
                type="text"
                className="payg-input"
                placeholder="Enter Amount"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <button
                onClick={() => payForPlan()}
                className="btn btn-green text-white"
              >
                Pay
              </button>
            </div>
            {/* <small className="mx-auto fontWeight500"> */}
            {/*  <div> */}
            {/*    Number Search Engine */}
            {/*    <br /> */}
            {/*  </div> */}
            {/*  <div>One User</div> */}
            {/*  <br /> */}
            {/* </small> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payg;
