import React from 'react';

const Loading = () => {
  return <img
      src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif"
      alt="loading gif"
    />

};

export default Loading;
