import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAssignedPhoneNumber } from '../../../services/state-management/actions/common/users/UsersActions';

class AssignedNumber extends Component {
  componentDidMount() {
    this.props.getAssignedPhoneNumber();
  }

  render() {
    const { assignedNumber } = this.props;

    return (
      <div className="col-md-4 col-12 mb-3">
        <div className="boxes d-flex">
          <div className="icon pr-2">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/c_scale,w_40/v1554389639/PhoneNumberee.png"
              alt=""
            />
          </div>
          <div>
            {typeof assignedNumber !== 'object' ? (
              <h5 className="mb-0">
                <strong>{assignedNumber}</strong>
              </h5>
            ) : null}
            <small className="mt-0">
              <strong>My Phone Number</strong>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assignedNumber: state.assignedNumber.number,
  };
};

export default connect(
  mapStateToProps,
  { getAssignedPhoneNumber },
)(AssignedNumber);
