import axios from 'axios';
import { toast } from 'react-toastify';
import * as url from '../../../../apis/conferences/ConferenceUrl';
import * as types from './ConferencesTypes';

export const getConferences = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${url.GET_CONFERENCES_URL}/${sessionStorage.getItem('cardid')}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );
    if (response.data.status === true) {
      dispatch({
        type: types.GET_CONFERENCES,
        payload: response.data.result,
      });
    } else {
      toast.info(
        `cannot retrieve ${sessionStorage.getItem('firstname')}s conferences`,
      );
    }
  } catch (e) {console.log(e)}
};

export const addConference = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${url.ADD_CONFERENCE_URL}`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.data.status === true) {
      dispatch({
        type: types.ADD_CONFERENCE,
        payload: {
          response: response.data.result,
          loading: false,
        },
      });

      toast.success('Conference Added Successfully!');
    } else {
      toast.info('Something went wrong!');
      dispatch({
        type: types.STOP_ADDING_CONFERENCE,
      });
    }
  } catch (e) {
    if (e) {
      toast.info('Something went wrong. Please try again later!');
      this.setState({
        loading: false,
      });
    }
  }
};
