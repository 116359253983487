import {
    CREATED_QUEUES,
    GET_QUEUES,
    START_ADDING_QUEUES,
    START_FETCHING_QUEUES,
    STOP_FETCHING_QUEUES
} from "../../actions/types/QueuesType";

const initialState = {

    queues: [],
    loadQueues: false,
    loadForm: false

};

export const QueuesReducer = (state=initialState, action) => {
    const {payload} = action;

    switch (action.type) {
        case GET_QUEUES:
            return {

                ...state,
                queues: action.payload,
                loadQueues: false

        };

        case START_ADDING_QUEUES:
            return {

                ...state,
                loadForm: payload

            };

        case CREATED_QUEUES:
            return {

                ...state,
                load: payload,
                loadForm: payload

            };

        case START_FETCHING_QUEUES:
            return {

                ...state,
                loadQueues: payload

            };

        case STOP_FETCHING_QUEUES:
            return {

                ...state,
                loadQueues: payload

            };

        default:
            return state;

    }

};

export default QueuesReducer;