import axios from 'axios';
import {
  GET_USER_BY_ID_URL_FOR_VERIFICATION,
  GET_USER_BY_USERNAME_URL,
} from '../../../apis/verify/VerifyUrl';
import { CHECK_VERIFICATION_STATUS } from '../types/user/verify/VerifyTypes';

export const checkVerificationStatus = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${GET_USER_BY_USERNAME_URL}/${sessionStorage.getItem('username')}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (response.data.wificall_user_id) {
      try {
        const res = await axios.get(
          `${GET_USER_BY_ID_URL_FOR_VERIFICATION}/${response.data.wificall_user_id}`,
        );

        if (res.data) {
          dispatch({
            type: CHECK_VERIFICATION_STATUS,
            payload: res.data.verification_status,
          });
        }
      } catch (e) {}
    }
  } catch (e) {}
};
