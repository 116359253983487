/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import MenuBar from '../../../layouts/MenuBar';
import Balance from './Balance';
import ButtonSet from '../../../layouts/ButtonSet';

const Developers = () => {
  return (
    <div className="body">
      <div className="container-fluid">
        <div className="col-lg-12 col-12 dashboard-image mt-4 d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
            alt="logo"
            width="12%"
            height="15%"
          />
        </div>
        <div className="row">
          <MenuBar />
        </div>

        <div className="container p-0">
          <div className="row wallboard-table">
            <div className="col-lg-12 col-12 mt-4">
              <div className="d-flex justify-content-end">
                <Balance />

                <div className="col-lg-3">
                  <div className="d-flex button-section">
                    <ButtonSet />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <h5>
              <strong>My Developer Page</strong>
            </h5>
            <div className="mt-3 col-lg-12 col-12">
              <div className="col-lg-12 col-12 shadow bg-white">
                <div className="d-flex p-5">
                  <label className="m-0 p-0">Token:</label>
                  <div className="col-11 ml-3 p-0 wrapping-word">
                    <p style={{ lineHeight: '1.7', fontSize: '14px' }}>
                      {sessionStorage.getItem('token')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h6>
                Get started with our{' '}
                <strong>
                  <a
                    href="https://wificall.ng/documentation.html"
                    target="_blank"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    API Documentation
                  </a>
                </strong>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Developers;
