import {
  GET_USERS_IN_WORKGROUP,
  GET_WORKGROUPS,
  START_GETTING_USERS_IN_WORKGROUP,
  STOP_GETTING_USERS_IN_WORKGROUP,
} from '../../actions/types/workgroup/WorkgroupTypes';
import {
  LOADING_STARTS,
  LOADING_STOPS,
} from '../../actions/types/loading/LoadingTypes';

const initialState = {
  workgroups: [],
  load: false,
  users: [],
  loadUsers: false,
  loading: false,
  subscribers: [],
};

export const WorkgroupReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_WORKGROUPS:
      return {
        ...state,
        workgroups: payload,
        load: false,
      };

    case LOADING_STOPS:
      return {
        ...state,
        load: payload,
      };

    case LOADING_STARTS:
      return {
        ...state,
        load: payload,
      };

    case START_GETTING_USERS_IN_WORKGROUP:
      return {
        ...state,
        loadUsers: payload,
        loading: true,
      };

    case GET_USERS_IN_WORKGROUP:
      return {
        ...state,
        users: payload,
        loadUsers: false,
        loading: false,
        subscribers: payload,
      };

    case STOP_GETTING_USERS_IN_WORKGROUP:
      return {
        ...state,
        loadUsers: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default WorkgroupReducer;
