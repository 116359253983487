import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {uploadIDVerification} from "../../../services/state-management/actions/common/user/verification/VerificationActions";

class UploadIdentityDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            files: null,
            load: false,
            type: null,
            preview: '',
            view: null,
            loaded: 0,
            identity: ''

        };

    }

    static propTypes = {

        user: PropTypes.object.isRequired,

    };

    submitFile(){

        const {files, identity} = this.state;

        return (files !== null && identity.length > 0)

    }

    uploadIdentity = () => {

        this.setState({

            load: true

        });

        const{files, type, identity} = this.state;


        if(type === "audio/wav"){

            toast.info("Please a non-audio file");
            this.setState({

                load: false

            })

        } else {

            const data = new FormData();

            data.append("card_id", sessionStorage.getItem('cardid'));
            data.append("workgroup_name", sessionStorage.getItem('workgroup'));
            data.append("file", files);
            data.append("id_verification_type", identity);

            this.props.uploadIDVerification(data);

        }

    };

    fileSelected = e => this.setState({

            files: e.target.files[0],
            type: e.target.files[0].type,
            name: e.target.files[0].name,
            view: URL.createObjectURL(e.target.files[0])

    });

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.progress){

            this.setState({

                loaded: nextProps.progress

            })
        }

        if(nextProps.file === "uploaded identity"){

            this.setState({

                load: false,
                type: null,
                name: null,
                view: null,
                loaded: 0,
                identity: ''

            })

        }

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    render() {

        const isEnabled = this.submitFile();
        const {load, name, view, identity} = this.state;

        return (
            <div className="tab-pane container active col-md-12 p-0" id="homePlan">
                <div className="col-md-8 d-flex p-0">
                    <div className="col-md-5 p-0">
                        <h6>Select identity file to upload</h6>
                        <div className="mb-3">
                            <label className="m-0 p-0" htmlFor="">Identity Type</label>
                            <input type="text" className="form-control identity-type" name="identity" value={identity} onChange={this.onChange} placeholder="e.g voters card"/>
                        </div>
                        <div className="card p-4 mr-5 text-center card-verify">
                            <span className="fas fa-file-upload"
                                  onClick={()=> this.fileInput.click()}
                                  style={{cursor: 'pointer'}}>
                            </span>
                            <input required={true} type="file" style={{ display: 'none'}} ref={fileInput => this.fileInput = fileInput} onChange={this.fileSelected} className="form-control mt-2 p-2" />

                            <p>Click to select document image (jpg or pdf formats only)</p>
                        </div>
                    </div>

                    <div className="col-md-5 p-0">
                        <h6>Document preview</h6>
                        <div className="card p-4 text-center card-verify">
                            <span className="far fa-file-image"></span>
                            {name ? <img src={view} alt="view" className="w-100"/> : null}
                            {name ? name : <p>Document Preview</p>}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 text-center mt-5">
                <div className="progress-bar" style={{width:'100%'}} role="progressbar" value={this.state.loaded}>
                        <span>
                            {this.state.loaded ? Math.round(this.state.loaded,2) + '%'  : null }
                        </span>
                </div>
                </div>

                <div className="col-md-6 text-center mt-5" onClick={this.uploadIdentity}>
                    {load ?  <button className="btn second-button text-white" disabled={true}><i className="fa fa-spinner fa-spin"></i></button>
                    : <button className="btn second-button text-white" disabled={!isEnabled}>Upload Identity Document</button>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    user: state.loggedInUser.user,
    file: state.identityUpload.identityUpload,
    progress: state.uploadIdentityProgress.uploadIdentityProgress

});

export default connect(mapStateToProps, {uploadIDVerification})(UploadIdentityDocument);