import { useEffect, useState } from 'react';
import axios from 'axios';

const useForm = (url: string) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        return await axios.get(url);
      } catch (e) {
        return e;
      }
    }

    getData()
      .then((response) => {
        setLoading(false);
        // @ts-ignore
        setData(response.data);
      })
      .catch(() => setLoading(true));
  }, [url]);

  return { data, loading };
};
export default useForm;
