import React from 'react';

const FailedPayments = () => {
    return (
        <div className="bg-white w-100 h-100 payment col-12 p-0">
            <div className="payment-content shadow h-50 mr-auto ml-auto col-12 col-lg-4 p-0">
                <div className="text-center payment-holder">
                    <div>
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1556617349/Failed.png" alt="check" className="payment-check"/></div>
                    <h5><strong>Sorry, Payment was unsuccessful</strong></h5>
                </div>
            </div>
        </div>
    );
};

export default FailedPayments;