import React, {Component} from 'react';
import {ToastContainer} from "react-toastify";
import {enterCode} from "../../../services/state-management/actions/auth/AuthActions";
import {connect} from "react-redux";
import { withRouter} from "react-router-dom";

class EnterCode extends Component {

    constructor(props) {
        super(props);

        this.state = {

            code: '',
            load: false

        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    onChange = e => this.setState({

        [e.target.name]: e.target.value

    });

    onSubmit = async (e) => {

        e.preventDefault();

        const {code} = this.state;

        this.setState({

            load: true

        });

        const data = {

            code,
            token: this.props.location.appState.code

        };

        this.props.enterCode(data, this.props.history);

    };

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.load === false){

            this.setState({

                load: nextProps.load,
                code: ''

            });

        }

    }

    submitForm = () => {

        const {code} = this.state;

        return(

            code.length > 0

        )

    };

    callAgain = () => {

        window.history.back()

    };

    render() {

        const {code, load} = this.state;

        const isEnabled = this.submitForm();

        return (
            <div className="main-wrapper">
                <div className="col-lg-5 col-md-5 col-sm-12 d-flex gather">
                    <div className="login col-sm-12 col-lg-12 col-md-12">
                        <div className="tizeti-logo">
                            <img src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png" alt="images"/>
                        </div>
                        <form className="form-horizontal login-form col-sm-12 col-lg-12 col-md-12" onSubmit={this.onSubmit}>
                            <div className="form-group col-lg-9 col-sm-12">
                                <h5 style={{color:"black"}}><b>Enter the code to complete the verification process</b></h5>
                                {/*<p className="copy-font">Please enterour email and you will get a reset link</p>*/}
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter code"
                                        name="code"
                                        value={code}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <br/>
                            </div>
                            <div className="wrapper-button mb-3">
                                { load ? <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={true}><i className="fa fa-spinner fa-spin"></i></button>
                                    :   <button className="btn btn-green" id="login" style={{backgroundColor: '#35BEAA', color:"white"}} disabled={!isEnabled}>Submit</button>
                                }
                            </div>

                            <div className="text-center">
                                <h6 onClick={() => this.callAgain()} style={{cursor:"pointer"}}><strong>Click to Call Again To Receive Code</strong></h6>
                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer/>
            </div>

        );
    }
}

const mapStateToProps = state => ({

    load: state.loadRegistrationCode.loadRegistrationCode

});

export default connect(mapStateToProps, {enterCode})(withRouter(EnterCode));