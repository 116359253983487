import React, {Component} from 'react';
import MenuBar from '../../../layouts/MenuBar';
import Balance from "./Balance";
import ButtonSet from "../../../layouts/ButtonSet";
import CreateGroup from "./tabs/phone/group/CreateGroup";
import Groups from "./tabs/phone/group/Groups";
import Campaigns from "./tabs/campaigns/Campaigns";
import Robocall from "./tabs/robocall/Robocall";
import {connect} from "react-redux";
import {fetchUser} from "../../../services/state-management/actions/auth/AuthActions";
import PropTypes from "prop-types";
import NotificationNIN from '../../../layouts/NotificationNIN';

class BulkDialler extends Component {

    static propTypes = {

        fetchUser: PropTypes.func.isRequired

    };

    componentDidMount() {

        this.props.fetchUser();

    };

    checkPlan(){

        const{plan} = this.props.user;

        if(plan){

            if(plan.name === "Basic unlimited plan"){

                return null

            }

            return <li className="nav-item mr-auto mb-2">
                <a className="nav-link"
                   id="pills-profile-tab"
                   data-toggle="pill"
                   href="#pills-profile"
                   role="tab"
                   aria-controls="pills-profile"
                   aria-selected="false">Campaigns</a>
            </li>
        }
    }


    render() {

        return (
            <div className="body">
                <div className="container-fluid">
                <div className="row">
            <NotificationNIN />
          </div>
                    <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png" alt="logo" width="12%" height="15%"/>                </div>
                    <div className="row">
                        <MenuBar />
                    </div>

                    <div className="container-fluid " id="resize-fluid">
                        <div className="col-lg-12 col-12 d-flex mt-3 mb-3">
                            <div className="col-lg-6 col-12 p-0">
                                <ul className="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist">

                                    <li className="nav-item mr-auto mb-2">
                                        <a className="nav-link active d-flex"
                                           data-target="#create-group"
                                           id="pills-create-tab"
                                           data-toggle="modal"
                                           href="#pills-create"
                                           role="tab"
                                           aria-controls="pills-create"
                                           aria-selected="false">Create Group</a>
                                    </li>
                                    <li className="nav-item mr-auto mb-2">
                                        <a className="nav-link"
                                           id="pills-home-tab"
                                           data-toggle="pill"
                                           href="#pills-home"
                                           role="tab"
                                           aria-controls="pills-home"
                                           aria-selected="true">Phone Numbers</a>
                                    </li>

                                    {this.checkPlan()}

                                    <li className="nav-item mr-auto mb-2">
                                        <a className="nav-link"
                                           id="pills-contact-tab"
                                           data-toggle="pill"
                                           href="#pills-robo"
                                           role="tab"
                                           aria-controls="pills-contact"
                                           aria-selected="false">Robocall</a>
                                    </li>
                                </ul>

                            </div>

                            <div className="col-12 col-lg-6 d-flex">
                                <div className="col-12 col-md-12 col-lg-12 wallboard-table p-0 wallboard-chair">
                                    <div className="col-12 col-lg-12 d-flex">
                                        <Balance/>
                                        <div className="col-lg-6 d-none d-md-block d-lg-block">
                                            <div className="d-flex button-section">
                                                <ButtonSet/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-content" id="pills-tabContent">
                            <div className="d-flex subscriber-img mr-2" id="pills-create" data-target="#create-group" role="tabpanel" aria-labelledby="pills-create-tab">
                                <div className="col-12 col-lg-12 d-flex flex-wrap">
                                    <CreateGroup />
                                </div>
                            </div>
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <Groups/>

                            </div>

                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                                <Campaigns/>

                            </div>


                            <div className="tab-pane fade" id="pills-robo" role="tabpanel" aria-labelledby="pills-contact-tab">

                                <Robocall/>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    user: state.auth.auth

});

export default connect(
  mapStateToProps,
  { fetchUser },
)(BulkDialler);
