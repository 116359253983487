/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { ToastContainer } from 'react-toastify';
import {
  verifyCode,
  OTPcall,
} from '../../../services/state-management/actions/auth/AuthActions';
import DownloadPopUp from '../DownloadPopUp';

class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.state = { userCode: '' };
  }

  requestAgain = () => {
    const {
      location: { appState: { phoneNumber } = {} },
    } = this.props;
    const data = { phoneNumber };
    this.props.OTPcall(data);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { userCode } = this.state;
    const {
      location: { appState: { resultOTP, wificallUserID } = {} },
      history,
    } = this.props;

    const data = {
      code: userCode,
      callID: resultOTP,
      id: wificallUserID,
    };
    this.props.verifyCode(data, history);
  };

  submitForm = () => this.state.userCode.length === 4;

  render() {
    const codeInputProps = {
      inputStyle: {
        margin: '5px',
        MozAppearance: 'textfield',
        width: '50px',
        fontSize: '35px',
        height: '60px',
        paddingLeft: '17px',
        backgroundColor: 'rgb(243,254,252)',
        borderColor: 'rgb(95,206,189)',
        borderWidth: '0px 0px 5px',
      },
      inputStyleInvalid: {
        margin: '5px',
        MozAppearance: 'textfield',
        width: '50px',
        fontSize: '35px',
        height: '60px',
        paddingLeft: '17px',
        backgroundColor: 'rgb(243,254,252)',
        borderColor: 'rgb(95,206,189)',
        borderWidth: '0px 0px 5px',
        color: 'red',
        border: '1px solid red',
      },
    };
    const { userCode } = this.state;
    const { show, load } = this.props;
    const isEnabled = this.submitForm();
    return (
      <div className="container">
        <div className="col-lg-7 col-md-7 col-sm-12 d-flex mt-5 mx-auto">
          <div className="col-12">
            <div className="tizeti-logo">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
              />
            </div>

            <div className="col-12 p-0">
              <form
                onSubmit={this.onSubmit}
                className="form-horizontal mt-3 login-form"
              >
                <div className="text-center">
                  <p className="mt-3 text-center" style={{ fontSize: '13px' }}>
                    <strong>
                      Verify Your Phone number to complete Signup
                      <br />
                      Enter your 4-digit code here
                    </strong>
                  </p>
                </div>

                <div className="text-center pt-4 mb-5">
                  <ReactCodeInput
                    type="text"
                    fields={4}
                    name="userCode"
                    value={userCode}
                    {...codeInputProps}
                    onChange={(value) => this.setState({ userCode: value })}
                  />
                </div>
                <div>
                  {load ? (
                    <button
                      type="button"
                      className="btn w-100"
                      style={{ backgroundColor: '#35BEAA', color: 'white' }}
                      disabled
                    >
                      <i className="fa fa-spinner fa-spin" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn w-100"
                      disabled={!isEnabled}
                      data-toggle="modal"
                      data-target="#downloadAppModal"
                      style={{ backgroundColor: '#35BEAA', color: 'white' }}
                    >
                      VERIFY
                    </button>
                  )}
                  {show === 'show' ? <DownloadPopUp /> : null}
                </div>
              </form>
            </div>

            <div className="text-center p-0 m-0" style={{ fontSize: '11px' }}>
              <p className="m-1">
                Didn&apos;t receive the code?
                <button
                  type="button"
                  className="border-0 ml-1"
                  style={{ backgroundColor: 'rgb(243,254,252)' }}
                  onClick={this.requestAgain}
                >
                  <strong>Request Again</strong>
                </button>
              </p>
              <p className="m-1">
                Already Verified?
                <strong>
                  <Link
                    to="/login"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    {' '}
                    Sign In
                  </Link>
                </strong>
              </p>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

VerificationCode.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  verifyCode: PropTypes.func.isRequired,
  OTPcall: PropTypes.func.isRequired,
  load: PropTypes.bool.isRequired,
  show: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  load: state.auth.load,
  show: state.auth.loadRegistrationCode,
});

export default connect(
  mapStateToProps,
  { verifyCode, OTPcall },
)(withRouter(VerificationCode));
