import axios from 'axios';
import { PAYMENTS_URL } from '../../../apis/PaymentsUrl';
import { GET_USER_PAYMENTS } from './PaymentTypes';

export const getUserPayments = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PAYMENTS_URL}/${sessionStorage.getItem('cardid')}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (response.data.status === true) {
      dispatch({
        type: GET_USER_PAYMENTS,
        payload: {
          response: response.data.result,
          load: false,
        },
      });
    }
  } catch (e) {
  }
};
