import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuBar from '../../../layouts/MenuBar';
import AssignedNumber from './AssignedNumber';
import ButtonSet from '../../../layouts/ButtonSet';
import TotalUsers from './TotalUsers';
import Balance from './Balance';
import Call from './Call';
import RecentCalls from './RecentCalls';
import CallsGraph from './CallsGraph';
import { checkVerificationStatus } from '../../../services/state-management/actions/verify/VerifyActions';
import DownloadAppNotification from '../../../layouts/DownloadAppNotification';
import NotificationNIN from '../../../layouts/NotificationNIN';

class Overview extends Component {
  static propTypes = {
    checkVerificationStatus: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.checkVerificationStatus();
  }

  render() {
    return (
      <div>
        <div className="body">
          <div className="container-fluid p-0">
            <NotificationNIN />
            <DownloadAppNotification />
            <div className="col-lg-12 dashboard-image mt-3 d-none d-lg-block">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
                alt="logo"
                width="12%"
                height="15%"
              />
            </div>
            <div className="row">
              <MenuBar />
            </div>
            <div className="container-fluid" id="resize-fluid">
              <div className="row">
                <div className="col-12 col-lg-12 mt-3 d-flex">
                  {/* <AssignedNumber /> */}
                  <div className="d-flex button-section mb-3 col-lg-12 p-0">
                    {/* <div className="d-none d-lg-block">
                      <Link
                        to={`/wallboard/${sessionStorage.getItem('usersipid')}`}
                        target="_blank"
                      >
                        <button className="btn second-button text-white mr-3">
                          Wallboardxxxx
                        </button>
                      </Link>
                    </div> */}
                    <Balance />
                    <ButtonSet />
                  </div>
                </div>

                <div className="col-md-12 d-flex box-carrier mt-3 pr-0 pl-0">
                  <TotalUsers />

                  <AssignedNumber />

                  <Call />
                </div>

                <div
                  className="card-group mt-1 d-flex col-12 col-lg-12 mt-4"
                  id="group-card"
                >
                  <CallsGraph />

                  <RecentCalls />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.verificationStatus.verificationStatus,
});

export default connect(
  mapStateToProps,
  { checkVerificationStatus },
)(Overview);
