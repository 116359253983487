import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  assignNumber,
  getAvailableNumbers,
  getUserAllocatedNumbers,
} from '../../../services/state-management/actions/phone/PhonesActions';
import { getQueues } from '../../../services/state-management/actions/queues/QueuesActions';
import { getConferences } from '../../../services/state-management/actions/common/conferences/ConferencesActions';
import { getIVrs } from '../../../services/state-management/actions/common/ivr/IvrActions';
import { fetchUser } from '../../../services/state-management/actions/auth/AuthActions';
import {
  // NEW_TEST_RECURRENT_SUBSCRIPTION_PLAN_CODE,
  PAYSTACK_LIVE_KEY,
  PAYSTACK_SECRET_KEY,
  // PAYSTACK_TEST_PUBLIC_KEY, PAYSTACK_TEST_SECRET_KEY,
  RECURRENT_SUBSCRIBTION_PLAN_CODE,
  // TEST_RECURRENT_SUBSCRIPTION_PLAN_CODE,
  VERIFY_PAYSTACK_URL,
} from '../../config/Payment';
import { ASSIGN_NUMBER_URL } from '../../../services/apis/PhoneUrl';
import { getUsersInWorkgroup } from '../../../services/state-management/actions/workgroups/WorkgroupsActions';

class AssignPhoneNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: '',
      type: '',
      destination: '',
      load: false,
      card: '',
    };

    this.onChange = this.onChange.bind(this);
    this.assignPhone = this.assignPhone.bind(this);
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  componentDidMount() {
    this.props.getUserAllocatedNumbers();
    this.props.getQueues();
    this.props.getUsersInWorkgroup();
    this.props.getIVrs();
    this.props.getConferences();
    this.props.getAvailableNumbers();
  }

  showDestinations() {
    const { category } = this.state;
    const { queues, users, conferences, ivrs } = this.props;

    switch (category) {
      case 'queue':
        if (queues) {
          return (
            <select
              className="form-control"
              name="destination"
              onChange={this.onChange}
            >
              <option value="">Choose a queue</option>
              {queues.map((qu, i) => {
                return <option value={qu.queue_number}>{qu.name}</option>;
              })}
            </select>
          );
        }
        return <h3>you have no queues</h3>;

      case 'user':
        if (users.length) {
          return (
            <select
              className="form-control"
              name="destination"
              onChange={this.onChange}
            >
              <option value="">Choose a user</option>
              {users.map((u, i) => {
                return (
                  <option value={u.id}>
                    {u.firstname}
{' '}
{u.lastname}
                  </option>
                );
              })}
            </select>
          );
        }
        return <h3>you have no users</h3>;

      case 'ivr':
        if (ivrs) {
          return (
            <select
              className="form-control"
              name="destination"
              onChange={this.onChange}
            >
              <option value="">Choose a ivr</option>
              {ivrs.map((qu, i) => {
                return <option value={qu.ivr_number}>{qu.name}</option>;
              })}
            </select>
          );
        }
        return <h3>You have no ivrs</h3>;

      case 'conference':
        if (conferences) {
          return (
            <select
              className="form-control"
              name="destination"
              onChange={this.onChange}
            >
              <option value="">Choose a conference</option>
              {conferences.map((co, i) => {
                return <option value={co.conference_number}>{co.name}</option>;
              })}
            </select>
          );
        }
        return <h3>You have no conferences</h3>;

      default:
        return null;
    }
  }

  submitForm() {
    const { type, destination, category } = this.state;

    return type.length > 0 && destination.length > 0 && category.length > 0;
  }

  assignPhone = (e) => {
    e.preventDefault();

    const { type, destination, category } = this.state;

    const number = {
      phonenumber_id: type,
      card_id: destination,
      destination,
      category,
    };

    if (number) {
      const email = sessionStorage.getItem('email');
      const firstname = sessionStorage.getItem('firstname');

      const handler = window.PaystackPop.setup({
        key: `${PAYSTACK_LIVE_KEY}`,
        email,
        plan: `${RECURRENT_SUBSCRIBTION_PLAN_CODE}`,
        // ref: "UNIQUE TRANSACTION REFERENCE HERE",
        metadata: {
          custom_fields: [
            {
              display_name: firstname,
              email,
              phonenumber_id: type,
              destination,
              category,
            },
          ],
        },
        callback(response) {
          if (response.reference) {
            const ref = response.reference;

            try {
              axios
                .get(`${VERIFY_PAYSTACK_URL}/${ref}`, {
                  headers: {
                    Authorization: `Bearer ${PAYSTACK_SECRET_KEY}`,
                  },
                })
                .then((res) => {
                  if (res.data.status === true) {
                    toast.success('Transaction Successful');

                    axios
                      .post(`${ASSIGN_NUMBER_URL}`, number, {
                        headers: {
                          Authorization: `Bearer ${sessionStorage.getItem(
                            'token',
                          )}`,
                        },
                      })
                      .then((resp) => {
                        // data:
                        //     amount: 10000
                        // authorization: {authorization_code: "AUTH_h9asht2zpz", bin: "539983", last4: "4618", exp_month: "03", exp_year: "2021", …}
                        // channel: "card"
                        // createdAt: "2019-06-21T12:16:53.000Z"
                        // created_at: "2019-06-21T12:16:53.000Z"
                        // currency: "NGN"
                        // customer: {id: 9346697, first_name: "", last_name: "", email: "oluwabukolatina@icloud.com", customer_code: "CUS_c7o8yjeoev7riqn", …}
                        // domain: "live"
                        // fees: 150
                        // fees_split: null
                        // gateway_response: "Approved"
                        // id: 200065001
                        // ip_address: "196.50.7.2"
                        // log: {start_time: 1561119413, time_spent: 94, attempts: 2, authentication: "otp", errors: 0, …}
                        // message: null
                        // metadata: {custom_fields: Array(1), referrer: "http://localhost:3000/#/phone"}
                        // order_id: null
                        // paidAt: "2019-06-21T12:18:26.000Z"
                        // paid_at: "2019-06-21T12:18:26.000Z"
                        // plan: "PLN_o1xuk950sx4v3lq"
                        // plan_object: {id: 25375, name: "phone number test", plan_code: "PLN_o1xuk950sx4v3lq", description: null, amount: 10000, …}
                        // reference: "T458470780952953"
                        // status: "success"
                        // subaccount: {}

                        if (
                          resp.data.status === true &&
                          res.data.data.amount >= 100000
                        ) {
                          toast.success('Number assigned successfully!');
                          //                                            window.location.reload();
                          // this.props.getUserAllocatedNumbers();
                        } else {
                          toast.warn(resp.data.message);
                        }
                      })
                      .catch();
                  }
                });
            } catch (e) {
              toast.info('Something went wrong!');
            }
          } else {
            toast.info('Transaction Failed');
          }
        },
        onClose() {},
      });
      handler.openIframe();
    }
  };

  reload = () => {
    window.location.reload();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        load: nextProps.loading,
      });
    }

    if (nextProps.error === false) {
      this.setState({
        load: nextProps.error,
      });
    }
  }

  render() {
    const { available } = this.props;
    const isEnabled = this.submitForm();
    const { load } = this.state;

    return (
      <div className="col-12 mb-4">
        <div className="col-lg-10 col-12 shadow-sm p-3">
          <form className="form-horizontal" onSubmit={this.assignPhone}>
            <div
              className="form-group col-12 d-flex flex-wrap pl-0"
              id="action-label"
            >
              <div className="col-md-3 p-0 col-12 mb-3 mr-auto">
                <label htmlFor="phone" className="p-0 text-left">
                  Phone Number
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1551709317/info.svg"
                    alt=""
                    data-toggle="tooltip"
                    data-placement="top"
                    className="ml-1"
                    title="Select number"
                  />
                  <br />
                </label>

                <select
                  className="form-control"
                  onChange={this.onChange}
                  name="type"
                >
                  <option className="resize-small" value="">
                    (Select number)
                  </option>
                  {available.map((number) => {
                    return <option value={number.id}>{number.did}</option>;
                  })}
                </select>

                {/* {number ? <select className="form-control" onChange={this.onChange} name="type"> */}
                {/* <option value={number.id} selected={number.id}>{number.did}</option> */}
                {/* </select> : null } */}
              </div>
              <div className="col-md-3 p-0 col-12 mb-3 mr-auto">
                <label htmlFor="category" className="p-0 text-left">
                  Category
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1551709317/info.svg"
                    alt=""
                    data-toggle="tooltip"
                    data-placement="top"
                    className="ml-1"
                    title="Choose a category to assign the number to"
                  />
                  <br />
                </label>
                <select
                  className="form-control"
                  onChange={this.onChange}
                  name="category"
                >
                  <option value="">Choose Category</option>
                  <option value="user">User</option>
                  <option value="ivr">ivr</option>
                  <option value="queue">Queue</option>
                  <option value="conference">Conference</option>
                </select>
              </div>
              <div className="col-md-3 p-0 col-12 mb-3 mr-auto">
                <label htmlFor="destinations" className="p-0 text-left">
                  Destination
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1551709317/info.svg"
                    alt=""
                    data-toggle="tooltip"
                    data-placement="top"
                    className="ml-1"
                    title="Choose a destination to assign the number to"
                  />
                  <br />
                </label>
                {this.showDestinations()}
              </div>
              {load ? (
                <div
                  className="col-md-2 col-12 add-phone p-0"
                  style={{ marginTop: '2.2rem' }}
                >
                  <button
                    type="submit"
                    className="btn second-button text-white"
                    disabled
                  >
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: '24px' }}
                    />
                    Assigning...
                  </button>
                </div>
              ) : (
                <div
                  className="col-md-2 col-12 add-phone p-0"
                  style={{ marginTop: '2.2rem' }}
                >
                  <button
                    type="submit"
                    className="btn second-button text-white"
                    disabled={!isEnabled}
                  >
                    Add Phone number
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  available: state.availableNumbers.availableNumbers,
  queues: state.queues.queues,
  users: state.users.users,
  conferences: state.conferences.conferences,
  ivrs: state.ivrs.ivrs,
  user: state.auth.auth,
  loading: state.loadAssigning.loadAssigning,
  error: state.errors,
});
AssignPhoneNumber.propTypes = {
  getUserAllocatedNumbers: PropTypes.func.isRequired,
  getQueues: PropTypes.func.isRequired,
  queues: PropTypes.object.isRequired,
  getUsersInWorkgroup: PropTypes.func.isRequired,
  getConferences: PropTypes.func.isRequired,
  getIVr: PropTypes.func.isRequired,
  conferences: PropTypes.object.isRequired,
  available: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  assignNumber: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    getUserAllocatedNumbers,
    getQueues,
    getUsersInWorkgroup,
    getConferences,
    getIVrs,
    assignNumber,
    getAvailableNumbers,
    fetchUser,
  },
)(AssignPhoneNumber);
