import axios from 'axios'
import {GET_WALLBOARD_QUEUES} from "../types/WallboardTypes";
import { GET_WALLBOARD_QUEUES_URL} from "../../../apis/verify/WallboardUrl";
import {toast} from "react-toastify";

export const getWallBoardQueues = (data) => async dispatch => {

    try {

        const response = await axios.get(`${GET_WALLBOARD_QUEUES_URL}/${data}`);

            if(response.data.queues) {

                dispatch ({

                    type: GET_WALLBOARD_QUEUES,
                    payload: response.data.queues.result

                })

            } else {

                toast.info("CANNOT RETRIEVE THE QUEUE LIST");

            }

    } catch (e) {
        
    }


};