import axios from "axios";
import {API_URL} from "../../../../../../components/config/BaseUrl";
import {toast} from "react-toastify";
import {
    UPLOAD_IDENTITY,
    UPLOAD_IDENTITY_PROGRESS, UPLOAD_UTILITY,
    UPLOAD_UTILITY_PROGRESS
} from "../../../types/user/verify/VerifyTypes";

export const uploadIDVerification = data => async dispatch => {

    try {

        const response = await axios.post(`${API_URL}/wificall/docs`, data, {

            headers: {

                'Authorization': `Bearer ${sessionStorage.getItem('token')}`

            },

            onUploadProgress: ProgressEvent => {

                dispatch({

                    type: UPLOAD_IDENTITY_PROGRESS,
                    payload: ((ProgressEvent.loaded / ProgressEvent.total) * 100)

                });

            }

        });

        if(response.data){

            toast.success("Identity Document Uploaded!");

            dispatch({

                type: UPLOAD_IDENTITY,
                payload: "uploaded identity"

            })

        } else {

        }

    } catch (e) {

    }

};

export const uploadUtilityVerification = data => async dispatch => {

    try {

        const response = await axios.post(`${API_URL}/wificall/utilities`, data, {

            headers: {

                'Authorization': `Bearer ${sessionStorage.getItem('token')}`

            },

            onUploadProgress: ProgressEvent => {

                dispatch({

                    type: UPLOAD_UTILITY_PROGRESS,
                    payload: ((ProgressEvent.loaded / ProgressEvent.total) * 100)

                });

            }

        });

        if(response.data){

            toast.success("Utility Document Uploaded!");

            dispatch({

                type: UPLOAD_UTILITY,
                payload: "uploaded utility"

            })

        } else {

        }

    } catch (e) {

    }

};