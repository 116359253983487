import axios from 'axios';
import toastr from 'toastr';
import {
  PAYSTACK_BASIC_CHARGE,
  PAYSTACK_LIVE_KEY,

} from '../../../components/config/Payment';
import { expectedAmount } from '../../../components/util/payment';
import { API_URL } from '../../../components/config/BaseUrl';
const usePayg = () => {
  const lessThanTwoFive = (amount) => {
    let handler;
    const newAmount = amount / (1 - `${PAYSTACK_BASIC_CHARGE}`);
    handler = window.PaystackPop.setup({
      key: `${PAYSTACK_LIVE_KEY}`,
      email: sessionStorage.getItem('email'),
      amount: Math.round(newAmount),
      ref: `${Math.floor(Math.random() * 1000000000 + 1)}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      firstname: sessionStorage.getItem('firstname'),
      lastname: sessionStorage.getItem('lastname'),
      metadata: {
        custom_fields: [
          {
            email: sessionStorage.getItem('email'),
            card_id: sessionStorage.getItem('cardid'),
            amount,
            source: 'web',
          },
        ],
      },
      callback(response) {
        if (response.reference) {
          const ref = response.reference;
          const url = `${API_URL}/verify?reference=${ref}`;
          axios({
            method: 'get',
            url,
          }).then((res) => {
            if (res.data) {
              if (
                res.data.status === true &&
                res.data.message === 'PostPayment SUCCESS'
              ) {
                toastr.success('Transaction Successful');
                window.location.reload();
              }
            }
          });
        }
      },
      onClose() {
        // alert('window closed');
      },
    });
    handler.openIframe();
  };
  const moreThanTwoFive = (amount) => {
    const newAmount = expectedAmount(amount, 100);
    let handler;
    handler = window.PaystackPop.setup({
      key: `${PAYSTACK_LIVE_KEY}`,
      email: sessionStorage.getItem('email'),
      amount: Math.round(newAmount),
      ref: `${Math.floor(Math.random() * 1000000000 + 1)}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      firstname: sessionStorage.getItem('firstname'),
      lastname: sessionStorage.getItem('lastname'),
      metadata: {
        custom_fields: [
          {
            email: sessionStorage.getItem('email'),
            card_id: sessionStorage.getItem('cardid'),
            amount,
            source: 'web',
          },
        ],
      },
      callback(response) {
        /* alert('success. transaction ref is ' + response.reference); */
        if (response.reference) {
          const ref = response.reference;
          const url = `${API_URL}/verify?reference=${ref}`;
          axios({
            method: 'get',
            url,
          }).then((res) => {
            if (res.data) {
              if (
                res.data.status === true &&
                res.data.message === 'PostPayment SUCCESS'
              ) {
                toastr.success('Transaction Successful');
                window.location.reload();
              }
            }
          });
          this.setState({
            reference: response.reference,
          });
        }
      },
      onClose() {
        // alert('window closed');
      },
    });
    handler.openIframe();
  };
  return { lessThanTwoFive, moreThanTwoFive };
};
export default usePayg;
