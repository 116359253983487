import React, {Component} from 'react';
import {connect} from "react-redux";
import {getTotalUsers} from "../../../services/state-management/actions/common/users/UsersActions";
import PropTypes from 'prop-types';

class TotalUsers extends Component {

    componentDidMount() {

        this.props.getTotalUsers()

    }

    static propTypes = {

        getTotalUsers: PropTypes.func.isRequired,
        totalUsers: PropTypes.object.isRequired

    };

    render() {
        const {totalUsers} = this.props;

        return (
            <div className="col-md-4 col-12 mb-3">
                <div className="boxes d-flex">
                    <div className="icon pr-2">
                        <img src="https://res.cloudinary.com/teewhy/image/upload/v1546529222/customerportal-img.fw.png" alt=''/>
                    </div>

                    <div>
                        <h5 className="count mt-1"><b>{totalUsers.length > 0 ? totalUsers: 0}</b>
                            <br/>
                            <span className="baby-font">Total Users</span></h5>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {

    return {

        totalUsers: state.totalUsers.totalUsers

    };

};

export default connect(mapStateToProps, {getTotalUsers})(TotalUsers);