import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  deleteFile,
  getFiles,
} from '../../../services/state-management/actions/files/FilesAction';
import { API_URL } from '../../config/BaseUrl';

class FilesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletedFile: {},
    };
  }

  deleteFile = async (files) => {
    const data = {
      filebucket_number: files.filebucket_number,
      filebucket_type: files.filebucket_type,
      filebucket_extension: files.extension,
      card_id: `${sessionStorage.getItem('cardid')}`,
      workgroup_name: sessionStorage.getItem('workgroup'),
    };

    // this.props.deleteFile(data);

    const response = await axios.post(
      `${API_URL}/wificall/deleteUpload`,
      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );

    if (response.data.status === true) {
      toast.success('File deleted successfully!');
      //
      // eslint-disable-next-line react/destructuring-assignment
      this.props.getFiles();
      this.setState(
        {
          deletedFile: response.data.result.filebucket_number,
        },
        () => console.log(this.state.deletedFile),
      );
    }
  };

  // eslint-disable-next-line class-methods-use-this,no-return-assign
  download = (files) => (window.location.href = files.filebucket_url);

  render() {
    const { files } = this.props;

    const allFiles = files ? (
      files.map((file, i) => {
        return (
          <tr className="table-row" data-href="#" key={file.filebucket_number}>
            <th scope="row">{i + 1}</th>
            <td>{file.name}</td>
            {/* <td><audio controls>
                            <source src={files.filebucket_url} type="audio/ogg"/>
                            <source src={files.filebucket_url} type="audio/mpeg"/>
                        </audio>
                            </td> */}
            {/* <td><img src="https://res.cloudinary.com/teewhy/image/upload/v1546936774/play-recording.fw.png" className="download-image" alt="play"/>/!*{files.filebucket_url}*!/</td> */}
            <td className="text-center">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1547666126/Download_icon.png"
                className="download-image"
                alt="download"
                onClick={() => this.download(file)}
              />{' '}
              {/* {files.filebucket_url} */}
            </td>
            {/* <td className="text-center" onClick={() => this.deleteFile(files)}> */}
            {/* <img src="https://res.cloudinary.com/teewhy/image/upload/v1546953183/trash.fw.png" alt="trash"/> */}
            {/* </td> */}
          </tr>
        );
      })
    ) : (
      <h1>No files</h1>
    );

    return (
      <tbody>
        {allFiles || (
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1548662534/WificallLoader.gif"
            alt="loading"
          />
        )}
      </tbody>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files.files,
  };
};

FilesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  getFiles: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { deleteFile, getFiles },
)(FilesTable);
