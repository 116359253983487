import {
  ADD_CONFERENCE,
  GET_CONFERENCES,
  STOP_ADDING_CONFERENCE,
} from '../../../actions/common/conferences/ConferencesTypes';

const initialState = {
  conferences: [],
  conference: {},
  loadAddConference: false,
};

const ConferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFERENCES:
      return {
        ...state,
        conferences: action.payload,
      };

    case ADD_CONFERENCE:
      return {
        ...state,
        conference: action.payload.response,
        loadAddConference: action.payload.loading,
      };

    case STOP_ADDING_CONFERENCE:
      return {
        ...state,
        loadAddConference: false,
      };

    default:
      return state;
  }
};

export default ConferencesReducer;
