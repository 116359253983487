import {CREATE_ROBOCALL, GET_ROBOCALLS} from "../../actions/types/bulk-dialler/RobocallTypes";

const initialState = {

    robocalls: [],
    robocall: {},
    loadCreateRobocall: false

};

const RobocallReducer = (state=initialState, action) => {

    switch (action.type) {
        case GET_ROBOCALLS:
            return {

                ...state,
                robocalls: action.payload

            };

        case CREATE_ROBOCALL:
            return{

                ...state,
                loadCreateRobocall: action.payload.load,
                robocall: action.payload.response

            };

        default:
            return state;
    }
};

export default RobocallReducer;