/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import '../../../styles/style.css';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import WallboardMenuBar from '../../../layouts/WallboardMenuBar';
import { getWallBoardQueues } from '../../../services/state-management/actions/wallboard/WallboardActions';
import WallboardQueuesTable from './WallboardQueuesTable';
import 'react-toastify/dist/ReactToastify.min.css';
import WallboardCallsWaiting from './WallboardCallsWaiting';
import { GET_WALLBOARD_QUEUES_URL } from '../../../services/apis/verify/WallboardUrl';

class Wallboard extends Component {
  static propTypes = {
    getWallBoardQueues: PropTypes.func.isRequired,
    wallboards: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      queueNumber: 0,
      wallboards: [],
    };

    this.displayQueue = this.displayQueue.bind(this);
  }

  componentDidMount() {
    const { usersip } = this.props.match.params;



    this.interval = setInterval(() => {
      this.deleteRows();

      axios.get(`${GET_WALLBOARD_QUEUES_URL}/${usersip}`).then((response) => {
        if (response.data) {
          this.setState({
            wallboards: response.data.queues.result,
          });
        } else {
          toast.info('CANNOT RETRIEVE THE QUEUE LIST');
        }
      });
    }, 3000);
  }

  componentWillUnmount() {
    if (this.interval) {
      this.timer = null;

      clearInterval(this.interval);
    }
  }

  deleteRows = () => {
    const myTable = document.getElementById('myTable');
    const rowCount = myTable.rows.length;
    for (let x = rowCount - 1; x > 0; x--) {
      myTable.deleteRow(x);
    }
  };

  showQueueDropdown() {
    if (this.state.queueNumber) {
      return (
        <select className="form-control w-25" name="queue">
          <option name="queue">Queue Numbers</option>
          {this.state.queueNumber.map((queue) => {
            return <option value={queue.name}>{queue.queue_number}</option>;
          })}
        </select>
      );
    }
  }

  displayQueue() {
    if (this.state.queueNumber) {
      return this.state.queueNumber.map((queue, i) => (
        <tr>
          <td>{queue.queue_number}</td>
        </tr>
      ));
    }
  }

  render() {
    const { wallboards } = this.state;

    return (
      <div className="body">
        <div className="main-wrapper">
          <div className="container-fluid">
            <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
                alt="logo"
                width="12%"
                height="15%"
              />
            </div>
            <div className="row">
              <WallboardMenuBar />
            </div>

            <div className="container-fluid" id="resize-fluid">
              <div className="row">
                <div className="wallboard-table col-12">
                  <div
                    className="card-group mt-1 d-flex col-lg-12 col-12 mt-1 p-0"
                    id="group-card"
                  >
                    <div className="col-lg-6 col-12 make-equal p-0">
                      <div>
                        <div className="wallboard-filter mb-3">
                          {this.showQueueDropdown()}
                        </div>
                      </div>

                      <div className="card o-income card-body col-12">
                        <table
                          className="table copy-font table-responsive"
                          id="myTable"
                        >
                          <thead>
                            <tr>
                              <th>Queue Name</th>
                              <th>Queue Number</th>
                              <th>Calls in Queue</th>
                              <th>Answered Calls</th>
                              <th>Aborted Calls</th>
                              <th>Avg Hold Time</th>
                              <th>Avg Talk Time</th>
                            </tr>
                          </thead>

                          {wallboards ? (
                            <WallboardQueuesTable queues={wallboards} />
                          ) : (
                            <small>loading...</small>
                          )}
                        </table>
                      </div>
                    </div>

                    {/* <WallboardQueues/> */}
                  </div>

                  <WallboardCallsWaiting queues={wallboards} />
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wallboards: state.wallboardqueues.wallboardqueues,
});

export default connect(
  mapStateToProps,
  { getWallBoardQueues },
)(Wallboard);
