import {
    CREATE_CAMPAIGN,
    GET_CAMPAIGNS
} from "../../actions/types/bulk-dialler/CampaignTypes";

const initialState = {

    campaigns: [],
    loadCreating: false,
    campaign: {}

};

const CampaignsReducer=(state=initialState, action) => {

    switch (action.type) {

        case GET_CAMPAIGNS:
            return {

                ...state,
                campaigns: action.payload

            };

        case CREATE_CAMPAIGN:
            return{

                ...state,
                loadCreating: action.payload.load,
                campaign: action.payload.response
                // campaigns: [action.payload.response, ...state.campaigns],


            };

        default:
            return state;

    }

};

export default CampaignsReducer;