import {GET_LOGGED_IN_USER} from "../../../actions/types/user/UserTypes";

const initialState = {

    user: {}

};

export const UserReducer =(state=initialState, action) => {
    switch (action.type) {
        case GET_LOGGED_IN_USER:
            return {

                ...state,
                user: action.payload

            };

        default:
            return state;

    }

};

export default UserReducer;