/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WallboardQueuesTable extends Component {
  static propTypes = {
    queues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ObjArr: [],
    };
  }

  render() {
    const { queues } = this.props;
    const obj = queues;
    for (const key in queues) {
      if (obj.hasOwnProperty(key)) {
        this.state.ObjArr.push(obj[key]);
      }
    }

    const queueList = this.state.ObjArr ? (
      this.state.ObjArr.map((queue) => (
        <tr>
          <td>{queue.name}</td>
          <td>{queue.q_no}</td>
          <td>{queue.cl_q}</td>
          <td>{queue.ans_cl}</td>
          <td>{queue.ab_cl}</td>
          <td>{queue.hl_tm}</td>
          <td>{queue.tk_tm}</td>
        </tr>
      ))
    ) : (
      <small>no queues</small>
    );

    return <tbody>{queueList}</tbody>;
  }
}

export default WallboardQueuesTable;
