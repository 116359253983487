/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import googlePlay from '../../images/google-play.png';
import appStore from '../../images/app-store.png';

const DownloadPopUp = () => {
  return (
    <div
      className="modal fade"
      id="downloadAppModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content Logo">
          <a
            className="close border-0 text-right mr-2"
            data-dismiss="modal"
            aria-label="Close"
            href="#"
          >
            <span aria-hidden="true">&times;</span>
          </a>
          <div className="col-12 text-center pb-3">
            <div className="modal-title mt-4">
              <img
                src="https://res.cloudinary.com/teewhy/image/upload/v1544618377/Logoo.png"
                alt="images"
                height="37px"
              />
            </div>
            <div className="modal-body pt-0">
              <strong className="text-nowrap copy-font">
                Download App To Login And Make Calls
              </strong>
              <div className="py-2 mt-3">
                <div className="row mb-2">
                  <div className="col">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.tizeti.wificall"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img src={googlePlay} alt="images" width="60%" />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <a
                      href="https://itunes.apple.com/us/app/wificall-ng/id1458874906?ls=1&mt=8"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img src={appStore} alt="images" width="60%" />
                    </a>
                  </div>
                </div>
              </div>
              {/* <strong className="text-nowrap copy-font">
                For Business Plan, Contact
                <a
                  href="tel:012290000"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {' '}
                  012290000
                </a>
              </strong> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPopUp;
